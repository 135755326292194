import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  json: any;
  timestamptz: any;
};

export type AlgoliaApiKey = {
  __typename?: 'AlgoliaApiKey';
  apiKey: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type FinancialData = {
  __typename?: 'FinancialData';
  data?: Maybe<Array<Maybe<IohlcData>>>;
  symbol?: Maybe<Scalars['String']>;
};

export type IohlcData = {
  __typename?: 'IOHLCData';
  close?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  high?: Maybe<Scalars['Float']>;
  low?: Maybe<Scalars['Float']>;
  open?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type NotePublishingInfo = {
  clientIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  noteId: Scalars['Int'];
  shouldSendEmail: Scalars['Boolean'];
  teamId?: Maybe<Scalars['Int']>;
};

export type PublishNoteOutput = {
  __typename?: 'PublishNoteOutput';
  noteId: Scalars['Int'];
};

export enum QueryPeriods {
  Period_1m = 'period_1m',
  Period_1mm = 'period_1mm',
  Period_1y = 'period_1y',
  Period_2y = 'period_2y',
  Period_3m = 'period_3m',
  Period_5d = 'period_5d',
  Period_5dm = 'period_5dm',
  Period_5y = 'period_5y',
  Period_6m = 'period_6m',
  PeriodMax = 'period_max',
  PeriodYtd = 'period_ytd'
}

export type SignedUrl = {
  __typename?: 'SignedUrl';
  signedUrl: Scalars['String'];
  url: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type UnpublishNoteOutput = {
  __typename?: 'UnpublishNoteOutput';
  noteId: Scalars['Int'];
};

/** columns and relationships of "chart_indicators" */
export type Chart_Indicators = {
  __typename?: 'chart_indicators';
  value: Scalars['String'];
};

/** aggregated selection of "chart_indicators" */
export type Chart_Indicators_Aggregate = {
  __typename?: 'chart_indicators_aggregate';
  aggregate?: Maybe<Chart_Indicators_Aggregate_Fields>;
  nodes: Array<Chart_Indicators>;
};

/** aggregate fields of "chart_indicators" */
export type Chart_Indicators_Aggregate_Fields = {
  __typename?: 'chart_indicators_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chart_Indicators_Max_Fields>;
  min?: Maybe<Chart_Indicators_Min_Fields>;
};


/** aggregate fields of "chart_indicators" */
export type Chart_Indicators_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Chart_Indicators_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chart_indicators". All fields are combined with a logical 'AND'. */
export type Chart_Indicators_Bool_Exp = {
  _and?: Maybe<Array<Chart_Indicators_Bool_Exp>>;
  _not?: Maybe<Chart_Indicators_Bool_Exp>;
  _or?: Maybe<Array<Chart_Indicators_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chart_indicators" */
export enum Chart_Indicators_Constraint {
  /** unique or primary key constraint */
  ChartIndicatorsPkey = 'chart_indicators_pkey'
}

/** input type for inserting data into table "chart_indicators" */
export type Chart_Indicators_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Chart_Indicators_Max_Fields = {
  __typename?: 'chart_indicators_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Chart_Indicators_Min_Fields = {
  __typename?: 'chart_indicators_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chart_indicators" */
export type Chart_Indicators_Mutation_Response = {
  __typename?: 'chart_indicators_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chart_Indicators>;
};

/** on conflict condition type for table "chart_indicators" */
export type Chart_Indicators_On_Conflict = {
  constraint: Chart_Indicators_Constraint;
  update_columns: Array<Chart_Indicators_Update_Column>;
  where?: Maybe<Chart_Indicators_Bool_Exp>;
};

/** Ordering options when selecting data from "chart_indicators". */
export type Chart_Indicators_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: chart_indicators */
export type Chart_Indicators_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "chart_indicators" */
export enum Chart_Indicators_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "chart_indicators" */
export type Chart_Indicators_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "chart_indicators" */
export enum Chart_Indicators_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "chart_periods" */
export type Chart_Periods = {
  __typename?: 'chart_periods';
  value: Scalars['String'];
};

/** aggregated selection of "chart_periods" */
export type Chart_Periods_Aggregate = {
  __typename?: 'chart_periods_aggregate';
  aggregate?: Maybe<Chart_Periods_Aggregate_Fields>;
  nodes: Array<Chart_Periods>;
};

/** aggregate fields of "chart_periods" */
export type Chart_Periods_Aggregate_Fields = {
  __typename?: 'chart_periods_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chart_Periods_Max_Fields>;
  min?: Maybe<Chart_Periods_Min_Fields>;
};


/** aggregate fields of "chart_periods" */
export type Chart_Periods_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Chart_Periods_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chart_periods". All fields are combined with a logical 'AND'. */
export type Chart_Periods_Bool_Exp = {
  _and?: Maybe<Array<Chart_Periods_Bool_Exp>>;
  _not?: Maybe<Chart_Periods_Bool_Exp>;
  _or?: Maybe<Array<Chart_Periods_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chart_periods" */
export enum Chart_Periods_Constraint {
  /** unique or primary key constraint */
  ChartPeriodsPkey = 'chart_periods_pkey'
}

export enum Chart_Periods_Enum {
  Period_1m = 'period_1m',
  Period_1mm = 'period_1mm',
  Period_1y = 'period_1y',
  Period_2y = 'period_2y',
  Period_3m = 'period_3m',
  Period_5d = 'period_5d',
  Period_5dm = 'period_5dm',
  Period_5y = 'period_5y',
  Period_6m = 'period_6m',
  PeriodMax = 'period_max',
  PeriodYtd = 'period_ytd'
}

/** Boolean expression to compare columns of type "chart_periods_enum". All fields are combined with logical 'AND'. */
export type Chart_Periods_Enum_Comparison_Exp = {
  _eq?: Maybe<Chart_Periods_Enum>;
  _in?: Maybe<Array<Chart_Periods_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Chart_Periods_Enum>;
  _nin?: Maybe<Array<Chart_Periods_Enum>>;
};

/** input type for inserting data into table "chart_periods" */
export type Chart_Periods_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Chart_Periods_Max_Fields = {
  __typename?: 'chart_periods_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Chart_Periods_Min_Fields = {
  __typename?: 'chart_periods_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chart_periods" */
export type Chart_Periods_Mutation_Response = {
  __typename?: 'chart_periods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chart_Periods>;
};

/** on conflict condition type for table "chart_periods" */
export type Chart_Periods_On_Conflict = {
  constraint: Chart_Periods_Constraint;
  update_columns: Array<Chart_Periods_Update_Column>;
  where?: Maybe<Chart_Periods_Bool_Exp>;
};

/** Ordering options when selecting data from "chart_periods". */
export type Chart_Periods_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: chart_periods */
export type Chart_Periods_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "chart_periods" */
export enum Chart_Periods_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "chart_periods" */
export type Chart_Periods_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "chart_periods" */
export enum Chart_Periods_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "chart_styles" */
export type Chart_Styles = {
  __typename?: 'chart_styles';
  value: Scalars['String'];
};

/** aggregated selection of "chart_styles" */
export type Chart_Styles_Aggregate = {
  __typename?: 'chart_styles_aggregate';
  aggregate?: Maybe<Chart_Styles_Aggregate_Fields>;
  nodes: Array<Chart_Styles>;
};

/** aggregate fields of "chart_styles" */
export type Chart_Styles_Aggregate_Fields = {
  __typename?: 'chart_styles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chart_Styles_Max_Fields>;
  min?: Maybe<Chart_Styles_Min_Fields>;
};


/** aggregate fields of "chart_styles" */
export type Chart_Styles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Chart_Styles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chart_styles". All fields are combined with a logical 'AND'. */
export type Chart_Styles_Bool_Exp = {
  _and?: Maybe<Array<Chart_Styles_Bool_Exp>>;
  _not?: Maybe<Chart_Styles_Bool_Exp>;
  _or?: Maybe<Array<Chart_Styles_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chart_styles" */
export enum Chart_Styles_Constraint {
  /** unique or primary key constraint */
  ChartStylesPkey = 'chart_styles_pkey'
}

export enum Chart_Styles_Enum {
  Area = 'area',
  Candlestick = 'candlestick',
  Line = 'line'
}

/** Boolean expression to compare columns of type "chart_styles_enum". All fields are combined with logical 'AND'. */
export type Chart_Styles_Enum_Comparison_Exp = {
  _eq?: Maybe<Chart_Styles_Enum>;
  _in?: Maybe<Array<Chart_Styles_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Chart_Styles_Enum>;
  _nin?: Maybe<Array<Chart_Styles_Enum>>;
};

/** input type for inserting data into table "chart_styles" */
export type Chart_Styles_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Chart_Styles_Max_Fields = {
  __typename?: 'chart_styles_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Chart_Styles_Min_Fields = {
  __typename?: 'chart_styles_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chart_styles" */
export type Chart_Styles_Mutation_Response = {
  __typename?: 'chart_styles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chart_Styles>;
};

/** on conflict condition type for table "chart_styles" */
export type Chart_Styles_On_Conflict = {
  constraint: Chart_Styles_Constraint;
  update_columns: Array<Chart_Styles_Update_Column>;
  where?: Maybe<Chart_Styles_Bool_Exp>;
};

/** Ordering options when selecting data from "chart_styles". */
export type Chart_Styles_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: chart_styles */
export type Chart_Styles_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "chart_styles" */
export enum Chart_Styles_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "chart_styles" */
export type Chart_Styles_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "chart_styles" */
export enum Chart_Styles_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "charts" */
export type Charts = {
  __typename?: 'charts';
  id: Scalars['Int'];
  /** An array relationship */
  notes: Array<Notes>;
  /** An aggregate relationship */
  notes_aggregate: Notes_Aggregate;
  period: Chart_Periods_Enum;
  position?: Maybe<Scalars['Int']>;
  /** An array relationship */
  settings: Array<User_Chart_Settings>;
  /** An aggregate relationship */
  settings_aggregate: User_Chart_Settings_Aggregate;
  style?: Maybe<Chart_Styles_Enum>;
  symbol: Scalars['String'];
  workspace_id: Scalars['Int'];
  /** An object relationship */
  workspaces: Workspaces;
};


/** columns and relationships of "charts" */
export type ChartsNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "charts" */
export type ChartsNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


/** columns and relationships of "charts" */
export type ChartsSettingsArgs = {
  distinct_on?: Maybe<Array<User_Chart_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Chart_Settings_Order_By>>;
  where?: Maybe<User_Chart_Settings_Bool_Exp>;
};


/** columns and relationships of "charts" */
export type ChartsSettings_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Chart_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Chart_Settings_Order_By>>;
  where?: Maybe<User_Chart_Settings_Bool_Exp>;
};

/** aggregated selection of "charts" */
export type Charts_Aggregate = {
  __typename?: 'charts_aggregate';
  aggregate?: Maybe<Charts_Aggregate_Fields>;
  nodes: Array<Charts>;
};

/** aggregate fields of "charts" */
export type Charts_Aggregate_Fields = {
  __typename?: 'charts_aggregate_fields';
  avg?: Maybe<Charts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Charts_Max_Fields>;
  min?: Maybe<Charts_Min_Fields>;
  stddev?: Maybe<Charts_Stddev_Fields>;
  stddev_pop?: Maybe<Charts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Charts_Stddev_Samp_Fields>;
  sum?: Maybe<Charts_Sum_Fields>;
  var_pop?: Maybe<Charts_Var_Pop_Fields>;
  var_samp?: Maybe<Charts_Var_Samp_Fields>;
  variance?: Maybe<Charts_Variance_Fields>;
};


/** aggregate fields of "charts" */
export type Charts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Charts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "charts" */
export type Charts_Aggregate_Order_By = {
  avg?: Maybe<Charts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Charts_Max_Order_By>;
  min?: Maybe<Charts_Min_Order_By>;
  stddev?: Maybe<Charts_Stddev_Order_By>;
  stddev_pop?: Maybe<Charts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Charts_Stddev_Samp_Order_By>;
  sum?: Maybe<Charts_Sum_Order_By>;
  var_pop?: Maybe<Charts_Var_Pop_Order_By>;
  var_samp?: Maybe<Charts_Var_Samp_Order_By>;
  variance?: Maybe<Charts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "charts" */
export type Charts_Arr_Rel_Insert_Input = {
  data: Array<Charts_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Charts_On_Conflict>;
};

/** aggregate avg on columns */
export type Charts_Avg_Fields = {
  __typename?: 'charts_avg_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  workspace_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "charts" */
export type Charts_Avg_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "charts". All fields are combined with a logical 'AND'. */
export type Charts_Bool_Exp = {
  _and?: Maybe<Array<Charts_Bool_Exp>>;
  _not?: Maybe<Charts_Bool_Exp>;
  _or?: Maybe<Array<Charts_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<Notes_Bool_Exp>;
  period?: Maybe<Chart_Periods_Enum_Comparison_Exp>;
  position?: Maybe<Int_Comparison_Exp>;
  settings?: Maybe<User_Chart_Settings_Bool_Exp>;
  style?: Maybe<Chart_Styles_Enum_Comparison_Exp>;
  symbol?: Maybe<String_Comparison_Exp>;
  workspace_id?: Maybe<Int_Comparison_Exp>;
  workspaces?: Maybe<Workspaces_Bool_Exp>;
};

/** unique or primary key constraints on table "charts" */
export enum Charts_Constraint {
  /** unique or primary key constraint */
  ChartsWorkspaceIdPositionKey = 'charts_workspace_id_position_key',
  /** unique or primary key constraint */
  PkCharts = 'pk_charts'
}

/** input type for incrementing numeric columns in table "charts" */
export type Charts_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  workspace_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "charts" */
export type Charts_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Notes_Arr_Rel_Insert_Input>;
  period?: Maybe<Chart_Periods_Enum>;
  position?: Maybe<Scalars['Int']>;
  settings?: Maybe<User_Chart_Settings_Arr_Rel_Insert_Input>;
  style?: Maybe<Chart_Styles_Enum>;
  symbol?: Maybe<Scalars['String']>;
  workspace_id?: Maybe<Scalars['Int']>;
  workspaces?: Maybe<Workspaces_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Charts_Max_Fields = {
  __typename?: 'charts_max_fields';
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
  workspace_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "charts" */
export type Charts_Max_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  symbol?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Charts_Min_Fields = {
  __typename?: 'charts_min_fields';
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  symbol?: Maybe<Scalars['String']>;
  workspace_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "charts" */
export type Charts_Min_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  symbol?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "charts" */
export type Charts_Mutation_Response = {
  __typename?: 'charts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Charts>;
};

/** on conflict condition type for table "charts" */
export type Charts_On_Conflict = {
  constraint: Charts_Constraint;
  update_columns: Array<Charts_Update_Column>;
  where?: Maybe<Charts_Bool_Exp>;
};

/** Ordering options when selecting data from "charts". */
export type Charts_Order_By = {
  id?: Maybe<Order_By>;
  notes_aggregate?: Maybe<Notes_Aggregate_Order_By>;
  period?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  settings_aggregate?: Maybe<User_Chart_Settings_Aggregate_Order_By>;
  style?: Maybe<Order_By>;
  symbol?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
  workspaces?: Maybe<Workspaces_Order_By>;
};

/** primary key columns input for table: charts */
export type Charts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "charts" */
export enum Charts_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Period = 'period',
  /** column name */
  Position = 'position',
  /** column name */
  Style = 'style',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  WorkspaceId = 'workspace_id'
}

/** input type for updating data in table "charts" */
export type Charts_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  period?: Maybe<Chart_Periods_Enum>;
  position?: Maybe<Scalars['Int']>;
  style?: Maybe<Chart_Styles_Enum>;
  symbol?: Maybe<Scalars['String']>;
  workspace_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Charts_Stddev_Fields = {
  __typename?: 'charts_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  workspace_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "charts" */
export type Charts_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Charts_Stddev_Pop_Fields = {
  __typename?: 'charts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  workspace_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "charts" */
export type Charts_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Charts_Stddev_Samp_Fields = {
  __typename?: 'charts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  workspace_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "charts" */
export type Charts_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Charts_Sum_Fields = {
  __typename?: 'charts_sum_fields';
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  workspace_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "charts" */
export type Charts_Sum_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
};

/** update columns of table "charts" */
export enum Charts_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Period = 'period',
  /** column name */
  Position = 'position',
  /** column name */
  Style = 'style',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  WorkspaceId = 'workspace_id'
}

/** aggregate var_pop on columns */
export type Charts_Var_Pop_Fields = {
  __typename?: 'charts_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  workspace_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "charts" */
export type Charts_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Charts_Var_Samp_Fields = {
  __typename?: 'charts_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  workspace_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "charts" */
export type Charts_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Charts_Variance_Fields = {
  __typename?: 'charts_variance_fields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  workspace_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "charts" */
export type Charts_Variance_Order_By = {
  id?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  workspace_id?: Maybe<Order_By>;
};

/** columns and relationships of "client_charts" */
export type Client_Charts = {
  __typename?: 'client_charts';
  client_id: Scalars['String'];
  period: Chart_Periods_Enum;
  symbol: Scalars['String'];
  type: Chart_Styles_Enum;
};

/** aggregated selection of "client_charts" */
export type Client_Charts_Aggregate = {
  __typename?: 'client_charts_aggregate';
  aggregate?: Maybe<Client_Charts_Aggregate_Fields>;
  nodes: Array<Client_Charts>;
};

/** aggregate fields of "client_charts" */
export type Client_Charts_Aggregate_Fields = {
  __typename?: 'client_charts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Client_Charts_Max_Fields>;
  min?: Maybe<Client_Charts_Min_Fields>;
};


/** aggregate fields of "client_charts" */
export type Client_Charts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Client_Charts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_charts" */
export type Client_Charts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Client_Charts_Max_Order_By>;
  min?: Maybe<Client_Charts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "client_charts" */
export type Client_Charts_Arr_Rel_Insert_Input = {
  data: Array<Client_Charts_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Client_Charts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client_charts". All fields are combined with a logical 'AND'. */
export type Client_Charts_Bool_Exp = {
  _and?: Maybe<Array<Client_Charts_Bool_Exp>>;
  _not?: Maybe<Client_Charts_Bool_Exp>;
  _or?: Maybe<Array<Client_Charts_Bool_Exp>>;
  client_id?: Maybe<String_Comparison_Exp>;
  period?: Maybe<Chart_Periods_Enum_Comparison_Exp>;
  symbol?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Chart_Styles_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_charts" */
export enum Client_Charts_Constraint {
  /** unique or primary key constraint */
  ClientChartsPkey = 'client_charts_pkey'
}

/** input type for inserting data into table "client_charts" */
export type Client_Charts_Insert_Input = {
  client_id?: Maybe<Scalars['String']>;
  period?: Maybe<Chart_Periods_Enum>;
  symbol?: Maybe<Scalars['String']>;
  type?: Maybe<Chart_Styles_Enum>;
};

/** aggregate max on columns */
export type Client_Charts_Max_Fields = {
  __typename?: 'client_charts_max_fields';
  client_id?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "client_charts" */
export type Client_Charts_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  symbol?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Charts_Min_Fields = {
  __typename?: 'client_charts_min_fields';
  client_id?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "client_charts" */
export type Client_Charts_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  symbol?: Maybe<Order_By>;
};

/** response of any mutation on the table "client_charts" */
export type Client_Charts_Mutation_Response = {
  __typename?: 'client_charts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Charts>;
};

/** on conflict condition type for table "client_charts" */
export type Client_Charts_On_Conflict = {
  constraint: Client_Charts_Constraint;
  update_columns: Array<Client_Charts_Update_Column>;
  where?: Maybe<Client_Charts_Bool_Exp>;
};

/** Ordering options when selecting data from "client_charts". */
export type Client_Charts_Order_By = {
  client_id?: Maybe<Order_By>;
  period?: Maybe<Order_By>;
  symbol?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: client_charts */
export type Client_Charts_Pk_Columns_Input = {
  client_id: Scalars['String'];
  symbol: Scalars['String'];
};

/** select columns of table "client_charts" */
export enum Client_Charts_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  Period = 'period',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "client_charts" */
export type Client_Charts_Set_Input = {
  client_id?: Maybe<Scalars['String']>;
  period?: Maybe<Chart_Periods_Enum>;
  symbol?: Maybe<Scalars['String']>;
  type?: Maybe<Chart_Styles_Enum>;
};

/** update columns of table "client_charts" */
export enum Client_Charts_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  Period = 'period',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  Type = 'type'
}

/** columns and relationships of "client_companies" */
export type Client_Companies = {
  __typename?: 'client_companies';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "client_companies" */
export type Client_Companies_Aggregate = {
  __typename?: 'client_companies_aggregate';
  aggregate?: Maybe<Client_Companies_Aggregate_Fields>;
  nodes: Array<Client_Companies>;
};

/** aggregate fields of "client_companies" */
export type Client_Companies_Aggregate_Fields = {
  __typename?: 'client_companies_aggregate_fields';
  avg?: Maybe<Client_Companies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Client_Companies_Max_Fields>;
  min?: Maybe<Client_Companies_Min_Fields>;
  stddev?: Maybe<Client_Companies_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Companies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Companies_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Companies_Sum_Fields>;
  var_pop?: Maybe<Client_Companies_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Companies_Var_Samp_Fields>;
  variance?: Maybe<Client_Companies_Variance_Fields>;
};


/** aggregate fields of "client_companies" */
export type Client_Companies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Client_Companies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Client_Companies_Avg_Fields = {
  __typename?: 'client_companies_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "client_companies". All fields are combined with a logical 'AND'. */
export type Client_Companies_Bool_Exp = {
  _and?: Maybe<Array<Client_Companies_Bool_Exp>>;
  _not?: Maybe<Client_Companies_Bool_Exp>;
  _or?: Maybe<Array<Client_Companies_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_companies" */
export enum Client_Companies_Constraint {
  /** unique or primary key constraint */
  PkClientCompanies = 'pk_client_companies'
}

/** input type for incrementing numeric columns in table "client_companies" */
export type Client_Companies_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "client_companies" */
export type Client_Companies_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Client_Companies_Max_Fields = {
  __typename?: 'client_companies_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Client_Companies_Min_Fields = {
  __typename?: 'client_companies_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "client_companies" */
export type Client_Companies_Mutation_Response = {
  __typename?: 'client_companies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Companies>;
};

/** input type for inserting object relation for remote table "client_companies" */
export type Client_Companies_Obj_Rel_Insert_Input = {
  data: Client_Companies_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Client_Companies_On_Conflict>;
};

/** on conflict condition type for table "client_companies" */
export type Client_Companies_On_Conflict = {
  constraint: Client_Companies_Constraint;
  update_columns: Array<Client_Companies_Update_Column>;
  where?: Maybe<Client_Companies_Bool_Exp>;
};

/** Ordering options when selecting data from "client_companies". */
export type Client_Companies_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: client_companies */
export type Client_Companies_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "client_companies" */
export enum Client_Companies_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "client_companies" */
export type Client_Companies_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Client_Companies_Stddev_Fields = {
  __typename?: 'client_companies_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Client_Companies_Stddev_Pop_Fields = {
  __typename?: 'client_companies_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Client_Companies_Stddev_Samp_Fields = {
  __typename?: 'client_companies_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Client_Companies_Sum_Fields = {
  __typename?: 'client_companies_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "client_companies" */
export enum Client_Companies_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Client_Companies_Var_Pop_Fields = {
  __typename?: 'client_companies_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Client_Companies_Var_Samp_Fields = {
  __typename?: 'client_companies_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Client_Companies_Variance_Fields = {
  __typename?: 'client_companies_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "clients" */
export type Clients = {
  __typename?: 'clients';
  /** An array relationship */
  charts: Array<Client_Charts>;
  /** An aggregate relationship */
  charts_aggregate: Client_Charts_Aggregate;
  /** An object relationship */
  company: Client_Companies;
  company_id: Scalars['Int'];
  /** An array relationship */
  public_notes_client: Array<Public_Notes_Client_Access>;
  /** An aggregate relationship */
  public_notes_client_aggregate: Public_Notes_Client_Access_Aggregate;
  /** An array relationship */
  team_contacts: Array<Team_Contacts>;
  /** An aggregate relationship */
  team_contacts_aggregate: Team_Contacts_Aggregate;
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};


/** columns and relationships of "clients" */
export type ClientsChartsArgs = {
  distinct_on?: Maybe<Array<Client_Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Charts_Order_By>>;
  where?: Maybe<Client_Charts_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsCharts_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Charts_Order_By>>;
  where?: Maybe<Client_Charts_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsPublic_Notes_ClientArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Client_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Client_Access_Order_By>>;
  where?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsPublic_Notes_Client_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Client_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Client_Access_Order_By>>;
  where?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsTeam_ContactsArgs = {
  distinct_on?: Maybe<Array<Team_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Contacts_Order_By>>;
  where?: Maybe<Team_Contacts_Bool_Exp>;
};


/** columns and relationships of "clients" */
export type ClientsTeam_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Team_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Contacts_Order_By>>;
  where?: Maybe<Team_Contacts_Bool_Exp>;
};

/** aggregated selection of "clients" */
export type Clients_Aggregate = {
  __typename?: 'clients_aggregate';
  aggregate?: Maybe<Clients_Aggregate_Fields>;
  nodes: Array<Clients>;
};

/** aggregate fields of "clients" */
export type Clients_Aggregate_Fields = {
  __typename?: 'clients_aggregate_fields';
  avg?: Maybe<Clients_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Clients_Max_Fields>;
  min?: Maybe<Clients_Min_Fields>;
  stddev?: Maybe<Clients_Stddev_Fields>;
  stddev_pop?: Maybe<Clients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Clients_Stddev_Samp_Fields>;
  sum?: Maybe<Clients_Sum_Fields>;
  var_pop?: Maybe<Clients_Var_Pop_Fields>;
  var_samp?: Maybe<Clients_Var_Samp_Fields>;
  variance?: Maybe<Clients_Variance_Fields>;
};


/** aggregate fields of "clients" */
export type Clients_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Clients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Clients_Avg_Fields = {
  __typename?: 'clients_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "clients". All fields are combined with a logical 'AND'. */
export type Clients_Bool_Exp = {
  _and?: Maybe<Array<Clients_Bool_Exp>>;
  _not?: Maybe<Clients_Bool_Exp>;
  _or?: Maybe<Array<Clients_Bool_Exp>>;
  charts?: Maybe<Client_Charts_Bool_Exp>;
  company?: Maybe<Client_Companies_Bool_Exp>;
  company_id?: Maybe<Int_Comparison_Exp>;
  public_notes_client?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
  team_contacts?: Maybe<Team_Contacts_Bool_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clients" */
export enum Clients_Constraint {
  /** unique or primary key constraint */
  PkOutlineClients = 'pk_outline_clients'
}

/** input type for incrementing numeric columns in table "clients" */
export type Clients_Inc_Input = {
  company_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "clients" */
export type Clients_Insert_Input = {
  charts?: Maybe<Client_Charts_Arr_Rel_Insert_Input>;
  company?: Maybe<Client_Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['Int']>;
  public_notes_client?: Maybe<Public_Notes_Client_Access_Arr_Rel_Insert_Input>;
  team_contacts?: Maybe<Team_Contacts_Arr_Rel_Insert_Input>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Clients_Max_Fields = {
  __typename?: 'clients_max_fields';
  company_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Clients_Min_Fields = {
  __typename?: 'clients_min_fields';
  company_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "clients" */
export type Clients_Mutation_Response = {
  __typename?: 'clients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clients>;
};

/** input type for inserting object relation for remote table "clients" */
export type Clients_Obj_Rel_Insert_Input = {
  data: Clients_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Clients_On_Conflict>;
};

/** on conflict condition type for table "clients" */
export type Clients_On_Conflict = {
  constraint: Clients_Constraint;
  update_columns: Array<Clients_Update_Column>;
  where?: Maybe<Clients_Bool_Exp>;
};

/** Ordering options when selecting data from "clients". */
export type Clients_Order_By = {
  charts_aggregate?: Maybe<Client_Charts_Aggregate_Order_By>;
  company?: Maybe<Client_Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  public_notes_client_aggregate?: Maybe<Public_Notes_Client_Access_Aggregate_Order_By>;
  team_contacts_aggregate?: Maybe<Team_Contacts_Aggregate_Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: clients */
export type Clients_Pk_Columns_Input = {
  user_id: Scalars['String'];
};

/** select columns of table "clients" */
export enum Clients_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "clients" */
export type Clients_Set_Input = {
  company_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Clients_Stddev_Fields = {
  __typename?: 'clients_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Clients_Stddev_Pop_Fields = {
  __typename?: 'clients_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Clients_Stddev_Samp_Fields = {
  __typename?: 'clients_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Clients_Sum_Fields = {
  __typename?: 'clients_sum_fields';
  company_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "clients" */
export enum Clients_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Clients_Var_Pop_Fields = {
  __typename?: 'clients_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Clients_Var_Samp_Fields = {
  __typename?: 'clients_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Clients_Variance_Fields = {
  __typename?: 'clients_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};

/** columns and relationships of "layouts" */
export type Layouts = {
  __typename?: 'layouts';
  value: Scalars['String'];
};

/** aggregated selection of "layouts" */
export type Layouts_Aggregate = {
  __typename?: 'layouts_aggregate';
  aggregate?: Maybe<Layouts_Aggregate_Fields>;
  nodes: Array<Layouts>;
};

/** aggregate fields of "layouts" */
export type Layouts_Aggregate_Fields = {
  __typename?: 'layouts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Layouts_Max_Fields>;
  min?: Maybe<Layouts_Min_Fields>;
};


/** aggregate fields of "layouts" */
export type Layouts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Layouts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "layouts". All fields are combined with a logical 'AND'. */
export type Layouts_Bool_Exp = {
  _and?: Maybe<Array<Layouts_Bool_Exp>>;
  _not?: Maybe<Layouts_Bool_Exp>;
  _or?: Maybe<Array<Layouts_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "layouts" */
export enum Layouts_Constraint {
  /** unique or primary key constraint */
  LayoutsPkey = 'layouts_pkey'
}

export enum Layouts_Enum {
  Layout_1 = 'LAYOUT_1',
  Layout_2h = 'LAYOUT_2h',
  Layout_2v = 'LAYOUT_2v',
  Layout_3b = 'LAYOUT_3b',
  Layout_3h = 'LAYOUT_3h',
  Layout_3l = 'LAYOUT_3l',
  Layout_3r = 'LAYOUT_3r',
  Layout_3t = 'LAYOUT_3t',
  Layout_3v = 'LAYOUT_3v',
  Layout_4 = 'LAYOUT_4',
  Layout_4b = 'LAYOUT_4b',
  Layout_4h = 'LAYOUT_4h',
  Layout_4l = 'LAYOUT_4l',
  Layout_4r = 'LAYOUT_4r',
  Layout_4t = 'LAYOUT_4t',
  Layout_4v = 'LAYOUT_4v',
  Layout_5b = 'LAYOUT_5b',
  Layout_5l = 'LAYOUT_5l',
  Layout_5r = 'LAYOUT_5r',
  Layout_5t = 'LAYOUT_5t',
  Layout_6h = 'LAYOUT_6h',
  Layout_6v = 'LAYOUT_6v'
}

/** Boolean expression to compare columns of type "layouts_enum". All fields are combined with logical 'AND'. */
export type Layouts_Enum_Comparison_Exp = {
  _eq?: Maybe<Layouts_Enum>;
  _in?: Maybe<Array<Layouts_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Layouts_Enum>;
  _nin?: Maybe<Array<Layouts_Enum>>;
};

/** input type for inserting data into table "layouts" */
export type Layouts_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Layouts_Max_Fields = {
  __typename?: 'layouts_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Layouts_Min_Fields = {
  __typename?: 'layouts_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "layouts" */
export type Layouts_Mutation_Response = {
  __typename?: 'layouts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Layouts>;
};

/** on conflict condition type for table "layouts" */
export type Layouts_On_Conflict = {
  constraint: Layouts_Constraint;
  update_columns: Array<Layouts_Update_Column>;
  where?: Maybe<Layouts_Bool_Exp>;
};

/** Ordering options when selecting data from "layouts". */
export type Layouts_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: layouts */
export type Layouts_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "layouts" */
export enum Layouts_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "layouts" */
export type Layouts_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "layouts" */
export enum Layouts_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "message_threads" */
export type Message_Threads = {
  __typename?: 'message_threads';
  channel_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  client: Users;
  client_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  public_note: Public_Notes;
  public_note_id: Scalars['Int'];
  read_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "message_threads" */
export type Message_Threads_Aggregate = {
  __typename?: 'message_threads_aggregate';
  aggregate?: Maybe<Message_Threads_Aggregate_Fields>;
  nodes: Array<Message_Threads>;
};

/** aggregate fields of "message_threads" */
export type Message_Threads_Aggregate_Fields = {
  __typename?: 'message_threads_aggregate_fields';
  avg?: Maybe<Message_Threads_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Message_Threads_Max_Fields>;
  min?: Maybe<Message_Threads_Min_Fields>;
  stddev?: Maybe<Message_Threads_Stddev_Fields>;
  stddev_pop?: Maybe<Message_Threads_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Message_Threads_Stddev_Samp_Fields>;
  sum?: Maybe<Message_Threads_Sum_Fields>;
  var_pop?: Maybe<Message_Threads_Var_Pop_Fields>;
  var_samp?: Maybe<Message_Threads_Var_Samp_Fields>;
  variance?: Maybe<Message_Threads_Variance_Fields>;
};


/** aggregate fields of "message_threads" */
export type Message_Threads_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Message_Threads_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "message_threads" */
export type Message_Threads_Aggregate_Order_By = {
  avg?: Maybe<Message_Threads_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Message_Threads_Max_Order_By>;
  min?: Maybe<Message_Threads_Min_Order_By>;
  stddev?: Maybe<Message_Threads_Stddev_Order_By>;
  stddev_pop?: Maybe<Message_Threads_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Message_Threads_Stddev_Samp_Order_By>;
  sum?: Maybe<Message_Threads_Sum_Order_By>;
  var_pop?: Maybe<Message_Threads_Var_Pop_Order_By>;
  var_samp?: Maybe<Message_Threads_Var_Samp_Order_By>;
  variance?: Maybe<Message_Threads_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "message_threads" */
export type Message_Threads_Arr_Rel_Insert_Input = {
  data: Array<Message_Threads_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Message_Threads_On_Conflict>;
};

/** aggregate avg on columns */
export type Message_Threads_Avg_Fields = {
  __typename?: 'message_threads_avg_fields';
  id?: Maybe<Scalars['Float']>;
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "message_threads" */
export type Message_Threads_Avg_Order_By = {
  id?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "message_threads". All fields are combined with a logical 'AND'. */
export type Message_Threads_Bool_Exp = {
  _and?: Maybe<Array<Message_Threads_Bool_Exp>>;
  _not?: Maybe<Message_Threads_Bool_Exp>;
  _or?: Maybe<Array<Message_Threads_Bool_Exp>>;
  channel_id?: Maybe<String_Comparison_Exp>;
  client?: Maybe<Users_Bool_Exp>;
  client_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  public_note?: Maybe<Public_Notes_Bool_Exp>;
  public_note_id?: Maybe<Int_Comparison_Exp>;
  read_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "message_threads" */
export enum Message_Threads_Constraint {
  /** unique or primary key constraint */
  MessageThreadsPkey = 'message_threads_pkey',
  /** unique or primary key constraint */
  MessageThreadsPublicNoteIdClientIdKey = 'message_threads_public_note_id_client_id_key'
}

/** input type for incrementing numeric columns in table "message_threads" */
export type Message_Threads_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  public_note_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "message_threads" */
export type Message_Threads_Insert_Input = {
  channel_id?: Maybe<Scalars['String']>;
  client?: Maybe<Users_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  public_note?: Maybe<Public_Notes_Obj_Rel_Insert_Input>;
  public_note_id?: Maybe<Scalars['Int']>;
  read_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Message_Threads_Max_Fields = {
  __typename?: 'message_threads_max_fields';
  channel_id?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  public_note_id?: Maybe<Scalars['Int']>;
  read_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "message_threads" */
export type Message_Threads_Max_Order_By = {
  channel_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Message_Threads_Min_Fields = {
  __typename?: 'message_threads_min_fields';
  channel_id?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  public_note_id?: Maybe<Scalars['Int']>;
  read_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "message_threads" */
export type Message_Threads_Min_Order_By = {
  channel_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "message_threads" */
export type Message_Threads_Mutation_Response = {
  __typename?: 'message_threads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Message_Threads>;
};

/** on conflict condition type for table "message_threads" */
export type Message_Threads_On_Conflict = {
  constraint: Message_Threads_Constraint;
  update_columns: Array<Message_Threads_Update_Column>;
  where?: Maybe<Message_Threads_Bool_Exp>;
};

/** Ordering options when selecting data from "message_threads". */
export type Message_Threads_Order_By = {
  channel_id?: Maybe<Order_By>;
  client?: Maybe<Users_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  public_note?: Maybe<Public_Notes_Order_By>;
  public_note_id?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
};

/** primary key columns input for table: message_threads */
export type Message_Threads_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "message_threads" */
export enum Message_Threads_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PublicNoteId = 'public_note_id',
  /** column name */
  ReadAt = 'read_at'
}

/** input type for updating data in table "message_threads" */
export type Message_Threads_Set_Input = {
  channel_id?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  public_note_id?: Maybe<Scalars['Int']>;
  read_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Message_Threads_Stddev_Fields = {
  __typename?: 'message_threads_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "message_threads" */
export type Message_Threads_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Message_Threads_Stddev_Pop_Fields = {
  __typename?: 'message_threads_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "message_threads" */
export type Message_Threads_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Message_Threads_Stddev_Samp_Fields = {
  __typename?: 'message_threads_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "message_threads" */
export type Message_Threads_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Message_Threads_Sum_Fields = {
  __typename?: 'message_threads_sum_fields';
  id?: Maybe<Scalars['Int']>;
  public_note_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "message_threads" */
export type Message_Threads_Sum_Order_By = {
  id?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** update columns of table "message_threads" */
export enum Message_Threads_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PublicNoteId = 'public_note_id',
  /** column name */
  ReadAt = 'read_at'
}

/** aggregate var_pop on columns */
export type Message_Threads_Var_Pop_Fields = {
  __typename?: 'message_threads_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "message_threads" */
export type Message_Threads_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Message_Threads_Var_Samp_Fields = {
  __typename?: 'message_threads_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "message_threads" */
export type Message_Threads_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Message_Threads_Variance_Fields = {
  __typename?: 'message_threads_variance_fields';
  id?: Maybe<Scalars['Float']>;
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "message_threads" */
export type Message_Threads_Variance_Order_By = {
  id?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "chart_indicators" */
  delete_chart_indicators?: Maybe<Chart_Indicators_Mutation_Response>;
  /** delete single row from the table: "chart_indicators" */
  delete_chart_indicators_by_pk?: Maybe<Chart_Indicators>;
  /** delete data from the table: "chart_periods" */
  delete_chart_periods?: Maybe<Chart_Periods_Mutation_Response>;
  /** delete single row from the table: "chart_periods" */
  delete_chart_periods_by_pk?: Maybe<Chart_Periods>;
  /** delete data from the table: "chart_styles" */
  delete_chart_styles?: Maybe<Chart_Styles_Mutation_Response>;
  /** delete single row from the table: "chart_styles" */
  delete_chart_styles_by_pk?: Maybe<Chart_Styles>;
  /** delete data from the table: "charts" */
  delete_charts?: Maybe<Charts_Mutation_Response>;
  /** delete single row from the table: "charts" */
  delete_charts_by_pk?: Maybe<Charts>;
  /** delete data from the table: "client_charts" */
  delete_client_charts?: Maybe<Client_Charts_Mutation_Response>;
  /** delete single row from the table: "client_charts" */
  delete_client_charts_by_pk?: Maybe<Client_Charts>;
  /** delete data from the table: "client_companies" */
  delete_client_companies?: Maybe<Client_Companies_Mutation_Response>;
  /** delete single row from the table: "client_companies" */
  delete_client_companies_by_pk?: Maybe<Client_Companies>;
  /** delete data from the table: "clients" */
  delete_clients?: Maybe<Clients_Mutation_Response>;
  /** delete single row from the table: "clients" */
  delete_clients_by_pk?: Maybe<Clients>;
  /** delete data from the table: "layouts" */
  delete_layouts?: Maybe<Layouts_Mutation_Response>;
  /** delete single row from the table: "layouts" */
  delete_layouts_by_pk?: Maybe<Layouts>;
  /** delete data from the table: "message_threads" */
  delete_message_threads?: Maybe<Message_Threads_Mutation_Response>;
  /** delete single row from the table: "message_threads" */
  delete_message_threads_by_pk?: Maybe<Message_Threads>;
  /** delete data from the table: "note_types" */
  delete_note_types?: Maybe<Note_Types_Mutation_Response>;
  /** delete single row from the table: "note_types" */
  delete_note_types_by_pk?: Maybe<Note_Types>;
  /** delete data from the table: "notes" */
  delete_notes?: Maybe<Notes_Mutation_Response>;
  /** delete single row from the table: "notes" */
  delete_notes_by_pk?: Maybe<Notes>;
  /** delete data from the table: "notes_users_notifications" */
  delete_notes_users_notifications?: Maybe<Notes_Users_Notifications_Mutation_Response>;
  /** delete single row from the table: "notes_users_notifications" */
  delete_notes_users_notifications_by_pk?: Maybe<Notes_Users_Notifications>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "outline_users" */
  delete_outline_users?: Maybe<Outline_Users_Mutation_Response>;
  /** delete single row from the table: "outline_users" */
  delete_outline_users_by_pk?: Maybe<Outline_Users>;
  /** delete data from the table: "permissions" */
  delete_permissions?: Maybe<Permissions_Mutation_Response>;
  /** delete single row from the table: "permissions" */
  delete_permissions_by_pk?: Maybe<Permissions>;
  /** delete data from the table: "public_notes" */
  delete_public_notes?: Maybe<Public_Notes_Mutation_Response>;
  /** delete single row from the table: "public_notes" */
  delete_public_notes_by_pk?: Maybe<Public_Notes>;
  /** delete data from the table: "public_notes_client_access" */
  delete_public_notes_client_access?: Maybe<Public_Notes_Client_Access_Mutation_Response>;
  /** delete single row from the table: "public_notes_client_access" */
  delete_public_notes_client_access_by_pk?: Maybe<Public_Notes_Client_Access>;
  /** delete data from the table: "public_notes_team_access" */
  delete_public_notes_team_access?: Maybe<Public_Notes_Team_Access_Mutation_Response>;
  /** delete single row from the table: "public_notes_team_access" */
  delete_public_notes_team_access_by_pk?: Maybe<Public_Notes_Team_Access>;
  /** delete data from the table: "team_contacts" */
  delete_team_contacts?: Maybe<Team_Contacts_Mutation_Response>;
  /** delete single row from the table: "team_contacts" */
  delete_team_contacts_by_pk?: Maybe<Team_Contacts>;
  /** delete data from the table: "teams" */
  delete_teams?: Maybe<Teams_Mutation_Response>;
  /** delete single row from the table: "teams" */
  delete_teams_by_pk?: Maybe<Teams>;
  /** delete data from the table: "user_chart_settings" */
  delete_user_chart_settings?: Maybe<User_Chart_Settings_Mutation_Response>;
  /** delete single row from the table: "user_chart_settings" */
  delete_user_chart_settings_by_pk?: Maybe<User_Chart_Settings>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "users_online" */
  delete_users_online?: Maybe<Users_Online_Mutation_Response>;
  /** delete data from the table: "workspaces" */
  delete_workspaces?: Maybe<Workspaces_Mutation_Response>;
  /** delete single row from the table: "workspaces" */
  delete_workspaces_by_pk?: Maybe<Workspaces>;
  /** insert data into the table: "chart_indicators" */
  insert_chart_indicators?: Maybe<Chart_Indicators_Mutation_Response>;
  /** insert a single row into the table: "chart_indicators" */
  insert_chart_indicators_one?: Maybe<Chart_Indicators>;
  /** insert data into the table: "chart_periods" */
  insert_chart_periods?: Maybe<Chart_Periods_Mutation_Response>;
  /** insert a single row into the table: "chart_periods" */
  insert_chart_periods_one?: Maybe<Chart_Periods>;
  /** insert data into the table: "chart_styles" */
  insert_chart_styles?: Maybe<Chart_Styles_Mutation_Response>;
  /** insert a single row into the table: "chart_styles" */
  insert_chart_styles_one?: Maybe<Chart_Styles>;
  /** insert data into the table: "charts" */
  insert_charts?: Maybe<Charts_Mutation_Response>;
  /** insert a single row into the table: "charts" */
  insert_charts_one?: Maybe<Charts>;
  /** insert data into the table: "client_charts" */
  insert_client_charts?: Maybe<Client_Charts_Mutation_Response>;
  /** insert a single row into the table: "client_charts" */
  insert_client_charts_one?: Maybe<Client_Charts>;
  /** insert data into the table: "client_companies" */
  insert_client_companies?: Maybe<Client_Companies_Mutation_Response>;
  /** insert a single row into the table: "client_companies" */
  insert_client_companies_one?: Maybe<Client_Companies>;
  /** insert data into the table: "clients" */
  insert_clients?: Maybe<Clients_Mutation_Response>;
  /** insert a single row into the table: "clients" */
  insert_clients_one?: Maybe<Clients>;
  /** insert data into the table: "layouts" */
  insert_layouts?: Maybe<Layouts_Mutation_Response>;
  /** insert a single row into the table: "layouts" */
  insert_layouts_one?: Maybe<Layouts>;
  /** insert data into the table: "message_threads" */
  insert_message_threads?: Maybe<Message_Threads_Mutation_Response>;
  /** insert a single row into the table: "message_threads" */
  insert_message_threads_one?: Maybe<Message_Threads>;
  /** insert data into the table: "note_types" */
  insert_note_types?: Maybe<Note_Types_Mutation_Response>;
  /** insert a single row into the table: "note_types" */
  insert_note_types_one?: Maybe<Note_Types>;
  /** insert data into the table: "notes" */
  insert_notes?: Maybe<Notes_Mutation_Response>;
  /** insert a single row into the table: "notes" */
  insert_notes_one?: Maybe<Notes>;
  /** insert data into the table: "notes_users_notifications" */
  insert_notes_users_notifications?: Maybe<Notes_Users_Notifications_Mutation_Response>;
  /** insert a single row into the table: "notes_users_notifications" */
  insert_notes_users_notifications_one?: Maybe<Notes_Users_Notifications>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<Organizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "outline_users" */
  insert_outline_users?: Maybe<Outline_Users_Mutation_Response>;
  /** insert a single row into the table: "outline_users" */
  insert_outline_users_one?: Maybe<Outline_Users>;
  /** insert data into the table: "permissions" */
  insert_permissions?: Maybe<Permissions_Mutation_Response>;
  /** insert a single row into the table: "permissions" */
  insert_permissions_one?: Maybe<Permissions>;
  /** insert data into the table: "public_notes" */
  insert_public_notes?: Maybe<Public_Notes_Mutation_Response>;
  /** insert data into the table: "public_notes_client_access" */
  insert_public_notes_client_access?: Maybe<Public_Notes_Client_Access_Mutation_Response>;
  /** insert a single row into the table: "public_notes_client_access" */
  insert_public_notes_client_access_one?: Maybe<Public_Notes_Client_Access>;
  /** insert a single row into the table: "public_notes" */
  insert_public_notes_one?: Maybe<Public_Notes>;
  /** insert data into the table: "public_notes_team_access" */
  insert_public_notes_team_access?: Maybe<Public_Notes_Team_Access_Mutation_Response>;
  /** insert a single row into the table: "public_notes_team_access" */
  insert_public_notes_team_access_one?: Maybe<Public_Notes_Team_Access>;
  /** insert data into the table: "team_contacts" */
  insert_team_contacts?: Maybe<Team_Contacts_Mutation_Response>;
  /** insert a single row into the table: "team_contacts" */
  insert_team_contacts_one?: Maybe<Team_Contacts>;
  /** insert data into the table: "teams" */
  insert_teams?: Maybe<Teams_Mutation_Response>;
  /** insert a single row into the table: "teams" */
  insert_teams_one?: Maybe<Teams>;
  /** insert data into the table: "user_chart_settings" */
  insert_user_chart_settings?: Maybe<User_Chart_Settings_Mutation_Response>;
  /** insert a single row into the table: "user_chart_settings" */
  insert_user_chart_settings_one?: Maybe<User_Chart_Settings>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "users_online" */
  insert_users_online?: Maybe<Users_Online_Mutation_Response>;
  /** insert a single row into the table: "users_online" */
  insert_users_online_one?: Maybe<Users_Online>;
  /** insert data into the table: "workspaces" */
  insert_workspaces?: Maybe<Workspaces_Mutation_Response>;
  /** insert a single row into the table: "workspaces" */
  insert_workspaces_one?: Maybe<Workspaces>;
  publishNote?: Maybe<PublishNoteOutput>;
  unpublishNote?: Maybe<UnpublishNoteOutput>;
  /** update data of the table: "chart_indicators" */
  update_chart_indicators?: Maybe<Chart_Indicators_Mutation_Response>;
  /** update single row of the table: "chart_indicators" */
  update_chart_indicators_by_pk?: Maybe<Chart_Indicators>;
  /** update data of the table: "chart_periods" */
  update_chart_periods?: Maybe<Chart_Periods_Mutation_Response>;
  /** update single row of the table: "chart_periods" */
  update_chart_periods_by_pk?: Maybe<Chart_Periods>;
  /** update data of the table: "chart_styles" */
  update_chart_styles?: Maybe<Chart_Styles_Mutation_Response>;
  /** update single row of the table: "chart_styles" */
  update_chart_styles_by_pk?: Maybe<Chart_Styles>;
  /** update data of the table: "charts" */
  update_charts?: Maybe<Charts_Mutation_Response>;
  /** update single row of the table: "charts" */
  update_charts_by_pk?: Maybe<Charts>;
  /** update data of the table: "client_charts" */
  update_client_charts?: Maybe<Client_Charts_Mutation_Response>;
  /** update single row of the table: "client_charts" */
  update_client_charts_by_pk?: Maybe<Client_Charts>;
  /** update data of the table: "client_companies" */
  update_client_companies?: Maybe<Client_Companies_Mutation_Response>;
  /** update single row of the table: "client_companies" */
  update_client_companies_by_pk?: Maybe<Client_Companies>;
  /** update data of the table: "clients" */
  update_clients?: Maybe<Clients_Mutation_Response>;
  /** update single row of the table: "clients" */
  update_clients_by_pk?: Maybe<Clients>;
  /** update data of the table: "layouts" */
  update_layouts?: Maybe<Layouts_Mutation_Response>;
  /** update single row of the table: "layouts" */
  update_layouts_by_pk?: Maybe<Layouts>;
  /** update data of the table: "message_threads" */
  update_message_threads?: Maybe<Message_Threads_Mutation_Response>;
  /** update single row of the table: "message_threads" */
  update_message_threads_by_pk?: Maybe<Message_Threads>;
  /** update data of the table: "note_types" */
  update_note_types?: Maybe<Note_Types_Mutation_Response>;
  /** update single row of the table: "note_types" */
  update_note_types_by_pk?: Maybe<Note_Types>;
  /** update data of the table: "notes" */
  update_notes?: Maybe<Notes_Mutation_Response>;
  /** update single row of the table: "notes" */
  update_notes_by_pk?: Maybe<Notes>;
  /** update data of the table: "notes_users_notifications" */
  update_notes_users_notifications?: Maybe<Notes_Users_Notifications_Mutation_Response>;
  /** update single row of the table: "notes_users_notifications" */
  update_notes_users_notifications_by_pk?: Maybe<Notes_Users_Notifications>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<Organizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update data of the table: "outline_users" */
  update_outline_users?: Maybe<Outline_Users_Mutation_Response>;
  /** update single row of the table: "outline_users" */
  update_outline_users_by_pk?: Maybe<Outline_Users>;
  /** update data of the table: "permissions" */
  update_permissions?: Maybe<Permissions_Mutation_Response>;
  /** update single row of the table: "permissions" */
  update_permissions_by_pk?: Maybe<Permissions>;
  /** update data of the table: "public_notes" */
  update_public_notes?: Maybe<Public_Notes_Mutation_Response>;
  /** update single row of the table: "public_notes" */
  update_public_notes_by_pk?: Maybe<Public_Notes>;
  /** update data of the table: "public_notes_client_access" */
  update_public_notes_client_access?: Maybe<Public_Notes_Client_Access_Mutation_Response>;
  /** update single row of the table: "public_notes_client_access" */
  update_public_notes_client_access_by_pk?: Maybe<Public_Notes_Client_Access>;
  /** update data of the table: "public_notes_team_access" */
  update_public_notes_team_access?: Maybe<Public_Notes_Team_Access_Mutation_Response>;
  /** update single row of the table: "public_notes_team_access" */
  update_public_notes_team_access_by_pk?: Maybe<Public_Notes_Team_Access>;
  /** update data of the table: "team_contacts" */
  update_team_contacts?: Maybe<Team_Contacts_Mutation_Response>;
  /** update single row of the table: "team_contacts" */
  update_team_contacts_by_pk?: Maybe<Team_Contacts>;
  /** update data of the table: "teams" */
  update_teams?: Maybe<Teams_Mutation_Response>;
  /** update single row of the table: "teams" */
  update_teams_by_pk?: Maybe<Teams>;
  /** update data of the table: "user_chart_settings" */
  update_user_chart_settings?: Maybe<User_Chart_Settings_Mutation_Response>;
  /** update single row of the table: "user_chart_settings" */
  update_user_chart_settings_by_pk?: Maybe<User_Chart_Settings>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "users_online" */
  update_users_online?: Maybe<Users_Online_Mutation_Response>;
  /** update data of the table: "workspaces" */
  update_workspaces?: Maybe<Workspaces_Mutation_Response>;
  /** update single row of the table: "workspaces" */
  update_workspaces_by_pk?: Maybe<Workspaces>;
};


/** mutation root */
export type Mutation_RootDelete_Chart_IndicatorsArgs = {
  where: Chart_Indicators_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chart_Indicators_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Chart_PeriodsArgs = {
  where: Chart_Periods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chart_Periods_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Chart_StylesArgs = {
  where: Chart_Styles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chart_Styles_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ChartsArgs = {
  where: Charts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Charts_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Client_ChartsArgs = {
  where: Client_Charts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Charts_By_PkArgs = {
  client_id: Scalars['String'];
  symbol: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Client_CompaniesArgs = {
  where: Client_Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Companies_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ClientsArgs = {
  where: Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clients_By_PkArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_LayoutsArgs = {
  where: Layouts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Layouts_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Message_ThreadsArgs = {
  where: Message_Threads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Message_Threads_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Note_TypesArgs = {
  where: Note_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Note_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_NotesArgs = {
  where: Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Notes_Users_NotificationsArgs = {
  where: Notes_Users_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notes_Users_Notifications_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_OrganizationsArgs = {
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Outline_UsersArgs = {
  where: Outline_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outline_Users_By_PkArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PermissionsArgs = {
  where: Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Permissions_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Public_NotesArgs = {
  where: Public_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Public_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Public_Notes_Client_AccessArgs = {
  where: Public_Notes_Client_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Public_Notes_Client_Access_By_PkArgs = {
  client_id: Scalars['String'];
  public_note_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Public_Notes_Team_AccessArgs = {
  where: Public_Notes_Team_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Public_Notes_Team_Access_By_PkArgs = {
  public_note_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Team_ContactsArgs = {
  where: Team_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Contacts_By_PkArgs = {
  client_id: Scalars['String'];
  team_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TeamsArgs = {
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_Chart_SettingsArgs = {
  where: User_Chart_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Chart_Settings_By_PkArgs = {
  chart_id: Scalars['Int'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Users_OnlineArgs = {
  where: Users_Online_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WorkspacesArgs = {
  where: Workspaces_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Workspaces_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_Chart_IndicatorsArgs = {
  objects: Array<Chart_Indicators_Insert_Input>;
  on_conflict?: Maybe<Chart_Indicators_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chart_Indicators_OneArgs = {
  object: Chart_Indicators_Insert_Input;
  on_conflict?: Maybe<Chart_Indicators_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chart_PeriodsArgs = {
  objects: Array<Chart_Periods_Insert_Input>;
  on_conflict?: Maybe<Chart_Periods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chart_Periods_OneArgs = {
  object: Chart_Periods_Insert_Input;
  on_conflict?: Maybe<Chart_Periods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chart_StylesArgs = {
  objects: Array<Chart_Styles_Insert_Input>;
  on_conflict?: Maybe<Chart_Styles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chart_Styles_OneArgs = {
  object: Chart_Styles_Insert_Input;
  on_conflict?: Maybe<Chart_Styles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ChartsArgs = {
  objects: Array<Charts_Insert_Input>;
  on_conflict?: Maybe<Charts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Charts_OneArgs = {
  object: Charts_Insert_Input;
  on_conflict?: Maybe<Charts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_ChartsArgs = {
  objects: Array<Client_Charts_Insert_Input>;
  on_conflict?: Maybe<Client_Charts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Charts_OneArgs = {
  object: Client_Charts_Insert_Input;
  on_conflict?: Maybe<Client_Charts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_CompaniesArgs = {
  objects: Array<Client_Companies_Insert_Input>;
  on_conflict?: Maybe<Client_Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Companies_OneArgs = {
  object: Client_Companies_Insert_Input;
  on_conflict?: Maybe<Client_Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientsArgs = {
  objects: Array<Clients_Insert_Input>;
  on_conflict?: Maybe<Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clients_OneArgs = {
  object: Clients_Insert_Input;
  on_conflict?: Maybe<Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LayoutsArgs = {
  objects: Array<Layouts_Insert_Input>;
  on_conflict?: Maybe<Layouts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Layouts_OneArgs = {
  object: Layouts_Insert_Input;
  on_conflict?: Maybe<Layouts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Message_ThreadsArgs = {
  objects: Array<Message_Threads_Insert_Input>;
  on_conflict?: Maybe<Message_Threads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Message_Threads_OneArgs = {
  object: Message_Threads_Insert_Input;
  on_conflict?: Maybe<Message_Threads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Note_TypesArgs = {
  objects: Array<Note_Types_Insert_Input>;
  on_conflict?: Maybe<Note_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Note_Types_OneArgs = {
  object: Note_Types_Insert_Input;
  on_conflict?: Maybe<Note_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotesArgs = {
  objects: Array<Notes_Insert_Input>;
  on_conflict?: Maybe<Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notes_OneArgs = {
  object: Notes_Insert_Input;
  on_conflict?: Maybe<Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notes_Users_NotificationsArgs = {
  objects: Array<Notes_Users_Notifications_Insert_Input>;
  on_conflict?: Maybe<Notes_Users_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notes_Users_Notifications_OneArgs = {
  object: Notes_Users_Notifications_Insert_Input;
  on_conflict?: Maybe<Notes_Users_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganizationsArgs = {
  objects: Array<Organizations_Insert_Input>;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_OneArgs = {
  object: Organizations_Insert_Input;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outline_UsersArgs = {
  objects: Array<Outline_Users_Insert_Input>;
  on_conflict?: Maybe<Outline_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outline_Users_OneArgs = {
  object: Outline_Users_Insert_Input;
  on_conflict?: Maybe<Outline_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PermissionsArgs = {
  objects: Array<Permissions_Insert_Input>;
  on_conflict?: Maybe<Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Permissions_OneArgs = {
  object: Permissions_Insert_Input;
  on_conflict?: Maybe<Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_NotesArgs = {
  objects: Array<Public_Notes_Insert_Input>;
  on_conflict?: Maybe<Public_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_Notes_Client_AccessArgs = {
  objects: Array<Public_Notes_Client_Access_Insert_Input>;
  on_conflict?: Maybe<Public_Notes_Client_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_Notes_Client_Access_OneArgs = {
  object: Public_Notes_Client_Access_Insert_Input;
  on_conflict?: Maybe<Public_Notes_Client_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_Notes_OneArgs = {
  object: Public_Notes_Insert_Input;
  on_conflict?: Maybe<Public_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_Notes_Team_AccessArgs = {
  objects: Array<Public_Notes_Team_Access_Insert_Input>;
  on_conflict?: Maybe<Public_Notes_Team_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_Notes_Team_Access_OneArgs = {
  object: Public_Notes_Team_Access_Insert_Input;
  on_conflict?: Maybe<Public_Notes_Team_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_ContactsArgs = {
  objects: Array<Team_Contacts_Insert_Input>;
  on_conflict?: Maybe<Team_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Contacts_OneArgs = {
  object: Team_Contacts_Insert_Input;
  on_conflict?: Maybe<Team_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamsArgs = {
  objects: Array<Teams_Insert_Input>;
  on_conflict?: Maybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_OneArgs = {
  object: Teams_Insert_Input;
  on_conflict?: Maybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Chart_SettingsArgs = {
  objects: Array<User_Chart_Settings_Insert_Input>;
  on_conflict?: Maybe<User_Chart_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Chart_Settings_OneArgs = {
  object: User_Chart_Settings_Insert_Input;
  on_conflict?: Maybe<User_Chart_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OnlineArgs = {
  objects: Array<Users_Online_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Online_OneArgs = {
  object: Users_Online_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_WorkspacesArgs = {
  objects: Array<Workspaces_Insert_Input>;
  on_conflict?: Maybe<Workspaces_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workspaces_OneArgs = {
  object: Workspaces_Insert_Input;
  on_conflict?: Maybe<Workspaces_On_Conflict>;
};


/** mutation root */
export type Mutation_RootPublishNoteArgs = {
  notePublishingInfo: NotePublishingInfo;
};


/** mutation root */
export type Mutation_RootUnpublishNoteArgs = {
  noteId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootUpdate_Chart_IndicatorsArgs = {
  _set?: Maybe<Chart_Indicators_Set_Input>;
  where: Chart_Indicators_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chart_Indicators_By_PkArgs = {
  _set?: Maybe<Chart_Indicators_Set_Input>;
  pk_columns: Chart_Indicators_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chart_PeriodsArgs = {
  _set?: Maybe<Chart_Periods_Set_Input>;
  where: Chart_Periods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chart_Periods_By_PkArgs = {
  _set?: Maybe<Chart_Periods_Set_Input>;
  pk_columns: Chart_Periods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chart_StylesArgs = {
  _set?: Maybe<Chart_Styles_Set_Input>;
  where: Chart_Styles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chart_Styles_By_PkArgs = {
  _set?: Maybe<Chart_Styles_Set_Input>;
  pk_columns: Chart_Styles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ChartsArgs = {
  _inc?: Maybe<Charts_Inc_Input>;
  _set?: Maybe<Charts_Set_Input>;
  where: Charts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Charts_By_PkArgs = {
  _inc?: Maybe<Charts_Inc_Input>;
  _set?: Maybe<Charts_Set_Input>;
  pk_columns: Charts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_ChartsArgs = {
  _set?: Maybe<Client_Charts_Set_Input>;
  where: Client_Charts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Charts_By_PkArgs = {
  _set?: Maybe<Client_Charts_Set_Input>;
  pk_columns: Client_Charts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_CompaniesArgs = {
  _inc?: Maybe<Client_Companies_Inc_Input>;
  _set?: Maybe<Client_Companies_Set_Input>;
  where: Client_Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Companies_By_PkArgs = {
  _inc?: Maybe<Client_Companies_Inc_Input>;
  _set?: Maybe<Client_Companies_Set_Input>;
  pk_columns: Client_Companies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClientsArgs = {
  _inc?: Maybe<Clients_Inc_Input>;
  _set?: Maybe<Clients_Set_Input>;
  where: Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clients_By_PkArgs = {
  _inc?: Maybe<Clients_Inc_Input>;
  _set?: Maybe<Clients_Set_Input>;
  pk_columns: Clients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LayoutsArgs = {
  _set?: Maybe<Layouts_Set_Input>;
  where: Layouts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Layouts_By_PkArgs = {
  _set?: Maybe<Layouts_Set_Input>;
  pk_columns: Layouts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Message_ThreadsArgs = {
  _inc?: Maybe<Message_Threads_Inc_Input>;
  _set?: Maybe<Message_Threads_Set_Input>;
  where: Message_Threads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Message_Threads_By_PkArgs = {
  _inc?: Maybe<Message_Threads_Inc_Input>;
  _set?: Maybe<Message_Threads_Set_Input>;
  pk_columns: Message_Threads_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Note_TypesArgs = {
  _set?: Maybe<Note_Types_Set_Input>;
  where: Note_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Note_Types_By_PkArgs = {
  _set?: Maybe<Note_Types_Set_Input>;
  pk_columns: Note_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NotesArgs = {
  _inc?: Maybe<Notes_Inc_Input>;
  _set?: Maybe<Notes_Set_Input>;
  where: Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notes_By_PkArgs = {
  _inc?: Maybe<Notes_Inc_Input>;
  _set?: Maybe<Notes_Set_Input>;
  pk_columns: Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notes_Users_NotificationsArgs = {
  _inc?: Maybe<Notes_Users_Notifications_Inc_Input>;
  _set?: Maybe<Notes_Users_Notifications_Set_Input>;
  where: Notes_Users_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notes_Users_Notifications_By_PkArgs = {
  _inc?: Maybe<Notes_Users_Notifications_Inc_Input>;
  _set?: Maybe<Notes_Users_Notifications_Set_Input>;
  pk_columns: Notes_Users_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationsArgs = {
  _inc?: Maybe<Organizations_Inc_Input>;
  _set?: Maybe<Organizations_Set_Input>;
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_By_PkArgs = {
  _inc?: Maybe<Organizations_Inc_Input>;
  _set?: Maybe<Organizations_Set_Input>;
  pk_columns: Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outline_UsersArgs = {
  _inc?: Maybe<Outline_Users_Inc_Input>;
  _set?: Maybe<Outline_Users_Set_Input>;
  where: Outline_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outline_Users_By_PkArgs = {
  _inc?: Maybe<Outline_Users_Inc_Input>;
  _set?: Maybe<Outline_Users_Set_Input>;
  pk_columns: Outline_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PermissionsArgs = {
  _set?: Maybe<Permissions_Set_Input>;
  where: Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Permissions_By_PkArgs = {
  _set?: Maybe<Permissions_Set_Input>;
  pk_columns: Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Public_NotesArgs = {
  _inc?: Maybe<Public_Notes_Inc_Input>;
  _set?: Maybe<Public_Notes_Set_Input>;
  where: Public_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Public_Notes_By_PkArgs = {
  _inc?: Maybe<Public_Notes_Inc_Input>;
  _set?: Maybe<Public_Notes_Set_Input>;
  pk_columns: Public_Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Public_Notes_Client_AccessArgs = {
  _inc?: Maybe<Public_Notes_Client_Access_Inc_Input>;
  _set?: Maybe<Public_Notes_Client_Access_Set_Input>;
  where: Public_Notes_Client_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Public_Notes_Client_Access_By_PkArgs = {
  _inc?: Maybe<Public_Notes_Client_Access_Inc_Input>;
  _set?: Maybe<Public_Notes_Client_Access_Set_Input>;
  pk_columns: Public_Notes_Client_Access_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Public_Notes_Team_AccessArgs = {
  _inc?: Maybe<Public_Notes_Team_Access_Inc_Input>;
  _set?: Maybe<Public_Notes_Team_Access_Set_Input>;
  where: Public_Notes_Team_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Public_Notes_Team_Access_By_PkArgs = {
  _inc?: Maybe<Public_Notes_Team_Access_Inc_Input>;
  _set?: Maybe<Public_Notes_Team_Access_Set_Input>;
  pk_columns: Public_Notes_Team_Access_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_ContactsArgs = {
  _inc?: Maybe<Team_Contacts_Inc_Input>;
  _set?: Maybe<Team_Contacts_Set_Input>;
  where: Team_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Contacts_By_PkArgs = {
  _inc?: Maybe<Team_Contacts_Inc_Input>;
  _set?: Maybe<Team_Contacts_Set_Input>;
  pk_columns: Team_Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TeamsArgs = {
  _inc?: Maybe<Teams_Inc_Input>;
  _set?: Maybe<Teams_Set_Input>;
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_By_PkArgs = {
  _inc?: Maybe<Teams_Inc_Input>;
  _set?: Maybe<Teams_Set_Input>;
  pk_columns: Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Chart_SettingsArgs = {
  _inc?: Maybe<User_Chart_Settings_Inc_Input>;
  _set?: Maybe<User_Chart_Settings_Set_Input>;
  where: User_Chart_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Chart_Settings_By_PkArgs = {
  _inc?: Maybe<User_Chart_Settings_Inc_Input>;
  _set?: Maybe<User_Chart_Settings_Set_Input>;
  pk_columns: User_Chart_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_OnlineArgs = {
  _set?: Maybe<Users_Online_Set_Input>;
  where: Users_Online_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WorkspacesArgs = {
  _inc?: Maybe<Workspaces_Inc_Input>;
  _set?: Maybe<Workspaces_Set_Input>;
  where: Workspaces_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Workspaces_By_PkArgs = {
  _inc?: Maybe<Workspaces_Inc_Input>;
  _set?: Maybe<Workspaces_Set_Input>;
  pk_columns: Workspaces_Pk_Columns_Input;
};

/** columns and relationships of "note_types" */
export type Note_Types = {
  __typename?: 'note_types';
  value: Scalars['String'];
};

/** aggregated selection of "note_types" */
export type Note_Types_Aggregate = {
  __typename?: 'note_types_aggregate';
  aggregate?: Maybe<Note_Types_Aggregate_Fields>;
  nodes: Array<Note_Types>;
};

/** aggregate fields of "note_types" */
export type Note_Types_Aggregate_Fields = {
  __typename?: 'note_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Note_Types_Max_Fields>;
  min?: Maybe<Note_Types_Min_Fields>;
};


/** aggregate fields of "note_types" */
export type Note_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Note_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "note_types". All fields are combined with a logical 'AND'. */
export type Note_Types_Bool_Exp = {
  _and?: Maybe<Array<Note_Types_Bool_Exp>>;
  _not?: Maybe<Note_Types_Bool_Exp>;
  _or?: Maybe<Array<Note_Types_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "note_types" */
export enum Note_Types_Constraint {
  /** unique or primary key constraint */
  NoteTypesPkey = 'note_types_pkey'
}

export enum Note_Types_Enum {
  File = 'file',
  Link = 'link',
  Note = 'note'
}

/** Boolean expression to compare columns of type "note_types_enum". All fields are combined with logical 'AND'. */
export type Note_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Note_Types_Enum>;
  _in?: Maybe<Array<Note_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Note_Types_Enum>;
  _nin?: Maybe<Array<Note_Types_Enum>>;
};

/** input type for inserting data into table "note_types" */
export type Note_Types_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Note_Types_Max_Fields = {
  __typename?: 'note_types_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Note_Types_Min_Fields = {
  __typename?: 'note_types_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "note_types" */
export type Note_Types_Mutation_Response = {
  __typename?: 'note_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Note_Types>;
};

/** on conflict condition type for table "note_types" */
export type Note_Types_On_Conflict = {
  constraint: Note_Types_Constraint;
  update_columns: Array<Note_Types_Update_Column>;
  where?: Maybe<Note_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "note_types". */
export type Note_Types_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: note_types */
export type Note_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "note_types" */
export enum Note_Types_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "note_types" */
export type Note_Types_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "note_types" */
export enum Note_Types_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "notes" */
export type Notes = {
  __typename?: 'notes';
  channel_id?: Maybe<Scalars['String']>;
  chart_symbol: Scalars['String'];
  content?: Maybe<Scalars['json']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  last_modified?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  notes_users_notifications?: Maybe<Notes_Users_Notifications>;
  owner: Scalars['String'];
  permission?: Maybe<Permissions_Enum>;
  /** An object relationship */
  published_note: Public_Notes;
  published_note_id?: Maybe<Scalars['Int']>;
  time: Scalars['timestamptz'];
  title?: Maybe<Scalars['String']>;
  type: Note_Types_Enum;
  /** An object relationship */
  user: Users;
};


/** columns and relationships of "notes" */
export type NotesContentArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "notes" */
export type Notes_Aggregate = {
  __typename?: 'notes_aggregate';
  aggregate?: Maybe<Notes_Aggregate_Fields>;
  nodes: Array<Notes>;
};

/** aggregate fields of "notes" */
export type Notes_Aggregate_Fields = {
  __typename?: 'notes_aggregate_fields';
  avg?: Maybe<Notes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Notes_Max_Fields>;
  min?: Maybe<Notes_Min_Fields>;
  stddev?: Maybe<Notes_Stddev_Fields>;
  stddev_pop?: Maybe<Notes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Notes_Stddev_Samp_Fields>;
  sum?: Maybe<Notes_Sum_Fields>;
  var_pop?: Maybe<Notes_Var_Pop_Fields>;
  var_samp?: Maybe<Notes_Var_Samp_Fields>;
  variance?: Maybe<Notes_Variance_Fields>;
};


/** aggregate fields of "notes" */
export type Notes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notes" */
export type Notes_Aggregate_Order_By = {
  avg?: Maybe<Notes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Notes_Max_Order_By>;
  min?: Maybe<Notes_Min_Order_By>;
  stddev?: Maybe<Notes_Stddev_Order_By>;
  stddev_pop?: Maybe<Notes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Notes_Stddev_Samp_Order_By>;
  sum?: Maybe<Notes_Sum_Order_By>;
  var_pop?: Maybe<Notes_Var_Pop_Order_By>;
  var_samp?: Maybe<Notes_Var_Samp_Order_By>;
  variance?: Maybe<Notes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "notes" */
export type Notes_Arr_Rel_Insert_Input = {
  data: Array<Notes_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Notes_On_Conflict>;
};

/** aggregate avg on columns */
export type Notes_Avg_Fields = {
  __typename?: 'notes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  published_note_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "notes" */
export type Notes_Avg_Order_By = {
  id?: Maybe<Order_By>;
  published_note_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "notes". All fields are combined with a logical 'AND'. */
export type Notes_Bool_Exp = {
  _and?: Maybe<Array<Notes_Bool_Exp>>;
  _not?: Maybe<Notes_Bool_Exp>;
  _or?: Maybe<Array<Notes_Bool_Exp>>;
  channel_id?: Maybe<String_Comparison_Exp>;
  chart_symbol?: Maybe<String_Comparison_Exp>;
  content?: Maybe<Json_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_modified?: Maybe<Timestamptz_Comparison_Exp>;
  notes_users_notifications?: Maybe<Notes_Users_Notifications_Bool_Exp>;
  owner?: Maybe<String_Comparison_Exp>;
  permission?: Maybe<Permissions_Enum_Comparison_Exp>;
  published_note?: Maybe<Public_Notes_Bool_Exp>;
  published_note_id?: Maybe<Int_Comparison_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Note_Types_Enum_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "notes" */
export enum Notes_Constraint {
  /** unique or primary key constraint */
  PkNotes = 'pk_notes'
}

/** input type for incrementing numeric columns in table "notes" */
export type Notes_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  published_note_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "notes" */
export type Notes_Insert_Input = {
  channel_id?: Maybe<Scalars['String']>;
  chart_symbol?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['json']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_modified?: Maybe<Scalars['timestamptz']>;
  notes_users_notifications?: Maybe<Notes_Users_Notifications_Obj_Rel_Insert_Input>;
  owner?: Maybe<Scalars['String']>;
  permission?: Maybe<Permissions_Enum>;
  published_note?: Maybe<Public_Notes_Obj_Rel_Insert_Input>;
  published_note_id?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Note_Types_Enum>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Notes_Max_Fields = {
  __typename?: 'notes_max_fields';
  channel_id?: Maybe<Scalars['String']>;
  chart_symbol?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_modified?: Maybe<Scalars['timestamptz']>;
  owner?: Maybe<Scalars['String']>;
  published_note_id?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "notes" */
export type Notes_Max_Order_By = {
  channel_id?: Maybe<Order_By>;
  chart_symbol?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_modified?: Maybe<Order_By>;
  owner?: Maybe<Order_By>;
  published_note_id?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Notes_Min_Fields = {
  __typename?: 'notes_min_fields';
  channel_id?: Maybe<Scalars['String']>;
  chart_symbol?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_modified?: Maybe<Scalars['timestamptz']>;
  owner?: Maybe<Scalars['String']>;
  published_note_id?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "notes" */
export type Notes_Min_Order_By = {
  channel_id?: Maybe<Order_By>;
  chart_symbol?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_modified?: Maybe<Order_By>;
  owner?: Maybe<Order_By>;
  published_note_id?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "notes" */
export type Notes_Mutation_Response = {
  __typename?: 'notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notes>;
};

/** input type for inserting object relation for remote table "notes" */
export type Notes_Obj_Rel_Insert_Input = {
  data: Notes_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Notes_On_Conflict>;
};

/** on conflict condition type for table "notes" */
export type Notes_On_Conflict = {
  constraint: Notes_Constraint;
  update_columns: Array<Notes_Update_Column>;
  where?: Maybe<Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "notes". */
export type Notes_Order_By = {
  channel_id?: Maybe<Order_By>;
  chart_symbol?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_modified?: Maybe<Order_By>;
  notes_users_notifications?: Maybe<Notes_Users_Notifications_Order_By>;
  owner?: Maybe<Order_By>;
  permission?: Maybe<Order_By>;
  published_note?: Maybe<Public_Notes_Order_By>;
  published_note_id?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: notes */
export type Notes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "notes" */
export enum Notes_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChartSymbol = 'chart_symbol',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'last_modified',
  /** column name */
  Owner = 'owner',
  /** column name */
  Permission = 'permission',
  /** column name */
  PublishedNoteId = 'published_note_id',
  /** column name */
  Time = 'time',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "notes" */
export type Notes_Set_Input = {
  channel_id?: Maybe<Scalars['String']>;
  chart_symbol?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['json']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_modified?: Maybe<Scalars['timestamptz']>;
  owner?: Maybe<Scalars['String']>;
  permission?: Maybe<Permissions_Enum>;
  published_note_id?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Note_Types_Enum>;
};

/** aggregate stddev on columns */
export type Notes_Stddev_Fields = {
  __typename?: 'notes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  published_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "notes" */
export type Notes_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  published_note_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Notes_Stddev_Pop_Fields = {
  __typename?: 'notes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  published_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "notes" */
export type Notes_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  published_note_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Notes_Stddev_Samp_Fields = {
  __typename?: 'notes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  published_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "notes" */
export type Notes_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  published_note_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Notes_Sum_Fields = {
  __typename?: 'notes_sum_fields';
  id?: Maybe<Scalars['Int']>;
  published_note_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "notes" */
export type Notes_Sum_Order_By = {
  id?: Maybe<Order_By>;
  published_note_id?: Maybe<Order_By>;
};

/** update columns of table "notes" */
export enum Notes_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChartSymbol = 'chart_symbol',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'last_modified',
  /** column name */
  Owner = 'owner',
  /** column name */
  Permission = 'permission',
  /** column name */
  PublishedNoteId = 'published_note_id',
  /** column name */
  Time = 'time',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** columns and relationships of "notes_users_notifications" */
export type Notes_Users_Notifications = {
  __typename?: 'notes_users_notifications';
  id: Scalars['Int'];
  muted?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  note: Notes;
  note_id: Scalars['Int'];
  read_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "notes_users_notifications" */
export type Notes_Users_Notifications_Aggregate = {
  __typename?: 'notes_users_notifications_aggregate';
  aggregate?: Maybe<Notes_Users_Notifications_Aggregate_Fields>;
  nodes: Array<Notes_Users_Notifications>;
};

/** aggregate fields of "notes_users_notifications" */
export type Notes_Users_Notifications_Aggregate_Fields = {
  __typename?: 'notes_users_notifications_aggregate_fields';
  avg?: Maybe<Notes_Users_Notifications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Notes_Users_Notifications_Max_Fields>;
  min?: Maybe<Notes_Users_Notifications_Min_Fields>;
  stddev?: Maybe<Notes_Users_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Notes_Users_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Notes_Users_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Notes_Users_Notifications_Sum_Fields>;
  var_pop?: Maybe<Notes_Users_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Notes_Users_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Notes_Users_Notifications_Variance_Fields>;
};


/** aggregate fields of "notes_users_notifications" */
export type Notes_Users_Notifications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notes_Users_Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Notes_Users_Notifications_Avg_Fields = {
  __typename?: 'notes_users_notifications_avg_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "notes_users_notifications". All fields are combined with a logical 'AND'. */
export type Notes_Users_Notifications_Bool_Exp = {
  _and?: Maybe<Array<Notes_Users_Notifications_Bool_Exp>>;
  _not?: Maybe<Notes_Users_Notifications_Bool_Exp>;
  _or?: Maybe<Array<Notes_Users_Notifications_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  muted?: Maybe<Boolean_Comparison_Exp>;
  note?: Maybe<Notes_Bool_Exp>;
  note_id?: Maybe<Int_Comparison_Exp>;
  read_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notes_users_notifications" */
export enum Notes_Users_Notifications_Constraint {
  /** unique or primary key constraint */
  NotesUsersNotificationsPkey = 'notes_users_notifications_pkey',
  /** unique or primary key constraint */
  NotesUsersNotificationsUserIdNoteIdKey = 'notes_users_notifications_user_id_note_id_key'
}

/** input type for incrementing numeric columns in table "notes_users_notifications" */
export type Notes_Users_Notifications_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  note_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "notes_users_notifications" */
export type Notes_Users_Notifications_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  muted?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Notes_Obj_Rel_Insert_Input>;
  note_id?: Maybe<Scalars['Int']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notes_Users_Notifications_Max_Fields = {
  __typename?: 'notes_users_notifications_max_fields';
  id?: Maybe<Scalars['Int']>;
  note_id?: Maybe<Scalars['Int']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notes_Users_Notifications_Min_Fields = {
  __typename?: 'notes_users_notifications_min_fields';
  id?: Maybe<Scalars['Int']>;
  note_id?: Maybe<Scalars['Int']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notes_users_notifications" */
export type Notes_Users_Notifications_Mutation_Response = {
  __typename?: 'notes_users_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notes_Users_Notifications>;
};

/** input type for inserting object relation for remote table "notes_users_notifications" */
export type Notes_Users_Notifications_Obj_Rel_Insert_Input = {
  data: Notes_Users_Notifications_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Notes_Users_Notifications_On_Conflict>;
};

/** on conflict condition type for table "notes_users_notifications" */
export type Notes_Users_Notifications_On_Conflict = {
  constraint: Notes_Users_Notifications_Constraint;
  update_columns: Array<Notes_Users_Notifications_Update_Column>;
  where?: Maybe<Notes_Users_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notes_users_notifications". */
export type Notes_Users_Notifications_Order_By = {
  id?: Maybe<Order_By>;
  muted?: Maybe<Order_By>;
  note?: Maybe<Notes_Order_By>;
  note_id?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: notes_users_notifications */
export type Notes_Users_Notifications_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "notes_users_notifications" */
export enum Notes_Users_Notifications_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Muted = 'muted',
  /** column name */
  NoteId = 'note_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "notes_users_notifications" */
export type Notes_Users_Notifications_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  muted?: Maybe<Scalars['Boolean']>;
  note_id?: Maybe<Scalars['Int']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Notes_Users_Notifications_Stddev_Fields = {
  __typename?: 'notes_users_notifications_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Notes_Users_Notifications_Stddev_Pop_Fields = {
  __typename?: 'notes_users_notifications_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Notes_Users_Notifications_Stddev_Samp_Fields = {
  __typename?: 'notes_users_notifications_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Notes_Users_Notifications_Sum_Fields = {
  __typename?: 'notes_users_notifications_sum_fields';
  id?: Maybe<Scalars['Int']>;
  note_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "notes_users_notifications" */
export enum Notes_Users_Notifications_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Muted = 'muted',
  /** column name */
  NoteId = 'note_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Notes_Users_Notifications_Var_Pop_Fields = {
  __typename?: 'notes_users_notifications_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Notes_Users_Notifications_Var_Samp_Fields = {
  __typename?: 'notes_users_notifications_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Notes_Users_Notifications_Variance_Fields = {
  __typename?: 'notes_users_notifications_variance_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type Notes_Var_Pop_Fields = {
  __typename?: 'notes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  published_note_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "notes" */
export type Notes_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  published_note_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Notes_Var_Samp_Fields = {
  __typename?: 'notes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  published_note_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "notes" */
export type Notes_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  published_note_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Notes_Variance_Fields = {
  __typename?: 'notes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  published_note_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "notes" */
export type Notes_Variance_Order_By = {
  id?: Maybe<Order_By>;
  published_note_id?: Maybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organizations" */
export type Organizations = {
  __typename?: 'organizations';
  id: Scalars['Int'];
  /** An array relationship */
  members: Array<Outline_Users>;
  /** An aggregate relationship */
  members_aggregate: Outline_Users_Aggregate;
  name: Scalars['String'];
};


/** columns and relationships of "organizations" */
export type OrganizationsMembersArgs = {
  distinct_on?: Maybe<Array<Outline_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outline_Users_Order_By>>;
  where?: Maybe<Outline_Users_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Outline_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outline_Users_Order_By>>;
  where?: Maybe<Outline_Users_Bool_Exp>;
};

/** aggregated selection of "organizations" */
export type Organizations_Aggregate = {
  __typename?: 'organizations_aggregate';
  aggregate?: Maybe<Organizations_Aggregate_Fields>;
  nodes: Array<Organizations>;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_Fields = {
  __typename?: 'organizations_aggregate_fields';
  avg?: Maybe<Organizations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organizations_Max_Fields>;
  min?: Maybe<Organizations_Min_Fields>;
  stddev?: Maybe<Organizations_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_Sum_Fields>;
  var_pop?: Maybe<Organizations_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_Var_Samp_Fields>;
  variance?: Maybe<Organizations_Variance_Fields>;
};


/** aggregate fields of "organizations" */
export type Organizations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Organizations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Organizations_Avg_Fields = {
  __typename?: 'organizations_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: Maybe<Array<Organizations_Bool_Exp>>;
  _not?: Maybe<Organizations_Bool_Exp>;
  _or?: Maybe<Array<Organizations_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  members?: Maybe<Outline_Users_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations" */
export enum Organizations_Constraint {
  /** unique or primary key constraint */
  PkOrganizations = 'pk_organizations'
}

/** input type for incrementing numeric columns in table "organizations" */
export type Organizations_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "organizations" */
export type Organizations_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  members?: Maybe<Outline_Users_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Organizations_Max_Fields = {
  __typename?: 'organizations_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Organizations_Min_Fields = {
  __typename?: 'organizations_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organizations" */
export type Organizations_Mutation_Response = {
  __typename?: 'organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type Organizations_Obj_Rel_Insert_Input = {
  data: Organizations_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Organizations_On_Conflict>;
};

/** on conflict condition type for table "organizations" */
export type Organizations_On_Conflict = {
  constraint: Organizations_Constraint;
  update_columns: Array<Organizations_Update_Column>;
  where?: Maybe<Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations". */
export type Organizations_Order_By = {
  id?: Maybe<Order_By>;
  members_aggregate?: Maybe<Outline_Users_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: organizations */
export type Organizations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "organizations" */
export type Organizations_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Organizations_Stddev_Fields = {
  __typename?: 'organizations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Organizations_Stddev_Pop_Fields = {
  __typename?: 'organizations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Organizations_Stddev_Samp_Fields = {
  __typename?: 'organizations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Organizations_Sum_Fields = {
  __typename?: 'organizations_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "organizations" */
export enum Organizations_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Organizations_Var_Pop_Fields = {
  __typename?: 'organizations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Organizations_Var_Samp_Fields = {
  __typename?: 'organizations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Organizations_Variance_Fields = {
  __typename?: 'organizations_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "outline_users" */
export type Outline_Users = {
  __typename?: 'outline_users';
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['Int'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['Int'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "outline_users" */
export type Outline_Users_Aggregate = {
  __typename?: 'outline_users_aggregate';
  aggregate?: Maybe<Outline_Users_Aggregate_Fields>;
  nodes: Array<Outline_Users>;
};

/** aggregate fields of "outline_users" */
export type Outline_Users_Aggregate_Fields = {
  __typename?: 'outline_users_aggregate_fields';
  avg?: Maybe<Outline_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outline_Users_Max_Fields>;
  min?: Maybe<Outline_Users_Min_Fields>;
  stddev?: Maybe<Outline_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Outline_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outline_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Outline_Users_Sum_Fields>;
  var_pop?: Maybe<Outline_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Outline_Users_Var_Samp_Fields>;
  variance?: Maybe<Outline_Users_Variance_Fields>;
};


/** aggregate fields of "outline_users" */
export type Outline_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outline_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outline_users" */
export type Outline_Users_Aggregate_Order_By = {
  avg?: Maybe<Outline_Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Outline_Users_Max_Order_By>;
  min?: Maybe<Outline_Users_Min_Order_By>;
  stddev?: Maybe<Outline_Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Outline_Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Outline_Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Outline_Users_Sum_Order_By>;
  var_pop?: Maybe<Outline_Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Outline_Users_Var_Samp_Order_By>;
  variance?: Maybe<Outline_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outline_users" */
export type Outline_Users_Arr_Rel_Insert_Input = {
  data: Array<Outline_Users_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Outline_Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Outline_Users_Avg_Fields = {
  __typename?: 'outline_users_avg_fields';
  organization_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "outline_users" */
export type Outline_Users_Avg_Order_By = {
  organization_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outline_users". All fields are combined with a logical 'AND'. */
export type Outline_Users_Bool_Exp = {
  _and?: Maybe<Array<Outline_Users_Bool_Exp>>;
  _not?: Maybe<Outline_Users_Bool_Exp>;
  _or?: Maybe<Array<Outline_Users_Bool_Exp>>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  team?: Maybe<Teams_Bool_Exp>;
  team_id?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "outline_users" */
export enum Outline_Users_Constraint {
  /** unique or primary key constraint */
  PkOutlineUsers = 'pk_outline_users'
}

/** input type for incrementing numeric columns in table "outline_users" */
export type Outline_Users_Inc_Input = {
  organization_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "outline_users" */
export type Outline_Users_Insert_Input = {
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['Int']>;
  team?: Maybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: Maybe<Scalars['Int']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Outline_Users_Max_Fields = {
  __typename?: 'outline_users_max_fields';
  organization_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "outline_users" */
export type Outline_Users_Max_Order_By = {
  organization_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Outline_Users_Min_Fields = {
  __typename?: 'outline_users_min_fields';
  organization_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "outline_users" */
export type Outline_Users_Min_Order_By = {
  organization_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "outline_users" */
export type Outline_Users_Mutation_Response = {
  __typename?: 'outline_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outline_Users>;
};

/** input type for inserting object relation for remote table "outline_users" */
export type Outline_Users_Obj_Rel_Insert_Input = {
  data: Outline_Users_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Outline_Users_On_Conflict>;
};

/** on conflict condition type for table "outline_users" */
export type Outline_Users_On_Conflict = {
  constraint: Outline_Users_Constraint;
  update_columns: Array<Outline_Users_Update_Column>;
  where?: Maybe<Outline_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "outline_users". */
export type Outline_Users_Order_By = {
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  team?: Maybe<Teams_Order_By>;
  team_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: outline_users */
export type Outline_Users_Pk_Columns_Input = {
  user_id: Scalars['String'];
};

/** select columns of table "outline_users" */
export enum Outline_Users_Select_Column {
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "outline_users" */
export type Outline_Users_Set_Input = {
  organization_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Outline_Users_Stddev_Fields = {
  __typename?: 'outline_users_stddev_fields';
  organization_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "outline_users" */
export type Outline_Users_Stddev_Order_By = {
  organization_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outline_Users_Stddev_Pop_Fields = {
  __typename?: 'outline_users_stddev_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "outline_users" */
export type Outline_Users_Stddev_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outline_Users_Stddev_Samp_Fields = {
  __typename?: 'outline_users_stddev_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "outline_users" */
export type Outline_Users_Stddev_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Outline_Users_Sum_Fields = {
  __typename?: 'outline_users_sum_fields';
  organization_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "outline_users" */
export type Outline_Users_Sum_Order_By = {
  organization_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** update columns of table "outline_users" */
export enum Outline_Users_Update_Column {
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Outline_Users_Var_Pop_Fields = {
  __typename?: 'outline_users_var_pop_fields';
  organization_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "outline_users" */
export type Outline_Users_Var_Pop_Order_By = {
  organization_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outline_Users_Var_Samp_Fields = {
  __typename?: 'outline_users_var_samp_fields';
  organization_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "outline_users" */
export type Outline_Users_Var_Samp_Order_By = {
  organization_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Outline_Users_Variance_Fields = {
  __typename?: 'outline_users_variance_fields';
  organization_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "outline_users" */
export type Outline_Users_Variance_Order_By = {
  organization_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** columns and relationships of "permissions" */
export type Permissions = {
  __typename?: 'permissions';
  value: Scalars['String'];
};

/** aggregated selection of "permissions" */
export type Permissions_Aggregate = {
  __typename?: 'permissions_aggregate';
  aggregate?: Maybe<Permissions_Aggregate_Fields>;
  nodes: Array<Permissions>;
};

/** aggregate fields of "permissions" */
export type Permissions_Aggregate_Fields = {
  __typename?: 'permissions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Permissions_Max_Fields>;
  min?: Maybe<Permissions_Min_Fields>;
};


/** aggregate fields of "permissions" */
export type Permissions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Permissions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "permissions". All fields are combined with a logical 'AND'. */
export type Permissions_Bool_Exp = {
  _and?: Maybe<Array<Permissions_Bool_Exp>>;
  _not?: Maybe<Permissions_Bool_Exp>;
  _or?: Maybe<Array<Permissions_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "permissions" */
export enum Permissions_Constraint {
  /** unique or primary key constraint */
  PermissionsPkey = 'permissions_pkey'
}

export enum Permissions_Enum {
  OrgVisible = 'org_visible',
  Private = 'private',
  Public = 'public',
  TeamEdit = 'team_edit',
  TeamVisible = 'team_visible'
}

/** Boolean expression to compare columns of type "permissions_enum". All fields are combined with logical 'AND'. */
export type Permissions_Enum_Comparison_Exp = {
  _eq?: Maybe<Permissions_Enum>;
  _in?: Maybe<Array<Permissions_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Permissions_Enum>;
  _nin?: Maybe<Array<Permissions_Enum>>;
};

/** input type for inserting data into table "permissions" */
export type Permissions_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Permissions_Max_Fields = {
  __typename?: 'permissions_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Permissions_Min_Fields = {
  __typename?: 'permissions_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "permissions" */
export type Permissions_Mutation_Response = {
  __typename?: 'permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Permissions>;
};

/** on conflict condition type for table "permissions" */
export type Permissions_On_Conflict = {
  constraint: Permissions_Constraint;
  update_columns: Array<Permissions_Update_Column>;
  where?: Maybe<Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "permissions". */
export type Permissions_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: permissions */
export type Permissions_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "permissions" */
export enum Permissions_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "permissions" */
export type Permissions_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "permissions" */
export enum Permissions_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "public_notes" */
export type Public_Notes = {
  __typename?: 'public_notes';
  chart_symbol?: Maybe<Scalars['String']>;
  /** An array relationship */
  client_access: Array<Public_Notes_Client_Access>;
  /** An aggregate relationship */
  client_access_aggregate: Public_Notes_Client_Access_Aggregate;
  content: Scalars['json'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  last_modified: Scalars['timestamptz'];
  /** An array relationship */
  message_threads: Array<Message_Threads>;
  /** An aggregate relationship */
  message_threads_aggregate: Message_Threads_Aggregate;
  /** An object relationship */
  note: Notes;
  note_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  notes_users_notifications?: Maybe<Notes_Users_Notifications>;
  owner: Scalars['String'];
  /** An array relationship */
  related_notes: Array<Public_Notes>;
  /** An aggregate relationship */
  related_notes_aggregate: Public_Notes_Aggregate;
  /** An array relationship */
  team_accesses: Array<Public_Notes_Team_Access>;
  /** An aggregate relationship */
  team_accesses_aggregate: Public_Notes_Team_Access_Aggregate;
  time?: Maybe<Scalars['timestamptz']>;
  title: Scalars['String'];
  type: Note_Types_Enum;
  /** An object relationship */
  user: Users;
};


/** columns and relationships of "public_notes" */
export type Public_NotesClient_AccessArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Client_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Client_Access_Order_By>>;
  where?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
};


/** columns and relationships of "public_notes" */
export type Public_NotesClient_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Client_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Client_Access_Order_By>>;
  where?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
};


/** columns and relationships of "public_notes" */
export type Public_NotesContentArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "public_notes" */
export type Public_NotesMessage_ThreadsArgs = {
  distinct_on?: Maybe<Array<Message_Threads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Threads_Order_By>>;
  where?: Maybe<Message_Threads_Bool_Exp>;
};


/** columns and relationships of "public_notes" */
export type Public_NotesMessage_Threads_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Threads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Threads_Order_By>>;
  where?: Maybe<Message_Threads_Bool_Exp>;
};


/** columns and relationships of "public_notes" */
export type Public_NotesRelated_NotesArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Order_By>>;
  where?: Maybe<Public_Notes_Bool_Exp>;
};


/** columns and relationships of "public_notes" */
export type Public_NotesRelated_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Order_By>>;
  where?: Maybe<Public_Notes_Bool_Exp>;
};


/** columns and relationships of "public_notes" */
export type Public_NotesTeam_AccessesArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Team_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Team_Access_Order_By>>;
  where?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
};


/** columns and relationships of "public_notes" */
export type Public_NotesTeam_Accesses_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Team_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Team_Access_Order_By>>;
  where?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
};

/** aggregated selection of "public_notes" */
export type Public_Notes_Aggregate = {
  __typename?: 'public_notes_aggregate';
  aggregate?: Maybe<Public_Notes_Aggregate_Fields>;
  nodes: Array<Public_Notes>;
};

/** aggregate fields of "public_notes" */
export type Public_Notes_Aggregate_Fields = {
  __typename?: 'public_notes_aggregate_fields';
  avg?: Maybe<Public_Notes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Public_Notes_Max_Fields>;
  min?: Maybe<Public_Notes_Min_Fields>;
  stddev?: Maybe<Public_Notes_Stddev_Fields>;
  stddev_pop?: Maybe<Public_Notes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Public_Notes_Stddev_Samp_Fields>;
  sum?: Maybe<Public_Notes_Sum_Fields>;
  var_pop?: Maybe<Public_Notes_Var_Pop_Fields>;
  var_samp?: Maybe<Public_Notes_Var_Samp_Fields>;
  variance?: Maybe<Public_Notes_Variance_Fields>;
};


/** aggregate fields of "public_notes" */
export type Public_Notes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Public_Notes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "public_notes" */
export type Public_Notes_Aggregate_Order_By = {
  avg?: Maybe<Public_Notes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Public_Notes_Max_Order_By>;
  min?: Maybe<Public_Notes_Min_Order_By>;
  stddev?: Maybe<Public_Notes_Stddev_Order_By>;
  stddev_pop?: Maybe<Public_Notes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Public_Notes_Stddev_Samp_Order_By>;
  sum?: Maybe<Public_Notes_Sum_Order_By>;
  var_pop?: Maybe<Public_Notes_Var_Pop_Order_By>;
  var_samp?: Maybe<Public_Notes_Var_Samp_Order_By>;
  variance?: Maybe<Public_Notes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "public_notes" */
export type Public_Notes_Arr_Rel_Insert_Input = {
  data: Array<Public_Notes_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Public_Notes_On_Conflict>;
};

/** aggregate avg on columns */
export type Public_Notes_Avg_Fields = {
  __typename?: 'public_notes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "public_notes" */
export type Public_Notes_Avg_Order_By = {
  id?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "public_notes". All fields are combined with a logical 'AND'. */
export type Public_Notes_Bool_Exp = {
  _and?: Maybe<Array<Public_Notes_Bool_Exp>>;
  _not?: Maybe<Public_Notes_Bool_Exp>;
  _or?: Maybe<Array<Public_Notes_Bool_Exp>>;
  chart_symbol?: Maybe<String_Comparison_Exp>;
  client_access?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
  content?: Maybe<Json_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_modified?: Maybe<Timestamptz_Comparison_Exp>;
  message_threads?: Maybe<Message_Threads_Bool_Exp>;
  note?: Maybe<Notes_Bool_Exp>;
  note_id?: Maybe<Int_Comparison_Exp>;
  notes_users_notifications?: Maybe<Notes_Users_Notifications_Bool_Exp>;
  owner?: Maybe<String_Comparison_Exp>;
  related_notes?: Maybe<Public_Notes_Bool_Exp>;
  team_accesses?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
  time?: Maybe<Timestamptz_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Note_Types_Enum_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** columns and relationships of "public_notes_client_access" */
export type Public_Notes_Client_Access = {
  __typename?: 'public_notes_client_access';
  /** An object relationship */
  client: Clients;
  client_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  public_note: Public_Notes;
  public_note_id: Scalars['Int'];
};

/** aggregated selection of "public_notes_client_access" */
export type Public_Notes_Client_Access_Aggregate = {
  __typename?: 'public_notes_client_access_aggregate';
  aggregate?: Maybe<Public_Notes_Client_Access_Aggregate_Fields>;
  nodes: Array<Public_Notes_Client_Access>;
};

/** aggregate fields of "public_notes_client_access" */
export type Public_Notes_Client_Access_Aggregate_Fields = {
  __typename?: 'public_notes_client_access_aggregate_fields';
  avg?: Maybe<Public_Notes_Client_Access_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Public_Notes_Client_Access_Max_Fields>;
  min?: Maybe<Public_Notes_Client_Access_Min_Fields>;
  stddev?: Maybe<Public_Notes_Client_Access_Stddev_Fields>;
  stddev_pop?: Maybe<Public_Notes_Client_Access_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Public_Notes_Client_Access_Stddev_Samp_Fields>;
  sum?: Maybe<Public_Notes_Client_Access_Sum_Fields>;
  var_pop?: Maybe<Public_Notes_Client_Access_Var_Pop_Fields>;
  var_samp?: Maybe<Public_Notes_Client_Access_Var_Samp_Fields>;
  variance?: Maybe<Public_Notes_Client_Access_Variance_Fields>;
};


/** aggregate fields of "public_notes_client_access" */
export type Public_Notes_Client_Access_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Public_Notes_Client_Access_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Aggregate_Order_By = {
  avg?: Maybe<Public_Notes_Client_Access_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Public_Notes_Client_Access_Max_Order_By>;
  min?: Maybe<Public_Notes_Client_Access_Min_Order_By>;
  stddev?: Maybe<Public_Notes_Client_Access_Stddev_Order_By>;
  stddev_pop?: Maybe<Public_Notes_Client_Access_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Public_Notes_Client_Access_Stddev_Samp_Order_By>;
  sum?: Maybe<Public_Notes_Client_Access_Sum_Order_By>;
  var_pop?: Maybe<Public_Notes_Client_Access_Var_Pop_Order_By>;
  var_samp?: Maybe<Public_Notes_Client_Access_Var_Samp_Order_By>;
  variance?: Maybe<Public_Notes_Client_Access_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "public_notes_client_access" */
export type Public_Notes_Client_Access_Arr_Rel_Insert_Input = {
  data: Array<Public_Notes_Client_Access_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Public_Notes_Client_Access_On_Conflict>;
};

/** aggregate avg on columns */
export type Public_Notes_Client_Access_Avg_Fields = {
  __typename?: 'public_notes_client_access_avg_fields';
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Avg_Order_By = {
  public_note_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "public_notes_client_access". All fields are combined with a logical 'AND'. */
export type Public_Notes_Client_Access_Bool_Exp = {
  _and?: Maybe<Array<Public_Notes_Client_Access_Bool_Exp>>;
  _not?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
  _or?: Maybe<Array<Public_Notes_Client_Access_Bool_Exp>>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  public_note?: Maybe<Public_Notes_Bool_Exp>;
  public_note_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "public_notes_client_access" */
export enum Public_Notes_Client_Access_Constraint {
  /** unique or primary key constraint */
  PublicNotesClientAccessPkey = 'public_notes_client_access_pkey'
}

/** input type for incrementing numeric columns in table "public_notes_client_access" */
export type Public_Notes_Client_Access_Inc_Input = {
  public_note_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "public_notes_client_access" */
export type Public_Notes_Client_Access_Insert_Input = {
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  public_note?: Maybe<Public_Notes_Obj_Rel_Insert_Input>;
  public_note_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Public_Notes_Client_Access_Max_Fields = {
  __typename?: 'public_notes_client_access_max_fields';
  client_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  public_note_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Public_Notes_Client_Access_Min_Fields = {
  __typename?: 'public_notes_client_access_min_fields';
  client_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  public_note_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "public_notes_client_access" */
export type Public_Notes_Client_Access_Mutation_Response = {
  __typename?: 'public_notes_client_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Public_Notes_Client_Access>;
};

/** on conflict condition type for table "public_notes_client_access" */
export type Public_Notes_Client_Access_On_Conflict = {
  constraint: Public_Notes_Client_Access_Constraint;
  update_columns: Array<Public_Notes_Client_Access_Update_Column>;
  where?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "public_notes_client_access". */
export type Public_Notes_Client_Access_Order_By = {
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  public_note?: Maybe<Public_Notes_Order_By>;
  public_note_id?: Maybe<Order_By>;
};

/** primary key columns input for table: public_notes_client_access */
export type Public_Notes_Client_Access_Pk_Columns_Input = {
  client_id: Scalars['String'];
  public_note_id: Scalars['Int'];
};

/** select columns of table "public_notes_client_access" */
export enum Public_Notes_Client_Access_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PublicNoteId = 'public_note_id'
}

/** input type for updating data in table "public_notes_client_access" */
export type Public_Notes_Client_Access_Set_Input = {
  client_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  public_note_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Public_Notes_Client_Access_Stddev_Fields = {
  __typename?: 'public_notes_client_access_stddev_fields';
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Stddev_Order_By = {
  public_note_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Public_Notes_Client_Access_Stddev_Pop_Fields = {
  __typename?: 'public_notes_client_access_stddev_pop_fields';
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Stddev_Pop_Order_By = {
  public_note_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Public_Notes_Client_Access_Stddev_Samp_Fields = {
  __typename?: 'public_notes_client_access_stddev_samp_fields';
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Stddev_Samp_Order_By = {
  public_note_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Public_Notes_Client_Access_Sum_Fields = {
  __typename?: 'public_notes_client_access_sum_fields';
  public_note_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Sum_Order_By = {
  public_note_id?: Maybe<Order_By>;
};

/** update columns of table "public_notes_client_access" */
export enum Public_Notes_Client_Access_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PublicNoteId = 'public_note_id'
}

/** aggregate var_pop on columns */
export type Public_Notes_Client_Access_Var_Pop_Fields = {
  __typename?: 'public_notes_client_access_var_pop_fields';
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Var_Pop_Order_By = {
  public_note_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Public_Notes_Client_Access_Var_Samp_Fields = {
  __typename?: 'public_notes_client_access_var_samp_fields';
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Var_Samp_Order_By = {
  public_note_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Public_Notes_Client_Access_Variance_Fields = {
  __typename?: 'public_notes_client_access_variance_fields';
  public_note_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "public_notes_client_access" */
export type Public_Notes_Client_Access_Variance_Order_By = {
  public_note_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "public_notes" */
export enum Public_Notes_Constraint {
  /** unique or primary key constraint */
  PublicNotesPkey = 'public_notes_pkey'
}

/** input type for incrementing numeric columns in table "public_notes" */
export type Public_Notes_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  note_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "public_notes" */
export type Public_Notes_Insert_Input = {
  chart_symbol?: Maybe<Scalars['String']>;
  client_access?: Maybe<Public_Notes_Client_Access_Arr_Rel_Insert_Input>;
  content?: Maybe<Scalars['json']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_modified?: Maybe<Scalars['timestamptz']>;
  message_threads?: Maybe<Message_Threads_Arr_Rel_Insert_Input>;
  note?: Maybe<Notes_Obj_Rel_Insert_Input>;
  note_id?: Maybe<Scalars['Int']>;
  notes_users_notifications?: Maybe<Notes_Users_Notifications_Obj_Rel_Insert_Input>;
  owner?: Maybe<Scalars['String']>;
  related_notes?: Maybe<Public_Notes_Arr_Rel_Insert_Input>;
  team_accesses?: Maybe<Public_Notes_Team_Access_Arr_Rel_Insert_Input>;
  time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Note_Types_Enum>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Public_Notes_Max_Fields = {
  __typename?: 'public_notes_max_fields';
  chart_symbol?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_modified?: Maybe<Scalars['timestamptz']>;
  note_id?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "public_notes" */
export type Public_Notes_Max_Order_By = {
  chart_symbol?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_modified?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
  owner?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Public_Notes_Min_Fields = {
  __typename?: 'public_notes_min_fields';
  chart_symbol?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_modified?: Maybe<Scalars['timestamptz']>;
  note_id?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "public_notes" */
export type Public_Notes_Min_Order_By = {
  chart_symbol?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_modified?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
  owner?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "public_notes" */
export type Public_Notes_Mutation_Response = {
  __typename?: 'public_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Public_Notes>;
};

/** input type for inserting object relation for remote table "public_notes" */
export type Public_Notes_Obj_Rel_Insert_Input = {
  data: Public_Notes_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Public_Notes_On_Conflict>;
};

/** on conflict condition type for table "public_notes" */
export type Public_Notes_On_Conflict = {
  constraint: Public_Notes_Constraint;
  update_columns: Array<Public_Notes_Update_Column>;
  where?: Maybe<Public_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "public_notes". */
export type Public_Notes_Order_By = {
  chart_symbol?: Maybe<Order_By>;
  client_access_aggregate?: Maybe<Public_Notes_Client_Access_Aggregate_Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_modified?: Maybe<Order_By>;
  message_threads_aggregate?: Maybe<Message_Threads_Aggregate_Order_By>;
  note?: Maybe<Notes_Order_By>;
  note_id?: Maybe<Order_By>;
  notes_users_notifications?: Maybe<Notes_Users_Notifications_Order_By>;
  owner?: Maybe<Order_By>;
  related_notes_aggregate?: Maybe<Public_Notes_Aggregate_Order_By>;
  team_accesses_aggregate?: Maybe<Public_Notes_Team_Access_Aggregate_Order_By>;
  time?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: public_notes */
export type Public_Notes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "public_notes" */
export enum Public_Notes_Select_Column {
  /** column name */
  ChartSymbol = 'chart_symbol',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'last_modified',
  /** column name */
  NoteId = 'note_id',
  /** column name */
  Owner = 'owner',
  /** column name */
  Time = 'time',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "public_notes" */
export type Public_Notes_Set_Input = {
  chart_symbol?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['json']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_modified?: Maybe<Scalars['timestamptz']>;
  note_id?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Note_Types_Enum>;
};

/** aggregate stddev on columns */
export type Public_Notes_Stddev_Fields = {
  __typename?: 'public_notes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "public_notes" */
export type Public_Notes_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Public_Notes_Stddev_Pop_Fields = {
  __typename?: 'public_notes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "public_notes" */
export type Public_Notes_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Public_Notes_Stddev_Samp_Fields = {
  __typename?: 'public_notes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "public_notes" */
export type Public_Notes_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Public_Notes_Sum_Fields = {
  __typename?: 'public_notes_sum_fields';
  id?: Maybe<Scalars['Int']>;
  note_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "public_notes" */
export type Public_Notes_Sum_Order_By = {
  id?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
};

/** columns and relationships of "public_notes_team_access" */
export type Public_Notes_Team_Access = {
  __typename?: 'public_notes_team_access';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  public_note: Public_Notes;
  public_note_id: Scalars['Int'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['Int'];
};

/** aggregated selection of "public_notes_team_access" */
export type Public_Notes_Team_Access_Aggregate = {
  __typename?: 'public_notes_team_access_aggregate';
  aggregate?: Maybe<Public_Notes_Team_Access_Aggregate_Fields>;
  nodes: Array<Public_Notes_Team_Access>;
};

/** aggregate fields of "public_notes_team_access" */
export type Public_Notes_Team_Access_Aggregate_Fields = {
  __typename?: 'public_notes_team_access_aggregate_fields';
  avg?: Maybe<Public_Notes_Team_Access_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Public_Notes_Team_Access_Max_Fields>;
  min?: Maybe<Public_Notes_Team_Access_Min_Fields>;
  stddev?: Maybe<Public_Notes_Team_Access_Stddev_Fields>;
  stddev_pop?: Maybe<Public_Notes_Team_Access_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Public_Notes_Team_Access_Stddev_Samp_Fields>;
  sum?: Maybe<Public_Notes_Team_Access_Sum_Fields>;
  var_pop?: Maybe<Public_Notes_Team_Access_Var_Pop_Fields>;
  var_samp?: Maybe<Public_Notes_Team_Access_Var_Samp_Fields>;
  variance?: Maybe<Public_Notes_Team_Access_Variance_Fields>;
};


/** aggregate fields of "public_notes_team_access" */
export type Public_Notes_Team_Access_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Public_Notes_Team_Access_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Aggregate_Order_By = {
  avg?: Maybe<Public_Notes_Team_Access_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Public_Notes_Team_Access_Max_Order_By>;
  min?: Maybe<Public_Notes_Team_Access_Min_Order_By>;
  stddev?: Maybe<Public_Notes_Team_Access_Stddev_Order_By>;
  stddev_pop?: Maybe<Public_Notes_Team_Access_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Public_Notes_Team_Access_Stddev_Samp_Order_By>;
  sum?: Maybe<Public_Notes_Team_Access_Sum_Order_By>;
  var_pop?: Maybe<Public_Notes_Team_Access_Var_Pop_Order_By>;
  var_samp?: Maybe<Public_Notes_Team_Access_Var_Samp_Order_By>;
  variance?: Maybe<Public_Notes_Team_Access_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "public_notes_team_access" */
export type Public_Notes_Team_Access_Arr_Rel_Insert_Input = {
  data: Array<Public_Notes_Team_Access_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Public_Notes_Team_Access_On_Conflict>;
};

/** aggregate avg on columns */
export type Public_Notes_Team_Access_Avg_Fields = {
  __typename?: 'public_notes_team_access_avg_fields';
  public_note_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Avg_Order_By = {
  public_note_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "public_notes_team_access". All fields are combined with a logical 'AND'. */
export type Public_Notes_Team_Access_Bool_Exp = {
  _and?: Maybe<Array<Public_Notes_Team_Access_Bool_Exp>>;
  _not?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
  _or?: Maybe<Array<Public_Notes_Team_Access_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  public_note?: Maybe<Public_Notes_Bool_Exp>;
  public_note_id?: Maybe<Int_Comparison_Exp>;
  team?: Maybe<Teams_Bool_Exp>;
  team_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "public_notes_team_access" */
export enum Public_Notes_Team_Access_Constraint {
  /** unique or primary key constraint */
  PublicNotesTeamAccessPkey = 'public_notes_team_access_pkey'
}

/** input type for incrementing numeric columns in table "public_notes_team_access" */
export type Public_Notes_Team_Access_Inc_Input = {
  public_note_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "public_notes_team_access" */
export type Public_Notes_Team_Access_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  public_note?: Maybe<Public_Notes_Obj_Rel_Insert_Input>;
  public_note_id?: Maybe<Scalars['Int']>;
  team?: Maybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Public_Notes_Team_Access_Max_Fields = {
  __typename?: 'public_notes_team_access_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  public_note_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Public_Notes_Team_Access_Min_Fields = {
  __typename?: 'public_notes_team_access_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  public_note_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  public_note_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "public_notes_team_access" */
export type Public_Notes_Team_Access_Mutation_Response = {
  __typename?: 'public_notes_team_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Public_Notes_Team_Access>;
};

/** on conflict condition type for table "public_notes_team_access" */
export type Public_Notes_Team_Access_On_Conflict = {
  constraint: Public_Notes_Team_Access_Constraint;
  update_columns: Array<Public_Notes_Team_Access_Update_Column>;
  where?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "public_notes_team_access". */
export type Public_Notes_Team_Access_Order_By = {
  created_at?: Maybe<Order_By>;
  public_note?: Maybe<Public_Notes_Order_By>;
  public_note_id?: Maybe<Order_By>;
  team?: Maybe<Teams_Order_By>;
  team_id?: Maybe<Order_By>;
};

/** primary key columns input for table: public_notes_team_access */
export type Public_Notes_Team_Access_Pk_Columns_Input = {
  public_note_id: Scalars['Int'];
  team_id: Scalars['Int'];
};

/** select columns of table "public_notes_team_access" */
export enum Public_Notes_Team_Access_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PublicNoteId = 'public_note_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "public_notes_team_access" */
export type Public_Notes_Team_Access_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  public_note_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Public_Notes_Team_Access_Stddev_Fields = {
  __typename?: 'public_notes_team_access_stddev_fields';
  public_note_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Stddev_Order_By = {
  public_note_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Public_Notes_Team_Access_Stddev_Pop_Fields = {
  __typename?: 'public_notes_team_access_stddev_pop_fields';
  public_note_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Stddev_Pop_Order_By = {
  public_note_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Public_Notes_Team_Access_Stddev_Samp_Fields = {
  __typename?: 'public_notes_team_access_stddev_samp_fields';
  public_note_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Stddev_Samp_Order_By = {
  public_note_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Public_Notes_Team_Access_Sum_Fields = {
  __typename?: 'public_notes_team_access_sum_fields';
  public_note_id?: Maybe<Scalars['Int']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Sum_Order_By = {
  public_note_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** update columns of table "public_notes_team_access" */
export enum Public_Notes_Team_Access_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PublicNoteId = 'public_note_id',
  /** column name */
  TeamId = 'team_id'
}

/** aggregate var_pop on columns */
export type Public_Notes_Team_Access_Var_Pop_Fields = {
  __typename?: 'public_notes_team_access_var_pop_fields';
  public_note_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Var_Pop_Order_By = {
  public_note_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Public_Notes_Team_Access_Var_Samp_Fields = {
  __typename?: 'public_notes_team_access_var_samp_fields';
  public_note_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Var_Samp_Order_By = {
  public_note_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Public_Notes_Team_Access_Variance_Fields = {
  __typename?: 'public_notes_team_access_variance_fields';
  public_note_id?: Maybe<Scalars['Float']>;
  team_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "public_notes_team_access" */
export type Public_Notes_Team_Access_Variance_Order_By = {
  public_note_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** update columns of table "public_notes" */
export enum Public_Notes_Update_Column {
  /** column name */
  ChartSymbol = 'chart_symbol',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'last_modified',
  /** column name */
  NoteId = 'note_id',
  /** column name */
  Owner = 'owner',
  /** column name */
  Time = 'time',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Public_Notes_Var_Pop_Fields = {
  __typename?: 'public_notes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "public_notes" */
export type Public_Notes_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Public_Notes_Var_Samp_Fields = {
  __typename?: 'public_notes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "public_notes" */
export type Public_Notes_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Public_Notes_Variance_Fields = {
  __typename?: 'public_notes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  note_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "public_notes" */
export type Public_Notes_Variance_Order_By = {
  id?: Maybe<Order_By>;
  note_id?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "chart_indicators" */
  chart_indicators: Array<Chart_Indicators>;
  /** fetch aggregated fields from the table: "chart_indicators" */
  chart_indicators_aggregate: Chart_Indicators_Aggregate;
  /** fetch data from the table: "chart_indicators" using primary key columns */
  chart_indicators_by_pk?: Maybe<Chart_Indicators>;
  /** fetch data from the table: "chart_periods" */
  chart_periods: Array<Chart_Periods>;
  /** fetch aggregated fields from the table: "chart_periods" */
  chart_periods_aggregate: Chart_Periods_Aggregate;
  /** fetch data from the table: "chart_periods" using primary key columns */
  chart_periods_by_pk?: Maybe<Chart_Periods>;
  /** fetch data from the table: "chart_styles" */
  chart_styles: Array<Chart_Styles>;
  /** fetch aggregated fields from the table: "chart_styles" */
  chart_styles_aggregate: Chart_Styles_Aggregate;
  /** fetch data from the table: "chart_styles" using primary key columns */
  chart_styles_by_pk?: Maybe<Chart_Styles>;
  /** fetch data from the table: "charts" */
  charts: Array<Charts>;
  /** fetch aggregated fields from the table: "charts" */
  charts_aggregate: Charts_Aggregate;
  /** fetch data from the table: "charts" using primary key columns */
  charts_by_pk?: Maybe<Charts>;
  /** fetch data from the table: "client_charts" */
  client_charts: Array<Client_Charts>;
  /** fetch aggregated fields from the table: "client_charts" */
  client_charts_aggregate: Client_Charts_Aggregate;
  /** fetch data from the table: "client_charts" using primary key columns */
  client_charts_by_pk?: Maybe<Client_Charts>;
  /** fetch data from the table: "client_companies" */
  client_companies: Array<Client_Companies>;
  /** fetch aggregated fields from the table: "client_companies" */
  client_companies_aggregate: Client_Companies_Aggregate;
  /** fetch data from the table: "client_companies" using primary key columns */
  client_companies_by_pk?: Maybe<Client_Companies>;
  /** fetch data from the table: "clients" */
  clients: Array<Clients>;
  /** fetch aggregated fields from the table: "clients" */
  clients_aggregate: Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  clients_by_pk?: Maybe<Clients>;
  getAlgoliaApiKey?: Maybe<AlgoliaApiKey>;
  getFinancialData?: Maybe<FinancialData>;
  getSignedUrl?: Maybe<SignedUrl>;
  /** fetch data from the table: "layouts" */
  layouts: Array<Layouts>;
  /** fetch aggregated fields from the table: "layouts" */
  layouts_aggregate: Layouts_Aggregate;
  /** fetch data from the table: "layouts" using primary key columns */
  layouts_by_pk?: Maybe<Layouts>;
  /** fetch data from the table: "message_threads" */
  message_threads: Array<Message_Threads>;
  /** fetch aggregated fields from the table: "message_threads" */
  message_threads_aggregate: Message_Threads_Aggregate;
  /** fetch data from the table: "message_threads" using primary key columns */
  message_threads_by_pk?: Maybe<Message_Threads>;
  /** fetch data from the table: "note_types" */
  note_types: Array<Note_Types>;
  /** fetch aggregated fields from the table: "note_types" */
  note_types_aggregate: Note_Types_Aggregate;
  /** fetch data from the table: "note_types" using primary key columns */
  note_types_by_pk?: Maybe<Note_Types>;
  /** fetch data from the table: "notes" */
  notes: Array<Notes>;
  /** fetch aggregated fields from the table: "notes" */
  notes_aggregate: Notes_Aggregate;
  /** fetch data from the table: "notes" using primary key columns */
  notes_by_pk?: Maybe<Notes>;
  /** fetch data from the table: "notes_users_notifications" */
  notes_users_notifications: Array<Notes_Users_Notifications>;
  /** fetch aggregated fields from the table: "notes_users_notifications" */
  notes_users_notifications_aggregate: Notes_Users_Notifications_Aggregate;
  /** fetch data from the table: "notes_users_notifications" using primary key columns */
  notes_users_notifications_by_pk?: Maybe<Notes_Users_Notifications>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "outline_users" */
  outline_users: Array<Outline_Users>;
  /** fetch aggregated fields from the table: "outline_users" */
  outline_users_aggregate: Outline_Users_Aggregate;
  /** fetch data from the table: "outline_users" using primary key columns */
  outline_users_by_pk?: Maybe<Outline_Users>;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: Permissions_Aggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table: "public_notes" */
  public_notes: Array<Public_Notes>;
  /** fetch aggregated fields from the table: "public_notes" */
  public_notes_aggregate: Public_Notes_Aggregate;
  /** fetch data from the table: "public_notes" using primary key columns */
  public_notes_by_pk?: Maybe<Public_Notes>;
  /** fetch data from the table: "public_notes_client_access" */
  public_notes_client_access: Array<Public_Notes_Client_Access>;
  /** fetch aggregated fields from the table: "public_notes_client_access" */
  public_notes_client_access_aggregate: Public_Notes_Client_Access_Aggregate;
  /** fetch data from the table: "public_notes_client_access" using primary key columns */
  public_notes_client_access_by_pk?: Maybe<Public_Notes_Client_Access>;
  /** fetch data from the table: "public_notes_team_access" */
  public_notes_team_access: Array<Public_Notes_Team_Access>;
  /** fetch aggregated fields from the table: "public_notes_team_access" */
  public_notes_team_access_aggregate: Public_Notes_Team_Access_Aggregate;
  /** fetch data from the table: "public_notes_team_access" using primary key columns */
  public_notes_team_access_by_pk?: Maybe<Public_Notes_Team_Access>;
  /** fetch data from the table: "team_contacts" */
  team_contacts: Array<Team_Contacts>;
  /** fetch aggregated fields from the table: "team_contacts" */
  team_contacts_aggregate: Team_Contacts_Aggregate;
  /** fetch data from the table: "team_contacts" using primary key columns */
  team_contacts_by_pk?: Maybe<Team_Contacts>;
  /** fetch data from the table: "teams" */
  teams: Array<Teams>;
  /** fetch aggregated fields from the table: "teams" */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table: "user_chart_settings" */
  user_chart_settings: Array<User_Chart_Settings>;
  /** fetch aggregated fields from the table: "user_chart_settings" */
  user_chart_settings_aggregate: User_Chart_Settings_Aggregate;
  /** fetch data from the table: "user_chart_settings" using primary key columns */
  user_chart_settings_by_pk?: Maybe<User_Chart_Settings>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_online" */
  users_online: Array<Users_Online>;
  /** fetch aggregated fields from the table: "users_online" */
  users_online_aggregate: Users_Online_Aggregate;
  /** fetch data from the table: "workspaces" */
  workspaces: Array<Workspaces>;
  /** fetch aggregated fields from the table: "workspaces" */
  workspaces_aggregate: Workspaces_Aggregate;
  /** fetch data from the table: "workspaces" using primary key columns */
  workspaces_by_pk?: Maybe<Workspaces>;
};


export type Query_RootChart_IndicatorsArgs = {
  distinct_on?: Maybe<Array<Chart_Indicators_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Indicators_Order_By>>;
  where?: Maybe<Chart_Indicators_Bool_Exp>;
};


export type Query_RootChart_Indicators_AggregateArgs = {
  distinct_on?: Maybe<Array<Chart_Indicators_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Indicators_Order_By>>;
  where?: Maybe<Chart_Indicators_Bool_Exp>;
};


export type Query_RootChart_Indicators_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootChart_PeriodsArgs = {
  distinct_on?: Maybe<Array<Chart_Periods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Periods_Order_By>>;
  where?: Maybe<Chart_Periods_Bool_Exp>;
};


export type Query_RootChart_Periods_AggregateArgs = {
  distinct_on?: Maybe<Array<Chart_Periods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Periods_Order_By>>;
  where?: Maybe<Chart_Periods_Bool_Exp>;
};


export type Query_RootChart_Periods_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootChart_StylesArgs = {
  distinct_on?: Maybe<Array<Chart_Styles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Styles_Order_By>>;
  where?: Maybe<Chart_Styles_Bool_Exp>;
};


export type Query_RootChart_Styles_AggregateArgs = {
  distinct_on?: Maybe<Array<Chart_Styles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Styles_Order_By>>;
  where?: Maybe<Chart_Styles_Bool_Exp>;
};


export type Query_RootChart_Styles_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootChartsArgs = {
  distinct_on?: Maybe<Array<Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Charts_Order_By>>;
  where?: Maybe<Charts_Bool_Exp>;
};


export type Query_RootCharts_AggregateArgs = {
  distinct_on?: Maybe<Array<Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Charts_Order_By>>;
  where?: Maybe<Charts_Bool_Exp>;
};


export type Query_RootCharts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClient_ChartsArgs = {
  distinct_on?: Maybe<Array<Client_Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Charts_Order_By>>;
  where?: Maybe<Client_Charts_Bool_Exp>;
};


export type Query_RootClient_Charts_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Charts_Order_By>>;
  where?: Maybe<Client_Charts_Bool_Exp>;
};


export type Query_RootClient_Charts_By_PkArgs = {
  client_id: Scalars['String'];
  symbol: Scalars['String'];
};


export type Query_RootClient_CompaniesArgs = {
  distinct_on?: Maybe<Array<Client_Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Companies_Order_By>>;
  where?: Maybe<Client_Companies_Bool_Exp>;
};


export type Query_RootClient_Companies_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Companies_Order_By>>;
  where?: Maybe<Client_Companies_Bool_Exp>;
};


export type Query_RootClient_Companies_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClientsArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


export type Query_RootClients_AggregateArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


export type Query_RootClients_By_PkArgs = {
  user_id: Scalars['String'];
};


export type Query_RootGetFinancialDataArgs = {
  period?: Maybe<QueryPeriods>;
  symbol?: Maybe<Scalars['String']>;
};


export type Query_RootGetSignedUrlArgs = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
};


export type Query_RootLayoutsArgs = {
  distinct_on?: Maybe<Array<Layouts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Layouts_Order_By>>;
  where?: Maybe<Layouts_Bool_Exp>;
};


export type Query_RootLayouts_AggregateArgs = {
  distinct_on?: Maybe<Array<Layouts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Layouts_Order_By>>;
  where?: Maybe<Layouts_Bool_Exp>;
};


export type Query_RootLayouts_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootMessage_ThreadsArgs = {
  distinct_on?: Maybe<Array<Message_Threads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Threads_Order_By>>;
  where?: Maybe<Message_Threads_Bool_Exp>;
};


export type Query_RootMessage_Threads_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Threads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Threads_Order_By>>;
  where?: Maybe<Message_Threads_Bool_Exp>;
};


export type Query_RootMessage_Threads_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNote_TypesArgs = {
  distinct_on?: Maybe<Array<Note_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Types_Order_By>>;
  where?: Maybe<Note_Types_Bool_Exp>;
};


export type Query_RootNote_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Note_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Types_Order_By>>;
  where?: Maybe<Note_Types_Bool_Exp>;
};


export type Query_RootNote_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Query_RootNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Query_RootNotes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootNotes_Users_NotificationsArgs = {
  distinct_on?: Maybe<Array<Notes_Users_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Users_Notifications_Order_By>>;
  where?: Maybe<Notes_Users_Notifications_Bool_Exp>;
};


export type Query_RootNotes_Users_Notifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Users_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Users_Notifications_Order_By>>;
  where?: Maybe<Notes_Users_Notifications_Bool_Exp>;
};


export type Query_RootNotes_Users_Notifications_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOutline_UsersArgs = {
  distinct_on?: Maybe<Array<Outline_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outline_Users_Order_By>>;
  where?: Maybe<Outline_Users_Bool_Exp>;
};


export type Query_RootOutline_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Outline_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outline_Users_Order_By>>;
  where?: Maybe<Outline_Users_Bool_Exp>;
};


export type Query_RootOutline_Users_By_PkArgs = {
  user_id: Scalars['String'];
};


export type Query_RootPermissionsArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};


export type Query_RootPermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};


export type Query_RootPermissions_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootPublic_NotesArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Order_By>>;
  where?: Maybe<Public_Notes_Bool_Exp>;
};


export type Query_RootPublic_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Order_By>>;
  where?: Maybe<Public_Notes_Bool_Exp>;
};


export type Query_RootPublic_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPublic_Notes_Client_AccessArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Client_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Client_Access_Order_By>>;
  where?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
};


export type Query_RootPublic_Notes_Client_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Client_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Client_Access_Order_By>>;
  where?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
};


export type Query_RootPublic_Notes_Client_Access_By_PkArgs = {
  client_id: Scalars['String'];
  public_note_id: Scalars['Int'];
};


export type Query_RootPublic_Notes_Team_AccessArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Team_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Team_Access_Order_By>>;
  where?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
};


export type Query_RootPublic_Notes_Team_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Team_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Team_Access_Order_By>>;
  where?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
};


export type Query_RootPublic_Notes_Team_Access_By_PkArgs = {
  public_note_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type Query_RootTeam_ContactsArgs = {
  distinct_on?: Maybe<Array<Team_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Contacts_Order_By>>;
  where?: Maybe<Team_Contacts_Bool_Exp>;
};


export type Query_RootTeam_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Team_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Contacts_Order_By>>;
  where?: Maybe<Team_Contacts_Bool_Exp>;
};


export type Query_RootTeam_Contacts_By_PkArgs = {
  client_id: Scalars['String'];
  team_id: Scalars['Int'];
};


export type Query_RootTeamsArgs = {
  distinct_on?: Maybe<Array<Teams_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Order_By>>;
  where?: Maybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_AggregateArgs = {
  distinct_on?: Maybe<Array<Teams_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Order_By>>;
  where?: Maybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUser_Chart_SettingsArgs = {
  distinct_on?: Maybe<Array<User_Chart_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Chart_Settings_Order_By>>;
  where?: Maybe<User_Chart_Settings_Bool_Exp>;
};


export type Query_RootUser_Chart_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Chart_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Chart_Settings_Order_By>>;
  where?: Maybe<User_Chart_Settings_Bool_Exp>;
};


export type Query_RootUser_Chart_Settings_By_PkArgs = {
  chart_id: Scalars['Int'];
  user_id: Scalars['String'];
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootUsers_OnlineArgs = {
  distinct_on?: Maybe<Array<Users_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Online_Order_By>>;
  where?: Maybe<Users_Online_Bool_Exp>;
};


export type Query_RootUsers_Online_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Online_Order_By>>;
  where?: Maybe<Users_Online_Bool_Exp>;
};


export type Query_RootWorkspacesArgs = {
  distinct_on?: Maybe<Array<Workspaces_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workspaces_Order_By>>;
  where?: Maybe<Workspaces_Bool_Exp>;
};


export type Query_RootWorkspaces_AggregateArgs = {
  distinct_on?: Maybe<Array<Workspaces_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workspaces_Order_By>>;
  where?: Maybe<Workspaces_Bool_Exp>;
};


export type Query_RootWorkspaces_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "chart_indicators" */
  chart_indicators: Array<Chart_Indicators>;
  /** fetch aggregated fields from the table: "chart_indicators" */
  chart_indicators_aggregate: Chart_Indicators_Aggregate;
  /** fetch data from the table: "chart_indicators" using primary key columns */
  chart_indicators_by_pk?: Maybe<Chart_Indicators>;
  /** fetch data from the table: "chart_periods" */
  chart_periods: Array<Chart_Periods>;
  /** fetch aggregated fields from the table: "chart_periods" */
  chart_periods_aggregate: Chart_Periods_Aggregate;
  /** fetch data from the table: "chart_periods" using primary key columns */
  chart_periods_by_pk?: Maybe<Chart_Periods>;
  /** fetch data from the table: "chart_styles" */
  chart_styles: Array<Chart_Styles>;
  /** fetch aggregated fields from the table: "chart_styles" */
  chart_styles_aggregate: Chart_Styles_Aggregate;
  /** fetch data from the table: "chart_styles" using primary key columns */
  chart_styles_by_pk?: Maybe<Chart_Styles>;
  /** fetch data from the table: "charts" */
  charts: Array<Charts>;
  /** fetch aggregated fields from the table: "charts" */
  charts_aggregate: Charts_Aggregate;
  /** fetch data from the table: "charts" using primary key columns */
  charts_by_pk?: Maybe<Charts>;
  /** fetch data from the table: "client_charts" */
  client_charts: Array<Client_Charts>;
  /** fetch aggregated fields from the table: "client_charts" */
  client_charts_aggregate: Client_Charts_Aggregate;
  /** fetch data from the table: "client_charts" using primary key columns */
  client_charts_by_pk?: Maybe<Client_Charts>;
  /** fetch data from the table: "client_companies" */
  client_companies: Array<Client_Companies>;
  /** fetch aggregated fields from the table: "client_companies" */
  client_companies_aggregate: Client_Companies_Aggregate;
  /** fetch data from the table: "client_companies" using primary key columns */
  client_companies_by_pk?: Maybe<Client_Companies>;
  /** fetch data from the table: "clients" */
  clients: Array<Clients>;
  /** fetch aggregated fields from the table: "clients" */
  clients_aggregate: Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  clients_by_pk?: Maybe<Clients>;
  /** fetch data from the table: "layouts" */
  layouts: Array<Layouts>;
  /** fetch aggregated fields from the table: "layouts" */
  layouts_aggregate: Layouts_Aggregate;
  /** fetch data from the table: "layouts" using primary key columns */
  layouts_by_pk?: Maybe<Layouts>;
  /** fetch data from the table: "message_threads" */
  message_threads: Array<Message_Threads>;
  /** fetch aggregated fields from the table: "message_threads" */
  message_threads_aggregate: Message_Threads_Aggregate;
  /** fetch data from the table: "message_threads" using primary key columns */
  message_threads_by_pk?: Maybe<Message_Threads>;
  /** fetch data from the table: "note_types" */
  note_types: Array<Note_Types>;
  /** fetch aggregated fields from the table: "note_types" */
  note_types_aggregate: Note_Types_Aggregate;
  /** fetch data from the table: "note_types" using primary key columns */
  note_types_by_pk?: Maybe<Note_Types>;
  /** fetch data from the table: "notes" */
  notes: Array<Notes>;
  /** fetch aggregated fields from the table: "notes" */
  notes_aggregate: Notes_Aggregate;
  /** fetch data from the table: "notes" using primary key columns */
  notes_by_pk?: Maybe<Notes>;
  /** fetch data from the table: "notes_users_notifications" */
  notes_users_notifications: Array<Notes_Users_Notifications>;
  /** fetch aggregated fields from the table: "notes_users_notifications" */
  notes_users_notifications_aggregate: Notes_Users_Notifications_Aggregate;
  /** fetch data from the table: "notes_users_notifications" using primary key columns */
  notes_users_notifications_by_pk?: Maybe<Notes_Users_Notifications>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "outline_users" */
  outline_users: Array<Outline_Users>;
  /** fetch aggregated fields from the table: "outline_users" */
  outline_users_aggregate: Outline_Users_Aggregate;
  /** fetch data from the table: "outline_users" using primary key columns */
  outline_users_by_pk?: Maybe<Outline_Users>;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: Permissions_Aggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table: "public_notes" */
  public_notes: Array<Public_Notes>;
  /** fetch aggregated fields from the table: "public_notes" */
  public_notes_aggregate: Public_Notes_Aggregate;
  /** fetch data from the table: "public_notes" using primary key columns */
  public_notes_by_pk?: Maybe<Public_Notes>;
  /** fetch data from the table: "public_notes_client_access" */
  public_notes_client_access: Array<Public_Notes_Client_Access>;
  /** fetch aggregated fields from the table: "public_notes_client_access" */
  public_notes_client_access_aggregate: Public_Notes_Client_Access_Aggregate;
  /** fetch data from the table: "public_notes_client_access" using primary key columns */
  public_notes_client_access_by_pk?: Maybe<Public_Notes_Client_Access>;
  /** fetch data from the table: "public_notes_team_access" */
  public_notes_team_access: Array<Public_Notes_Team_Access>;
  /** fetch aggregated fields from the table: "public_notes_team_access" */
  public_notes_team_access_aggregate: Public_Notes_Team_Access_Aggregate;
  /** fetch data from the table: "public_notes_team_access" using primary key columns */
  public_notes_team_access_by_pk?: Maybe<Public_Notes_Team_Access>;
  /** fetch data from the table: "team_contacts" */
  team_contacts: Array<Team_Contacts>;
  /** fetch aggregated fields from the table: "team_contacts" */
  team_contacts_aggregate: Team_Contacts_Aggregate;
  /** fetch data from the table: "team_contacts" using primary key columns */
  team_contacts_by_pk?: Maybe<Team_Contacts>;
  /** fetch data from the table: "teams" */
  teams: Array<Teams>;
  /** fetch aggregated fields from the table: "teams" */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table: "user_chart_settings" */
  user_chart_settings: Array<User_Chart_Settings>;
  /** fetch aggregated fields from the table: "user_chart_settings" */
  user_chart_settings_aggregate: User_Chart_Settings_Aggregate;
  /** fetch data from the table: "user_chart_settings" using primary key columns */
  user_chart_settings_by_pk?: Maybe<User_Chart_Settings>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_online" */
  users_online: Array<Users_Online>;
  /** fetch aggregated fields from the table: "users_online" */
  users_online_aggregate: Users_Online_Aggregate;
  /** fetch data from the table: "workspaces" */
  workspaces: Array<Workspaces>;
  /** fetch aggregated fields from the table: "workspaces" */
  workspaces_aggregate: Workspaces_Aggregate;
  /** fetch data from the table: "workspaces" using primary key columns */
  workspaces_by_pk?: Maybe<Workspaces>;
};


export type Subscription_RootChart_IndicatorsArgs = {
  distinct_on?: Maybe<Array<Chart_Indicators_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Indicators_Order_By>>;
  where?: Maybe<Chart_Indicators_Bool_Exp>;
};


export type Subscription_RootChart_Indicators_AggregateArgs = {
  distinct_on?: Maybe<Array<Chart_Indicators_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Indicators_Order_By>>;
  where?: Maybe<Chart_Indicators_Bool_Exp>;
};


export type Subscription_RootChart_Indicators_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootChart_PeriodsArgs = {
  distinct_on?: Maybe<Array<Chart_Periods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Periods_Order_By>>;
  where?: Maybe<Chart_Periods_Bool_Exp>;
};


export type Subscription_RootChart_Periods_AggregateArgs = {
  distinct_on?: Maybe<Array<Chart_Periods_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Periods_Order_By>>;
  where?: Maybe<Chart_Periods_Bool_Exp>;
};


export type Subscription_RootChart_Periods_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootChart_StylesArgs = {
  distinct_on?: Maybe<Array<Chart_Styles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Styles_Order_By>>;
  where?: Maybe<Chart_Styles_Bool_Exp>;
};


export type Subscription_RootChart_Styles_AggregateArgs = {
  distinct_on?: Maybe<Array<Chart_Styles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Chart_Styles_Order_By>>;
  where?: Maybe<Chart_Styles_Bool_Exp>;
};


export type Subscription_RootChart_Styles_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootChartsArgs = {
  distinct_on?: Maybe<Array<Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Charts_Order_By>>;
  where?: Maybe<Charts_Bool_Exp>;
};


export type Subscription_RootCharts_AggregateArgs = {
  distinct_on?: Maybe<Array<Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Charts_Order_By>>;
  where?: Maybe<Charts_Bool_Exp>;
};


export type Subscription_RootCharts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClient_ChartsArgs = {
  distinct_on?: Maybe<Array<Client_Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Charts_Order_By>>;
  where?: Maybe<Client_Charts_Bool_Exp>;
};


export type Subscription_RootClient_Charts_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Charts_Order_By>>;
  where?: Maybe<Client_Charts_Bool_Exp>;
};


export type Subscription_RootClient_Charts_By_PkArgs = {
  client_id: Scalars['String'];
  symbol: Scalars['String'];
};


export type Subscription_RootClient_CompaniesArgs = {
  distinct_on?: Maybe<Array<Client_Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Companies_Order_By>>;
  where?: Maybe<Client_Companies_Bool_Exp>;
};


export type Subscription_RootClient_Companies_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Companies_Order_By>>;
  where?: Maybe<Client_Companies_Bool_Exp>;
};


export type Subscription_RootClient_Companies_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClientsArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


export type Subscription_RootClients_AggregateArgs = {
  distinct_on?: Maybe<Array<Clients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clients_Order_By>>;
  where?: Maybe<Clients_Bool_Exp>;
};


export type Subscription_RootClients_By_PkArgs = {
  user_id: Scalars['String'];
};


export type Subscription_RootLayoutsArgs = {
  distinct_on?: Maybe<Array<Layouts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Layouts_Order_By>>;
  where?: Maybe<Layouts_Bool_Exp>;
};


export type Subscription_RootLayouts_AggregateArgs = {
  distinct_on?: Maybe<Array<Layouts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Layouts_Order_By>>;
  where?: Maybe<Layouts_Bool_Exp>;
};


export type Subscription_RootLayouts_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootMessage_ThreadsArgs = {
  distinct_on?: Maybe<Array<Message_Threads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Threads_Order_By>>;
  where?: Maybe<Message_Threads_Bool_Exp>;
};


export type Subscription_RootMessage_Threads_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Threads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Threads_Order_By>>;
  where?: Maybe<Message_Threads_Bool_Exp>;
};


export type Subscription_RootMessage_Threads_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootNote_TypesArgs = {
  distinct_on?: Maybe<Array<Note_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Types_Order_By>>;
  where?: Maybe<Note_Types_Bool_Exp>;
};


export type Subscription_RootNote_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Note_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Note_Types_Order_By>>;
  where?: Maybe<Note_Types_Bool_Exp>;
};


export type Subscription_RootNote_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootNotesArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Subscription_RootNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Order_By>>;
  where?: Maybe<Notes_Bool_Exp>;
};


export type Subscription_RootNotes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootNotes_Users_NotificationsArgs = {
  distinct_on?: Maybe<Array<Notes_Users_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Users_Notifications_Order_By>>;
  where?: Maybe<Notes_Users_Notifications_Bool_Exp>;
};


export type Subscription_RootNotes_Users_Notifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Notes_Users_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notes_Users_Notifications_Order_By>>;
  where?: Maybe<Notes_Users_Notifications_Bool_Exp>;
};


export type Subscription_RootNotes_Users_Notifications_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOutline_UsersArgs = {
  distinct_on?: Maybe<Array<Outline_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outline_Users_Order_By>>;
  where?: Maybe<Outline_Users_Bool_Exp>;
};


export type Subscription_RootOutline_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Outline_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outline_Users_Order_By>>;
  where?: Maybe<Outline_Users_Bool_Exp>;
};


export type Subscription_RootOutline_Users_By_PkArgs = {
  user_id: Scalars['String'];
};


export type Subscription_RootPermissionsArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};


export type Subscription_RootPermissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Permissions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permissions_Order_By>>;
  where?: Maybe<Permissions_Bool_Exp>;
};


export type Subscription_RootPermissions_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPublic_NotesArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Order_By>>;
  where?: Maybe<Public_Notes_Bool_Exp>;
};


export type Subscription_RootPublic_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Order_By>>;
  where?: Maybe<Public_Notes_Bool_Exp>;
};


export type Subscription_RootPublic_Notes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPublic_Notes_Client_AccessArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Client_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Client_Access_Order_By>>;
  where?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
};


export type Subscription_RootPublic_Notes_Client_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Client_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Client_Access_Order_By>>;
  where?: Maybe<Public_Notes_Client_Access_Bool_Exp>;
};


export type Subscription_RootPublic_Notes_Client_Access_By_PkArgs = {
  client_id: Scalars['String'];
  public_note_id: Scalars['Int'];
};


export type Subscription_RootPublic_Notes_Team_AccessArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Team_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Team_Access_Order_By>>;
  where?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
};


export type Subscription_RootPublic_Notes_Team_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Team_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Team_Access_Order_By>>;
  where?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
};


export type Subscription_RootPublic_Notes_Team_Access_By_PkArgs = {
  public_note_id: Scalars['Int'];
  team_id: Scalars['Int'];
};


export type Subscription_RootTeam_ContactsArgs = {
  distinct_on?: Maybe<Array<Team_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Contacts_Order_By>>;
  where?: Maybe<Team_Contacts_Bool_Exp>;
};


export type Subscription_RootTeam_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Team_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Contacts_Order_By>>;
  where?: Maybe<Team_Contacts_Bool_Exp>;
};


export type Subscription_RootTeam_Contacts_By_PkArgs = {
  client_id: Scalars['String'];
  team_id: Scalars['Int'];
};


export type Subscription_RootTeamsArgs = {
  distinct_on?: Maybe<Array<Teams_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Order_By>>;
  where?: Maybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_AggregateArgs = {
  distinct_on?: Maybe<Array<Teams_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Order_By>>;
  where?: Maybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_Chart_SettingsArgs = {
  distinct_on?: Maybe<Array<User_Chart_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Chart_Settings_Order_By>>;
  where?: Maybe<User_Chart_Settings_Bool_Exp>;
};


export type Subscription_RootUser_Chart_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Chart_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Chart_Settings_Order_By>>;
  where?: Maybe<User_Chart_Settings_Bool_Exp>;
};


export type Subscription_RootUser_Chart_Settings_By_PkArgs = {
  chart_id: Scalars['Int'];
  user_id: Scalars['String'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootUsers_OnlineArgs = {
  distinct_on?: Maybe<Array<Users_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Online_Order_By>>;
  where?: Maybe<Users_Online_Bool_Exp>;
};


export type Subscription_RootUsers_Online_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Online_Order_By>>;
  where?: Maybe<Users_Online_Bool_Exp>;
};


export type Subscription_RootWorkspacesArgs = {
  distinct_on?: Maybe<Array<Workspaces_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workspaces_Order_By>>;
  where?: Maybe<Workspaces_Bool_Exp>;
};


export type Subscription_RootWorkspaces_AggregateArgs = {
  distinct_on?: Maybe<Array<Workspaces_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Workspaces_Order_By>>;
  where?: Maybe<Workspaces_Bool_Exp>;
};


export type Subscription_RootWorkspaces_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "team_contacts" */
export type Team_Contacts = {
  __typename?: 'team_contacts';
  channel_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  client: Clients;
  client_id: Scalars['String'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['Int'];
};

/** aggregated selection of "team_contacts" */
export type Team_Contacts_Aggregate = {
  __typename?: 'team_contacts_aggregate';
  aggregate?: Maybe<Team_Contacts_Aggregate_Fields>;
  nodes: Array<Team_Contacts>;
};

/** aggregate fields of "team_contacts" */
export type Team_Contacts_Aggregate_Fields = {
  __typename?: 'team_contacts_aggregate_fields';
  avg?: Maybe<Team_Contacts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Team_Contacts_Max_Fields>;
  min?: Maybe<Team_Contacts_Min_Fields>;
  stddev?: Maybe<Team_Contacts_Stddev_Fields>;
  stddev_pop?: Maybe<Team_Contacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Team_Contacts_Stddev_Samp_Fields>;
  sum?: Maybe<Team_Contacts_Sum_Fields>;
  var_pop?: Maybe<Team_Contacts_Var_Pop_Fields>;
  var_samp?: Maybe<Team_Contacts_Var_Samp_Fields>;
  variance?: Maybe<Team_Contacts_Variance_Fields>;
};


/** aggregate fields of "team_contacts" */
export type Team_Contacts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Team_Contacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "team_contacts" */
export type Team_Contacts_Aggregate_Order_By = {
  avg?: Maybe<Team_Contacts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Team_Contacts_Max_Order_By>;
  min?: Maybe<Team_Contacts_Min_Order_By>;
  stddev?: Maybe<Team_Contacts_Stddev_Order_By>;
  stddev_pop?: Maybe<Team_Contacts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Team_Contacts_Stddev_Samp_Order_By>;
  sum?: Maybe<Team_Contacts_Sum_Order_By>;
  var_pop?: Maybe<Team_Contacts_Var_Pop_Order_By>;
  var_samp?: Maybe<Team_Contacts_Var_Samp_Order_By>;
  variance?: Maybe<Team_Contacts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "team_contacts" */
export type Team_Contacts_Arr_Rel_Insert_Input = {
  data: Array<Team_Contacts_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Team_Contacts_On_Conflict>;
};

/** aggregate avg on columns */
export type Team_Contacts_Avg_Fields = {
  __typename?: 'team_contacts_avg_fields';
  team_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "team_contacts" */
export type Team_Contacts_Avg_Order_By = {
  team_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "team_contacts". All fields are combined with a logical 'AND'. */
export type Team_Contacts_Bool_Exp = {
  _and?: Maybe<Array<Team_Contacts_Bool_Exp>>;
  _not?: Maybe<Team_Contacts_Bool_Exp>;
  _or?: Maybe<Array<Team_Contacts_Bool_Exp>>;
  channel_id?: Maybe<String_Comparison_Exp>;
  client?: Maybe<Clients_Bool_Exp>;
  client_id?: Maybe<String_Comparison_Exp>;
  team?: Maybe<Teams_Bool_Exp>;
  team_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_contacts" */
export enum Team_Contacts_Constraint {
  /** unique or primary key constraint */
  TeamContactsPkey = 'team_contacts_pkey'
}

/** input type for incrementing numeric columns in table "team_contacts" */
export type Team_Contacts_Inc_Input = {
  team_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "team_contacts" */
export type Team_Contacts_Insert_Input = {
  channel_id?: Maybe<Scalars['String']>;
  client?: Maybe<Clients_Obj_Rel_Insert_Input>;
  client_id?: Maybe<Scalars['String']>;
  team?: Maybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Team_Contacts_Max_Fields = {
  __typename?: 'team_contacts_max_fields';
  channel_id?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "team_contacts" */
export type Team_Contacts_Max_Order_By = {
  channel_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Contacts_Min_Fields = {
  __typename?: 'team_contacts_min_fields';
  channel_id?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "team_contacts" */
export type Team_Contacts_Min_Order_By = {
  channel_id?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "team_contacts" */
export type Team_Contacts_Mutation_Response = {
  __typename?: 'team_contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_Contacts>;
};

/** on conflict condition type for table "team_contacts" */
export type Team_Contacts_On_Conflict = {
  constraint: Team_Contacts_Constraint;
  update_columns: Array<Team_Contacts_Update_Column>;
  where?: Maybe<Team_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "team_contacts". */
export type Team_Contacts_Order_By = {
  channel_id?: Maybe<Order_By>;
  client?: Maybe<Clients_Order_By>;
  client_id?: Maybe<Order_By>;
  team?: Maybe<Teams_Order_By>;
  team_id?: Maybe<Order_By>;
};

/** primary key columns input for table: team_contacts */
export type Team_Contacts_Pk_Columns_Input = {
  client_id: Scalars['String'];
  team_id: Scalars['Int'];
};

/** select columns of table "team_contacts" */
export enum Team_Contacts_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "team_contacts" */
export type Team_Contacts_Set_Input = {
  channel_id?: Maybe<Scalars['String']>;
  client_id?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Team_Contacts_Stddev_Fields = {
  __typename?: 'team_contacts_stddev_fields';
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "team_contacts" */
export type Team_Contacts_Stddev_Order_By = {
  team_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Team_Contacts_Stddev_Pop_Fields = {
  __typename?: 'team_contacts_stddev_pop_fields';
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "team_contacts" */
export type Team_Contacts_Stddev_Pop_Order_By = {
  team_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Team_Contacts_Stddev_Samp_Fields = {
  __typename?: 'team_contacts_stddev_samp_fields';
  team_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "team_contacts" */
export type Team_Contacts_Stddev_Samp_Order_By = {
  team_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Team_Contacts_Sum_Fields = {
  __typename?: 'team_contacts_sum_fields';
  team_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "team_contacts" */
export type Team_Contacts_Sum_Order_By = {
  team_id?: Maybe<Order_By>;
};

/** update columns of table "team_contacts" */
export enum Team_Contacts_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  TeamId = 'team_id'
}

/** aggregate var_pop on columns */
export type Team_Contacts_Var_Pop_Fields = {
  __typename?: 'team_contacts_var_pop_fields';
  team_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "team_contacts" */
export type Team_Contacts_Var_Pop_Order_By = {
  team_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Team_Contacts_Var_Samp_Fields = {
  __typename?: 'team_contacts_var_samp_fields';
  team_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "team_contacts" */
export type Team_Contacts_Var_Samp_Order_By = {
  team_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Team_Contacts_Variance_Fields = {
  __typename?: 'team_contacts_variance_fields';
  team_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "team_contacts" */
export type Team_Contacts_Variance_Order_By = {
  team_id?: Maybe<Order_By>;
};

/** columns and relationships of "teams" */
export type Teams = {
  __typename?: 'teams';
  /** An array relationship */
  contacts: Array<Team_Contacts>;
  /** An aggregate relationship */
  contacts_aggregate: Team_Contacts_Aggregate;
  id: Scalars['Int'];
  /** An array relationship */
  members: Array<Outline_Users>;
  /** An aggregate relationship */
  members_aggregate: Outline_Users_Aggregate;
  name: Scalars['String'];
  organization_id: Scalars['Int'];
  /** An array relationship */
  public_notes_team: Array<Public_Notes_Team_Access>;
  /** An aggregate relationship */
  public_notes_team_aggregate: Public_Notes_Team_Access_Aggregate;
};


/** columns and relationships of "teams" */
export type TeamsContactsArgs = {
  distinct_on?: Maybe<Array<Team_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Contacts_Order_By>>;
  where?: Maybe<Team_Contacts_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Team_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Team_Contacts_Order_By>>;
  where?: Maybe<Team_Contacts_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMembersArgs = {
  distinct_on?: Maybe<Array<Outline_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outline_Users_Order_By>>;
  where?: Maybe<Outline_Users_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Outline_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outline_Users_Order_By>>;
  where?: Maybe<Outline_Users_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsPublic_Notes_TeamArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Team_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Team_Access_Order_By>>;
  where?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsPublic_Notes_Team_AggregateArgs = {
  distinct_on?: Maybe<Array<Public_Notes_Team_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Public_Notes_Team_Access_Order_By>>;
  where?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
};

/** aggregated selection of "teams" */
export type Teams_Aggregate = {
  __typename?: 'teams_aggregate';
  aggregate?: Maybe<Teams_Aggregate_Fields>;
  nodes: Array<Teams>;
};

/** aggregate fields of "teams" */
export type Teams_Aggregate_Fields = {
  __typename?: 'teams_aggregate_fields';
  avg?: Maybe<Teams_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teams_Max_Fields>;
  min?: Maybe<Teams_Min_Fields>;
  stddev?: Maybe<Teams_Stddev_Fields>;
  stddev_pop?: Maybe<Teams_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teams_Stddev_Samp_Fields>;
  sum?: Maybe<Teams_Sum_Fields>;
  var_pop?: Maybe<Teams_Var_Pop_Fields>;
  var_samp?: Maybe<Teams_Var_Samp_Fields>;
  variance?: Maybe<Teams_Variance_Fields>;
};


/** aggregate fields of "teams" */
export type Teams_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teams_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Teams_Avg_Fields = {
  __typename?: 'teams_avg_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "teams". All fields are combined with a logical 'AND'. */
export type Teams_Bool_Exp = {
  _and?: Maybe<Array<Teams_Bool_Exp>>;
  _not?: Maybe<Teams_Bool_Exp>;
  _or?: Maybe<Array<Teams_Bool_Exp>>;
  contacts?: Maybe<Team_Contacts_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  members?: Maybe<Outline_Users_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organization_id?: Maybe<Int_Comparison_Exp>;
  public_notes_team?: Maybe<Public_Notes_Team_Access_Bool_Exp>;
};

/** unique or primary key constraints on table "teams" */
export enum Teams_Constraint {
  /** unique or primary key constraint */
  PkTeams = 'pk_teams'
}

/** input type for incrementing numeric columns in table "teams" */
export type Teams_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "teams" */
export type Teams_Insert_Input = {
  contacts?: Maybe<Team_Contacts_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  members?: Maybe<Outline_Users_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
  public_notes_team?: Maybe<Public_Notes_Team_Access_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Teams_Max_Fields = {
  __typename?: 'teams_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Teams_Min_Fields = {
  __typename?: 'teams_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "teams" */
export type Teams_Mutation_Response = {
  __typename?: 'teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams>;
};

/** input type for inserting object relation for remote table "teams" */
export type Teams_Obj_Rel_Insert_Input = {
  data: Teams_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Teams_On_Conflict>;
};

/** on conflict condition type for table "teams" */
export type Teams_On_Conflict = {
  constraint: Teams_Constraint;
  update_columns: Array<Teams_Update_Column>;
  where?: Maybe<Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "teams". */
export type Teams_Order_By = {
  contacts_aggregate?: Maybe<Team_Contacts_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  members_aggregate?: Maybe<Outline_Users_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  public_notes_team_aggregate?: Maybe<Public_Notes_Team_Access_Aggregate_Order_By>;
};

/** primary key columns input for table: teams */
export type Teams_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "teams" */
export enum Teams_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id'
}

/** input type for updating data in table "teams" */
export type Teams_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Teams_Stddev_Fields = {
  __typename?: 'teams_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Teams_Stddev_Pop_Fields = {
  __typename?: 'teams_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Teams_Stddev_Samp_Fields = {
  __typename?: 'teams_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Teams_Sum_Fields = {
  __typename?: 'teams_sum_fields';
  id?: Maybe<Scalars['Int']>;
  organization_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "teams" */
export enum Teams_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id'
}

/** aggregate var_pop on columns */
export type Teams_Var_Pop_Fields = {
  __typename?: 'teams_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Teams_Var_Samp_Fields = {
  __typename?: 'teams_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Teams_Variance_Fields = {
  __typename?: 'teams_variance_fields';
  id?: Maybe<Scalars['Float']>;
  organization_id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_chart_settings" */
export type User_Chart_Settings = {
  __typename?: 'user_chart_settings';
  chart_id: Scalars['Int'];
  period?: Maybe<Chart_Periods_Enum>;
  type?: Maybe<Chart_Styles_Enum>;
  user_id: Scalars['String'];
};

/** aggregated selection of "user_chart_settings" */
export type User_Chart_Settings_Aggregate = {
  __typename?: 'user_chart_settings_aggregate';
  aggregate?: Maybe<User_Chart_Settings_Aggregate_Fields>;
  nodes: Array<User_Chart_Settings>;
};

/** aggregate fields of "user_chart_settings" */
export type User_Chart_Settings_Aggregate_Fields = {
  __typename?: 'user_chart_settings_aggregate_fields';
  avg?: Maybe<User_Chart_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Chart_Settings_Max_Fields>;
  min?: Maybe<User_Chart_Settings_Min_Fields>;
  stddev?: Maybe<User_Chart_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<User_Chart_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Chart_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<User_Chart_Settings_Sum_Fields>;
  var_pop?: Maybe<User_Chart_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<User_Chart_Settings_Var_Samp_Fields>;
  variance?: Maybe<User_Chart_Settings_Variance_Fields>;
};


/** aggregate fields of "user_chart_settings" */
export type User_Chart_Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Chart_Settings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_chart_settings" */
export type User_Chart_Settings_Aggregate_Order_By = {
  avg?: Maybe<User_Chart_Settings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Chart_Settings_Max_Order_By>;
  min?: Maybe<User_Chart_Settings_Min_Order_By>;
  stddev?: Maybe<User_Chart_Settings_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Chart_Settings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Chart_Settings_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Chart_Settings_Sum_Order_By>;
  var_pop?: Maybe<User_Chart_Settings_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Chart_Settings_Var_Samp_Order_By>;
  variance?: Maybe<User_Chart_Settings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_chart_settings" */
export type User_Chart_Settings_Arr_Rel_Insert_Input = {
  data: Array<User_Chart_Settings_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<User_Chart_Settings_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Chart_Settings_Avg_Fields = {
  __typename?: 'user_chart_settings_avg_fields';
  chart_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_chart_settings" */
export type User_Chart_Settings_Avg_Order_By = {
  chart_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_chart_settings". All fields are combined with a logical 'AND'. */
export type User_Chart_Settings_Bool_Exp = {
  _and?: Maybe<Array<User_Chart_Settings_Bool_Exp>>;
  _not?: Maybe<User_Chart_Settings_Bool_Exp>;
  _or?: Maybe<Array<User_Chart_Settings_Bool_Exp>>;
  chart_id?: Maybe<Int_Comparison_Exp>;
  period?: Maybe<Chart_Periods_Enum_Comparison_Exp>;
  type?: Maybe<Chart_Styles_Enum_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_chart_settings" */
export enum User_Chart_Settings_Constraint {
  /** unique or primary key constraint */
  UserChartSettingsPkey = 'user_chart_settings_pkey'
}

/** input type for incrementing numeric columns in table "user_chart_settings" */
export type User_Chart_Settings_Inc_Input = {
  chart_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_chart_settings" */
export type User_Chart_Settings_Insert_Input = {
  chart_id?: Maybe<Scalars['Int']>;
  period?: Maybe<Chart_Periods_Enum>;
  type?: Maybe<Chart_Styles_Enum>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Chart_Settings_Max_Fields = {
  __typename?: 'user_chart_settings_max_fields';
  chart_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_chart_settings" */
export type User_Chart_Settings_Max_Order_By = {
  chart_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Chart_Settings_Min_Fields = {
  __typename?: 'user_chart_settings_min_fields';
  chart_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_chart_settings" */
export type User_Chart_Settings_Min_Order_By = {
  chart_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_chart_settings" */
export type User_Chart_Settings_Mutation_Response = {
  __typename?: 'user_chart_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Chart_Settings>;
};

/** on conflict condition type for table "user_chart_settings" */
export type User_Chart_Settings_On_Conflict = {
  constraint: User_Chart_Settings_Constraint;
  update_columns: Array<User_Chart_Settings_Update_Column>;
  where?: Maybe<User_Chart_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "user_chart_settings". */
export type User_Chart_Settings_Order_By = {
  chart_id?: Maybe<Order_By>;
  period?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: user_chart_settings */
export type User_Chart_Settings_Pk_Columns_Input = {
  chart_id: Scalars['Int'];
  user_id: Scalars['String'];
};

/** select columns of table "user_chart_settings" */
export enum User_Chart_Settings_Select_Column {
  /** column name */
  ChartId = 'chart_id',
  /** column name */
  Period = 'period',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_chart_settings" */
export type User_Chart_Settings_Set_Input = {
  chart_id?: Maybe<Scalars['Int']>;
  period?: Maybe<Chart_Periods_Enum>;
  type?: Maybe<Chart_Styles_Enum>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Chart_Settings_Stddev_Fields = {
  __typename?: 'user_chart_settings_stddev_fields';
  chart_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_chart_settings" */
export type User_Chart_Settings_Stddev_Order_By = {
  chart_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Chart_Settings_Stddev_Pop_Fields = {
  __typename?: 'user_chart_settings_stddev_pop_fields';
  chart_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_chart_settings" */
export type User_Chart_Settings_Stddev_Pop_Order_By = {
  chart_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Chart_Settings_Stddev_Samp_Fields = {
  __typename?: 'user_chart_settings_stddev_samp_fields';
  chart_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_chart_settings" */
export type User_Chart_Settings_Stddev_Samp_Order_By = {
  chart_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Chart_Settings_Sum_Fields = {
  __typename?: 'user_chart_settings_sum_fields';
  chart_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_chart_settings" */
export type User_Chart_Settings_Sum_Order_By = {
  chart_id?: Maybe<Order_By>;
};

/** update columns of table "user_chart_settings" */
export enum User_Chart_Settings_Update_Column {
  /** column name */
  ChartId = 'chart_id',
  /** column name */
  Period = 'period',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type User_Chart_Settings_Var_Pop_Fields = {
  __typename?: 'user_chart_settings_var_pop_fields';
  chart_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_chart_settings" */
export type User_Chart_Settings_Var_Pop_Order_By = {
  chart_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Chart_Settings_Var_Samp_Fields = {
  __typename?: 'user_chart_settings_var_samp_fields';
  chart_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_chart_settings" */
export type User_Chart_Settings_Var_Samp_Order_By = {
  chart_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Chart_Settings_Variance_Fields = {
  __typename?: 'user_chart_settings_variance_fields';
  chart_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_chart_settings" */
export type User_Chart_Settings_Variance_Order_By = {
  chart_id?: Maybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An object relationship */
  client_user?: Maybe<Clients>;
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object relationship */
  is_online?: Maybe<Users_Online>;
  job_title?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  message_threads: Array<Message_Threads>;
  /** An aggregate relationship */
  message_threads_aggregate: Message_Threads_Aggregate;
  /** An object relationship */
  outline_user?: Maybe<Outline_Users>;
  sendbird_token?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersMessage_ThreadsArgs = {
  distinct_on?: Maybe<Array<Message_Threads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Threads_Order_By>>;
  where?: Maybe<Message_Threads_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMessage_Threads_AggregateArgs = {
  distinct_on?: Maybe<Array<Message_Threads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Message_Threads_Order_By>>;
  where?: Maybe<Message_Threads_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  client_user?: Maybe<Clients_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  is_online?: Maybe<Users_Online_Bool_Exp>;
  job_title?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  last_seen?: Maybe<Timestamptz_Comparison_Exp>;
  message_threads?: Maybe<Message_Threads_Bool_Exp>;
  outline_user?: Maybe<Outline_Users_Bool_Exp>;
  sendbird_token?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  PkUsers = 'pk_users'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  client_user?: Maybe<Clients_Obj_Rel_Insert_Input>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  is_online?: Maybe<Users_Online_Obj_Rel_Insert_Input>;
  job_title?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  message_threads?: Maybe<Message_Threads_Arr_Rel_Insert_Input>;
  outline_user?: Maybe<Outline_Users_Obj_Rel_Insert_Input>;
  sendbird_token?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  job_title?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  sendbird_token?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  job_title?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  sendbird_token?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** columns and relationships of "users_online" */
export type Users_Online = {
  __typename?: 'users_online';
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "users_online" */
export type Users_Online_Aggregate = {
  __typename?: 'users_online_aggregate';
  aggregate?: Maybe<Users_Online_Aggregate_Fields>;
  nodes: Array<Users_Online>;
};

/** aggregate fields of "users_online" */
export type Users_Online_Aggregate_Fields = {
  __typename?: 'users_online_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Online_Max_Fields>;
  min?: Maybe<Users_Online_Min_Fields>;
};


/** aggregate fields of "users_online" */
export type Users_Online_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Online_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users_online". All fields are combined with a logical 'AND'. */
export type Users_Online_Bool_Exp = {
  _and?: Maybe<Array<Users_Online_Bool_Exp>>;
  _not?: Maybe<Users_Online_Bool_Exp>;
  _or?: Maybe<Array<Users_Online_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  last_seen?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** input type for inserting data into table "users_online" */
export type Users_Online_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Online_Max_Fields = {
  __typename?: 'users_online_max_fields';
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Online_Min_Fields = {
  __typename?: 'users_online_min_fields';
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users_online" */
export type Users_Online_Mutation_Response = {
  __typename?: 'users_online_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Online>;
};

/** input type for inserting object relation for remote table "users_online" */
export type Users_Online_Obj_Rel_Insert_Input = {
  data: Users_Online_Insert_Input;
};

/** Ordering options when selecting data from "users_online". */
export type Users_Online_Order_By = {
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** select columns of table "users_online" */
export enum Users_Online_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen'
}

/** input type for updating data in table "users_online" */
export type Users_Online_Set_Input = {
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  client_user?: Maybe<Clients_Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_online?: Maybe<Users_Online_Order_By>;
  job_title?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  message_threads_aggregate?: Maybe<Message_Threads_Aggregate_Order_By>;
  outline_user?: Maybe<Outline_Users_Order_By>;
  sendbird_token?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitle = 'job_title',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  SendbirdToken = 'sendbird_token'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  job_title?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  sendbird_token?: Maybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  JobTitle = 'job_title',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  SendbirdToken = 'sendbird_token'
}

/** columns and relationships of "workspaces" */
export type Workspaces = {
  __typename?: 'workspaces';
  /** An array relationship */
  charts: Array<Charts>;
  /** An aggregate relationship */
  charts_aggregate: Charts_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  icon: Scalars['String'];
  id: Scalars['Int'];
  landing_pane_content?: Maybe<Scalars['json']>;
  layout?: Maybe<Layouts_Enum>;
  name: Scalars['String'];
  owner: Scalars['String'];
  permission?: Maybe<Permissions_Enum>;
};


/** columns and relationships of "workspaces" */
export type WorkspacesChartsArgs = {
  distinct_on?: Maybe<Array<Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Charts_Order_By>>;
  where?: Maybe<Charts_Bool_Exp>;
};


/** columns and relationships of "workspaces" */
export type WorkspacesCharts_AggregateArgs = {
  distinct_on?: Maybe<Array<Charts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Charts_Order_By>>;
  where?: Maybe<Charts_Bool_Exp>;
};


/** columns and relationships of "workspaces" */
export type WorkspacesLanding_Pane_ContentArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "workspaces" */
export type Workspaces_Aggregate = {
  __typename?: 'workspaces_aggregate';
  aggregate?: Maybe<Workspaces_Aggregate_Fields>;
  nodes: Array<Workspaces>;
};

/** aggregate fields of "workspaces" */
export type Workspaces_Aggregate_Fields = {
  __typename?: 'workspaces_aggregate_fields';
  avg?: Maybe<Workspaces_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workspaces_Max_Fields>;
  min?: Maybe<Workspaces_Min_Fields>;
  stddev?: Maybe<Workspaces_Stddev_Fields>;
  stddev_pop?: Maybe<Workspaces_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workspaces_Stddev_Samp_Fields>;
  sum?: Maybe<Workspaces_Sum_Fields>;
  var_pop?: Maybe<Workspaces_Var_Pop_Fields>;
  var_samp?: Maybe<Workspaces_Var_Samp_Fields>;
  variance?: Maybe<Workspaces_Variance_Fields>;
};


/** aggregate fields of "workspaces" */
export type Workspaces_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Workspaces_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Workspaces_Avg_Fields = {
  __typename?: 'workspaces_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "workspaces". All fields are combined with a logical 'AND'. */
export type Workspaces_Bool_Exp = {
  _and?: Maybe<Array<Workspaces_Bool_Exp>>;
  _not?: Maybe<Workspaces_Bool_Exp>;
  _or?: Maybe<Array<Workspaces_Bool_Exp>>;
  charts?: Maybe<Charts_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  icon?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  landing_pane_content?: Maybe<Json_Comparison_Exp>;
  layout?: Maybe<Layouts_Enum_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  owner?: Maybe<String_Comparison_Exp>;
  permission?: Maybe<Permissions_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "workspaces" */
export enum Workspaces_Constraint {
  /** unique or primary key constraint */
  PkWorkspaces = 'pk_workspaces'
}

/** input type for incrementing numeric columns in table "workspaces" */
export type Workspaces_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "workspaces" */
export type Workspaces_Insert_Input = {
  charts?: Maybe<Charts_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  landing_pane_content?: Maybe<Scalars['json']>;
  layout?: Maybe<Layouts_Enum>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  permission?: Maybe<Permissions_Enum>;
};

/** aggregate max on columns */
export type Workspaces_Max_Fields = {
  __typename?: 'workspaces_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Workspaces_Min_Fields = {
  __typename?: 'workspaces_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "workspaces" */
export type Workspaces_Mutation_Response = {
  __typename?: 'workspaces_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workspaces>;
};

/** input type for inserting object relation for remote table "workspaces" */
export type Workspaces_Obj_Rel_Insert_Input = {
  data: Workspaces_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Workspaces_On_Conflict>;
};

/** on conflict condition type for table "workspaces" */
export type Workspaces_On_Conflict = {
  constraint: Workspaces_Constraint;
  update_columns: Array<Workspaces_Update_Column>;
  where?: Maybe<Workspaces_Bool_Exp>;
};

/** Ordering options when selecting data from "workspaces". */
export type Workspaces_Order_By = {
  charts_aggregate?: Maybe<Charts_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  landing_pane_content?: Maybe<Order_By>;
  layout?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  owner?: Maybe<Order_By>;
  permission?: Maybe<Order_By>;
};

/** primary key columns input for table: workspaces */
export type Workspaces_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "workspaces" */
export enum Workspaces_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  LandingPaneContent = 'landing_pane_content',
  /** column name */
  Layout = 'layout',
  /** column name */
  Name = 'name',
  /** column name */
  Owner = 'owner',
  /** column name */
  Permission = 'permission'
}

/** input type for updating data in table "workspaces" */
export type Workspaces_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  landing_pane_content?: Maybe<Scalars['json']>;
  layout?: Maybe<Layouts_Enum>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  permission?: Maybe<Permissions_Enum>;
};

/** aggregate stddev on columns */
export type Workspaces_Stddev_Fields = {
  __typename?: 'workspaces_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Workspaces_Stddev_Pop_Fields = {
  __typename?: 'workspaces_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Workspaces_Stddev_Samp_Fields = {
  __typename?: 'workspaces_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Workspaces_Sum_Fields = {
  __typename?: 'workspaces_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "workspaces" */
export enum Workspaces_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  LandingPaneContent = 'landing_pane_content',
  /** column name */
  Layout = 'layout',
  /** column name */
  Name = 'name',
  /** column name */
  Owner = 'owner',
  /** column name */
  Permission = 'permission'
}

/** aggregate var_pop on columns */
export type Workspaces_Var_Pop_Fields = {
  __typename?: 'workspaces_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Workspaces_Var_Samp_Fields = {
  __typename?: 'workspaces_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Workspaces_Variance_Fields = {
  __typename?: 'workspaces_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type AddChartMutationVariables = Exact<{
  workspaceId: Scalars['Int'];
  symbol: Scalars['String'];
  position: Scalars['Int'];
}>;


export type AddChartMutation = (
  { __typename?: 'mutation_root' }
  & { insert_charts_one?: Maybe<(
    { __typename?: 'charts' }
    & Pick<Charts, 'id' | 'symbol' | 'workspace_id' | 'position'>
  )> }
);

export type UpdateChartSymbolMutationVariables = Exact<{
  chartId: Scalars['Int'];
  symbol: Scalars['String'];
}>;


export type UpdateChartSymbolMutation = (
  { __typename?: 'mutation_root' }
  & { update_charts_by_pk?: Maybe<(
    { __typename?: 'charts' }
    & Pick<Charts, 'symbol'>
  )> }
);

export type UpdateChartSettingsMutationVariables = Exact<{
  chartId: Scalars['Int'];
  type?: Maybe<Chart_Styles_Enum>;
  period?: Maybe<Chart_Periods_Enum>;
}>;


export type UpdateChartSettingsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_chart_settings_one?: Maybe<(
    { __typename?: 'user_chart_settings' }
    & Pick<User_Chart_Settings, 'type' | 'period'>
  )> }
);

export type FrontendChartFragment = (
  { __typename?: 'charts' }
  & Pick<Charts, 'id' | 'symbol' | 'workspace_id' | 'position'>
  & { settings: Array<(
    { __typename?: 'user_chart_settings' }
    & Pick<User_Chart_Settings, 'period' | 'type'>
  )>, notes: Array<(
    { __typename?: 'notes' }
    & Pick<Notes, 'id' | 'title' | 'time' | 'type' | 'last_modified' | 'permission' | 'published_note_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'full_name'>
    ), notes_users_notifications?: Maybe<(
      { __typename?: 'notes_users_notifications' }
      & Pick<Notes_Users_Notifications, 'read_at' | 'muted'>
    )> }
  )> }
);

export type GetChartSubscriptionVariables = Exact<{
  chartId: Scalars['Int'];
}>;


export type GetChartSubscription = (
  { __typename?: 'subscription_root' }
  & { charts_by_pk?: Maybe<(
    { __typename?: 'charts' }
    & Pick<Charts, 'symbol'>
    & { settings: Array<(
      { __typename?: 'user_chart_settings' }
      & Pick<User_Chart_Settings, 'period' | 'type'>
    )> }
  )> }
);

export type ClientChartFragment = (
  { __typename?: 'client_charts' }
  & Pick<Client_Charts, 'type' | 'symbol' | 'period'>
);

export type UpdateClientChartMutationVariables = Exact<{
  symbol: Scalars['String'];
  type?: Maybe<Chart_Styles_Enum>;
  period?: Maybe<Chart_Periods_Enum>;
}>;


export type UpdateClientChartMutation = (
  { __typename?: 'mutation_root' }
  & { insert_client_charts_one?: Maybe<(
    { __typename?: 'client_charts' }
    & ClientChartFragment
  )> }
);

export type GetClientChartSubscriptionVariables = Exact<{
  userId: Scalars['String'];
  symbol: Scalars['String'];
}>;


export type GetClientChartSubscription = (
  { __typename?: 'subscription_root' }
  & { client_charts_by_pk?: Maybe<(
    { __typename?: 'client_charts' }
    & ClientChartFragment
  )> }
);

export type DeleteClientChartMutationVariables = Exact<{
  userId: Scalars['String'];
  symbol: Scalars['String'];
}>;


export type DeleteClientChartMutation = (
  { __typename?: 'mutation_root' }
  & { delete_client_charts_by_pk?: Maybe<(
    { __typename?: 'client_charts' }
    & ClientChartFragment
  )> }
);

export type GetClientChartsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetClientChartsSubscription = (
  { __typename?: 'subscription_root' }
  & { client_charts: Array<(
    { __typename?: 'client_charts' }
    & ClientChartFragment
  )> }
);

export type GetAllNotesUsersNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllNotesUsersNotificationsQuery = (
  { __typename?: 'query_root' }
  & { notes_users_notifications: Array<(
    { __typename?: 'notes_users_notifications' }
    & NotesReadAtFragment
  )> }
);

export type GetNotesUsersNotificationsByUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetNotesUsersNotificationsByUserQuery = (
  { __typename?: 'query_root' }
  & { notes_users_notifications: Array<(
    { __typename?: 'notes_users_notifications' }
    & NotesReadAtFragment
  )> }
);

export type GetNotesUsersNotificationsByUserAndNoteQueryVariables = Exact<{
  userId: Scalars['String'];
  noteId: Scalars['Int'];
}>;


export type GetNotesUsersNotificationsByUserAndNoteQuery = (
  { __typename?: 'query_root' }
  & { notes_users_notifications: Array<(
    { __typename?: 'notes_users_notifications' }
    & NotesReadAtFragment
  )> }
);

export type NotePreviewFragment = (
  { __typename?: 'notes' }
  & Pick<Notes, 'id' | 'title' | 'chart_symbol' | 'permission' | 'created_at' | 'time' | 'type' | 'last_modified' | 'channel_id' | 'published_note_id'>
  & { user: (
    { __typename?: 'users' }
    & Pick<Users, 'full_name'>
  ), notes_users_notifications?: Maybe<(
    { __typename?: 'notes_users_notifications' }
    & Pick<Notes_Users_Notifications, 'read_at' | 'muted'>
  )> }
);

export type SubscribeToNoteSubscriptionVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SubscribeToNoteSubscription = (
  { __typename?: 'subscription_root' }
  & { note?: Maybe<(
    { __typename?: 'notes' }
    & FrontendNoteFragment
  )> }
);

export type AddNoteMutationVariables = Exact<{
  symbol: Scalars['String'];
  time: Scalars['timestamptz'];
  type?: Maybe<Note_Types_Enum>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['json']>;
  permission?: Maybe<Permissions_Enum>;
}>;


export type AddNoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_notes_one?: Maybe<(
    { __typename?: 'notes' }
    & Pick<Notes, 'id' | 'permission'>
  )> }
);

export type PublishNoteMutationVariables = Exact<{
  notePublishingInfo: NotePublishingInfo;
}>;


export type PublishNoteMutation = (
  { __typename?: 'mutation_root' }
  & { publishNote?: Maybe<(
    { __typename?: 'PublishNoteOutput' }
    & Pick<PublishNoteOutput, 'noteId'>
  )> }
);

export type UpdateNoteContentsMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
  content?: Maybe<Scalars['json']>;
}>;


export type UpdateNoteContentsMutation = (
  { __typename?: 'mutation_root' }
  & { update_notes?: Maybe<(
    { __typename?: 'notes_mutation_response' }
    & Pick<Notes_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'notes' }
      & Pick<Notes, 'id' | 'title' | 'content'>
    )> }
  )> }
);

export type UpdateNotePermissionMutationVariables = Exact<{
  id: Scalars['Int'];
  permission?: Maybe<Permissions_Enum>;
  channelId?: Maybe<Scalars['String']>;
}>;


export type UpdateNotePermissionMutation = (
  { __typename?: 'mutation_root' }
  & { update_notes?: Maybe<(
    { __typename?: 'notes_mutation_response' }
    & Pick<Notes_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'notes' }
      & Pick<Notes, 'id' | 'permission' | 'channel_id'>
    )> }
  )> }
);

export type UpdateNoteTimeMutationVariables = Exact<{
  id: Scalars['Int'];
  time: Scalars['timestamptz'];
}>;


export type UpdateNoteTimeMutation = (
  { __typename?: 'mutation_root' }
  & { update_notes?: Maybe<(
    { __typename?: 'notes_mutation_response' }
    & Pick<Notes_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'notes' }
      & Pick<Notes, 'id' | 'time'>
    )> }
  )> }
);

export type GetAllNotesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetAllNotesSubscription = (
  { __typename?: 'subscription_root' }
  & { notes: Array<(
    { __typename?: 'notes' }
    & NotePreviewFragment
  )> }
);

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteNoteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_notes?: Maybe<(
    { __typename?: 'notes_mutation_response' }
    & Pick<Notes_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'notes' }
      & Pick<Notes, 'id'>
    )> }
  )> }
);

export type SoftDeleteNoteMutationVariables = Exact<{
  id: Scalars['Int'];
  currentTime: Scalars['timestamptz'];
}>;


export type SoftDeleteNoteMutation = (
  { __typename?: 'mutation_root' }
  & { update_notes?: Maybe<(
    { __typename?: 'notes_mutation_response' }
    & Pick<Notes_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'notes' }
      & Pick<Notes, 'id'>
    )> }
  )> }
);

export type FrontendPublicNoteFragment = (
  { __typename?: 'public_notes' }
  & Pick<Public_Notes, 'content'>
  & { threads: Array<(
    { __typename?: 'message_threads' }
    & Pick<Message_Threads, 'channel_id' | 'client_id'>
  )> }
  & PublicNotePreviewFragment
);

export type PublicNotePreviewWithThreadsFragment = (
  { __typename?: 'public_notes' }
  & { threads: Array<(
    { __typename?: 'message_threads' }
    & Pick<Message_Threads, 'channel_id' | 'client_id' | 'created_at' | 'read_at'>
  )> }
  & PublicNotePreviewFragment
);

export type PublicNotePreviewFragment = (
  { __typename?: 'public_notes' }
  & Pick<Public_Notes, 'id' | 'title' | 'type' | 'chart_symbol' | 'created_at' | 'last_modified' | 'time'>
  & { user: (
    { __typename?: 'users' }
    & Pick<Users, 'full_name'>
  ), notes_users_notifications?: Maybe<(
    { __typename?: 'notes_users_notifications' }
    & Pick<Notes_Users_Notifications, 'read_at' | 'muted'>
  )> }
);

export type GetAllPublicNotesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetAllPublicNotesSubscription = (
  { __typename?: 'subscription_root' }
  & { public_notes: Array<(
    { __typename?: 'public_notes' }
    & PublicNotePreviewWithThreadsFragment
  )> }
);

export type GetClientPublicNotesByChartSubscriptionVariables = Exact<{
  clientId: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
}>;


export type GetClientPublicNotesByChartSubscription = (
  { __typename?: 'subscription_root' }
  & { message_threads: Array<(
    { __typename?: 'message_threads' }
    & { public_note: (
      { __typename?: 'public_notes' }
      & PublicNotePreviewFragment
    ) }
  )> }
);

export type GetPublicNoteForChartSubscriptionVariables = Exact<{
  clientId: Scalars['String'];
  publicNoteId: Scalars['Int'];
}>;


export type GetPublicNoteForChartSubscription = (
  { __typename?: 'subscription_root' }
  & { public_note?: Maybe<(
    { __typename?: 'public_notes' }
    & Pick<Public_Notes, 'chart_symbol'>
    & { related_notes: Array<(
      { __typename?: 'public_notes' }
      & PublicNotePreviewFragment
    )>, message_threads: Array<(
      { __typename?: 'message_threads' }
      & Pick<Message_Threads, 'id' | 'channel_id'>
    )> }
  )> }
);

export type GetClientPublicNoteQueryVariables = Exact<{
  publicNoteId: Scalars['Int'];
  clientId: Scalars['String'];
}>;


export type GetClientPublicNoteQuery = (
  { __typename?: 'query_root' }
  & { public_note?: Maybe<(
    { __typename?: 'public_notes' }
    & { message_threads: Array<(
      { __typename?: 'message_threads' }
      & Pick<Message_Threads, 'id' | 'created_at' | 'read_at' | 'channel_id'>
    )> }
    & FrontendPublicNoteFragment
  )> }
);

export type GetUnreadPublicNotesCountSubscriptionVariables = Exact<{
  clientId?: Maybe<Scalars['String']>;
}>;


export type GetUnreadPublicNotesCountSubscription = (
  { __typename?: 'subscription_root' }
  & { message_threads_aggregate: (
    { __typename?: 'message_threads_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'message_threads_aggregate_fields' }
      & Pick<Message_Threads_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetFinancialDataQueryVariables = Exact<{
  symbol: Scalars['String'];
  period: QueryPeriods;
}>;


export type GetFinancialDataQuery = (
  { __typename?: 'query_root' }
  & { getFinancialData?: Maybe<(
    { __typename?: 'FinancialData' }
    & Pick<FinancialData, 'symbol'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'IOHLCData' }
      & Pick<IohlcData, 'close' | 'date' | 'high' | 'low' | 'open' | 'volume'>
    )>>> }
  )> }
);

export type GetSignedUrlQueryVariables = Exact<{
  fileName: Scalars['String'];
  fileType: Scalars['String'];
}>;


export type GetSignedUrlQuery = (
  { __typename?: 'query_root' }
  & { getSignedUrl?: Maybe<(
    { __typename?: 'SignedUrl' }
    & Pick<SignedUrl, 'url' | 'signedUrl'>
  )> }
);

export type GetAlgoliaApiKeyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAlgoliaApiKeyQuery = (
  { __typename?: 'query_root' }
  & { getAlgoliaApiKey?: Maybe<(
    { __typename?: 'AlgoliaApiKey' }
    & Pick<AlgoliaApiKey, 'apiKey'>
  )> }
);

export type MessageThreadFragment = (
  { __typename?: 'message_threads' }
  & Pick<Message_Threads, 'id' | 'channel_id' | 'client_id'>
  & { public_note: (
    { __typename?: 'public_notes' }
    & PublicNotePreviewFragment
  ) }
);

export type ClientWithNotesFragment = (
  { __typename?: 'clients' }
  & { team_contacts: Array<(
    { __typename?: 'team_contacts' }
    & Pick<Team_Contacts, 'team_id' | 'channel_id'>
  )>, user: (
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'full_name'>
    & { message_threads: Array<(
      { __typename?: 'message_threads' }
      & MessageThreadFragment
    )> }
  ) }
);

export type GetClientWithNotesSubscriptionVariables = Exact<{
  clientId: Scalars['String'];
}>;


export type GetClientWithNotesSubscription = (
  { __typename?: 'subscription_root' }
  & { client?: Maybe<(
    { __typename?: 'clients' }
    & ClientWithNotesFragment
  )> }
);

export type SetUserLastSeenMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type SetUserLastSeenMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'last_seen'>
  )> }
);

export type FrontendWorkspaceFragment = (
  { __typename?: 'workspaces' }
  & Pick<Workspaces, 'id' | 'owner' | 'name' | 'layout' | 'landing_pane_content'>
  & { charts: Array<(
    { __typename?: 'charts' }
    & FrontendChartFragment
  )> }
);

export type GetWorkspacesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkspacesQuery = (
  { __typename?: 'query_root' }
  & { workspaces: Array<(
    { __typename?: 'workspaces' }
    & Pick<Workspaces, 'id' | 'name' | 'icon' | 'permission' | 'landing_pane_content'>
  )> }
);

export type AddWorkspaceMutationVariables = Exact<{
  name: Scalars['String'];
  icon: Scalars['String'];
  permission: Permissions_Enum;
}>;


export type AddWorkspaceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_workspaces?: Maybe<(
    { __typename?: 'workspaces_mutation_response' }
    & Pick<Workspaces_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'workspaces' }
      & Pick<Workspaces, 'id' | 'name' | 'icon' | 'permission' | 'landing_pane_content'>
    )> }
  )> }
);

export type GetWorkspaceSubscriptionVariables = Exact<{
  workspaceId: Scalars['Int'];
}>;


export type GetWorkspaceSubscription = (
  { __typename?: 'subscription_root' }
  & { workspaces?: Maybe<(
    { __typename?: 'workspaces' }
    & FrontendWorkspaceFragment
  )> }
);

export type UpdateWorkspaceLayoutMutationVariables = Exact<{
  workspaceId: Scalars['Int'];
  layout: Layouts_Enum;
}>;


export type UpdateWorkspaceLayoutMutation = (
  { __typename?: 'mutation_root' }
  & { update_workspaces?: Maybe<(
    { __typename?: 'workspaces_mutation_response' }
    & Pick<Workspaces_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'workspaces' }
      & Pick<Workspaces, 'id' | 'layout'>
    )> }
  )> }
);

export type UpdateWorkspaceLandingPaneContentsMutationVariables = Exact<{
  id: Scalars['Int'];
  landing_pane_content?: Maybe<Scalars['json']>;
}>;


export type UpdateWorkspaceLandingPaneContentsMutation = (
  { __typename?: 'mutation_root' }
  & { update_workspaces?: Maybe<(
    { __typename?: 'workspaces_mutation_response' }
    & Pick<Workspaces_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'workspaces' }
      & Pick<Workspaces, 'id' | 'landing_pane_content'>
    )> }
  )> }
);

export type DeleteWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['Int'];
}>;


export type DeleteWorkspaceMutation = (
  { __typename?: 'mutation_root' }
  & { delete_workspaces?: Maybe<(
    { __typename?: 'workspaces_mutation_response' }
    & Pick<Workspaces_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'workspaces' }
      & Pick<Workspaces, 'id'>
    )> }
  )> }
);

export type FrontendClientFragment = (
  { __typename?: 'clients' }
  & Pick<Clients, 'user_id'>
  & { company: (
    { __typename?: 'client_companies' }
    & Pick<Client_Companies, 'name'>
  ), user: (
    { __typename?: 'users' }
    & Pick<Users, 'full_name' | 'email'>
    & { is_online?: Maybe<(
      { __typename?: 'users_online' }
      & Pick<Users_Online, 'id'>
    )> }
  ) }
);

export type AddMessageThreadsMutationVariables = Exact<{
  threads: Array<Message_Threads_Insert_Input>;
}>;


export type AddMessageThreadsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_message_threads?: Maybe<(
    { __typename?: 'message_threads_mutation_response' }
    & { returning: Array<(
      { __typename?: 'message_threads' }
      & Pick<Message_Threads, 'id' | 'client_id' | 'channel_id'>
    )> }
  )> }
);

export type MarkMessageThreadAsReadMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MarkMessageThreadAsReadMutation = (
  { __typename?: 'mutation_root' }
  & { update_message_threads_by_pk?: Maybe<(
    { __typename?: 'message_threads' }
    & Pick<Message_Threads, 'id' | 'read_at'>
  )> }
);

export type NotesReadAtFragment = (
  { __typename?: 'notes_users_notifications' }
  & Pick<Notes_Users_Notifications, 'note_id' | 'read_at' | 'user_id' | 'muted'>
);

export type UpsertNotificationAsReadMutationVariables = Exact<{
  noteId: Scalars['Int'];
  userId: Scalars['String'];
}>;


export type UpsertNotificationAsReadMutation = (
  { __typename?: 'mutation_root' }
  & { insert_notes_users_notifications?: Maybe<(
    { __typename?: 'notes_users_notifications_mutation_response' }
    & { returning: Array<(
      { __typename?: 'notes_users_notifications' }
      & NotesReadAtFragment
    )> }
  )> }
);

export type DeleteNoteUserNotificationByUserMutationVariables = Exact<{
  noteId: Scalars['Int'];
  userId: Scalars['String'];
}>;


export type DeleteNoteUserNotificationByUserMutation = (
  { __typename?: 'mutation_root' }
  & { delete_notes_users_notifications?: Maybe<(
    { __typename?: 'notes_users_notifications_mutation_response' }
    & Pick<Notes_Users_Notifications_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'notes_users_notifications' }
      & Pick<Notes_Users_Notifications, 'id'>
    )> }
  )> }
);

export type DeleteNoteUserNotificationMutationVariables = Exact<{
  noteId: Scalars['Int'];
}>;


export type DeleteNoteUserNotificationMutation = (
  { __typename?: 'mutation_root' }
  & { delete_notes_users_notifications?: Maybe<(
    { __typename?: 'notes_users_notifications_mutation_response' }
    & Pick<Notes_Users_Notifications_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'notes_users_notifications' }
      & Pick<Notes_Users_Notifications, 'id'>
    )> }
  )> }
);

export type FrontendNoteFragment = (
  { __typename?: 'notes' }
  & Pick<Notes, 'id' | 'title' | 'type' | 'content' | 'permission' | 'last_modified' | 'channel_id' | 'published_note_id' | 'chart_symbol' | 'time' | 'owner'>
  & { published_note: (
    { __typename?: 'public_notes' }
    & Pick<Public_Notes, 'last_modified'>
    & { message_threads: Array<(
      { __typename?: 'message_threads' }
      & FrontendMessageThreadFragment
    )> }
  ), notes_users_notifications?: Maybe<(
    { __typename?: 'notes_users_notifications' }
    & Pick<Notes_Users_Notifications, 'read_at' | 'muted'>
  )> }
);

export type FrontendMessageThreadFragment = (
  { __typename?: 'message_threads' }
  & Pick<Message_Threads, 'id'>
  & { client: (
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'full_name'>
    & { client_user?: Maybe<(
      { __typename?: 'clients' }
      & { company: (
        { __typename?: 'client_companies' }
        & Pick<Client_Companies, 'name'>
      ) }
    )> }
  ) }
);

export type GetNoteQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetNoteQuery = (
  { __typename?: 'query_root' }
  & { note?: Maybe<(
    { __typename?: 'notes' }
    & FrontendNoteFragment
  )> }
);

export type UnpublishNoteMutationVariables = Exact<{
  noteId: Scalars['Int'];
}>;


export type UnpublishNoteMutation = (
  { __typename?: 'mutation_root' }
  & { unpublishNote?: Maybe<(
    { __typename?: 'UnpublishNoteOutput' }
    & Pick<UnpublishNoteOutput, 'noteId'>
  )> }
);

export type TeamClientFragment = (
  { __typename?: 'team_contacts' }
  & Pick<Team_Contacts, 'channel_id'>
  & { client: (
    { __typename?: 'clients' }
    & FrontendClientFragment
  ) }
);

export type GetTeamClientsQueryVariables = Exact<{
  teamId: Scalars['Int'];
}>;


export type GetTeamClientsQuery = (
  { __typename?: 'query_root' }
  & { team_contacts: Array<(
    { __typename?: 'team_contacts' }
    & TeamClientFragment
  )> }
);

export type GetTeamClientsLiveSubscriptionVariables = Exact<{
  teamId: Scalars['Int'];
}>;


export type GetTeamClientsLiveSubscription = (
  { __typename?: 'subscription_root' }
  & { team_contacts: Array<(
    { __typename?: 'team_contacts' }
    & TeamClientFragment
  )> }
);

export type FrontendUserFragment = (
  { __typename?: 'users' }
  & Pick<Users, 'id' | 'first_name' | 'last_name' | 'full_name' | 'email'>
  & { outline_user?: Maybe<(
    { __typename?: 'outline_users' }
    & Pick<Outline_Users, 'organization_id' | 'team_id'>
    & { team: (
      { __typename?: 'teams' }
      & { contacts: Array<(
        { __typename?: 'team_contacts' }
        & { client: (
          { __typename?: 'clients' }
          & { user: (
            { __typename?: 'users' }
            & Pick<Users, 'full_name' | 'id'>
          ) }
        ) }
      )>, members: Array<(
        { __typename?: 'outline_users' }
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'full_name'>
        ) }
      )> }
    ) }
  )>, client_user?: Maybe<(
    { __typename?: 'clients' }
    & { company: (
      { __typename?: 'client_companies' }
      & Pick<Client_Companies, 'name'>
    ), team_contacts: Array<(
      { __typename?: 'team_contacts' }
      & Pick<Team_Contacts, 'team_id' | 'channel_id'>
    )> }
  )> }
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'query_root' }
  & { user?: Maybe<(
    { __typename?: 'users' }
    & FrontendUserFragment
  )> }
);

export const ClientChartFragmentDoc = gql`
    fragment ClientChart on client_charts {
  type
  symbol
  period
}
    `;
export const NotePreviewFragmentDoc = gql`
    fragment NotePreview on notes {
  id
  title
  chart_symbol
  permission
  created_at
  time
  type
  last_modified
  channel_id
  published_note_id
  user {
    full_name
  }
  notes_users_notifications {
    read_at
    muted
  }
}
    `;
export const PublicNotePreviewFragmentDoc = gql`
    fragment PublicNotePreview on public_notes {
  id
  title
  type
  chart_symbol
  created_at
  last_modified
  time
  user {
    full_name
  }
  notes_users_notifications {
    read_at
    muted
  }
}
    `;
export const FrontendPublicNoteFragmentDoc = gql`
    fragment FrontendPublicNote on public_notes {
  ...PublicNotePreview
  threads: message_threads {
    channel_id
    client_id
  }
  content
}
    ${PublicNotePreviewFragmentDoc}`;
export const PublicNotePreviewWithThreadsFragmentDoc = gql`
    fragment PublicNotePreviewWithThreads on public_notes {
  ...PublicNotePreview
  threads: message_threads {
    channel_id
    client_id
    created_at
    read_at
  }
}
    ${PublicNotePreviewFragmentDoc}`;
export const MessageThreadFragmentDoc = gql`
    fragment MessageThread on message_threads {
  id
  channel_id
  client_id
  public_note {
    ...PublicNotePreview
  }
}
    ${PublicNotePreviewFragmentDoc}`;
export const ClientWithNotesFragmentDoc = gql`
    fragment ClientWithNotes on clients {
  team_contacts {
    team_id
    channel_id
  }
  user {
    id
    full_name
    message_threads {
      ...MessageThread
    }
  }
}
    ${MessageThreadFragmentDoc}`;
export const FrontendChartFragmentDoc = gql`
    fragment FrontendChart on charts {
  id
  symbol
  workspace_id
  position
  settings {
    period
    type
  }
  notes(where: {permission: {_neq: public}}) {
    id
    title
    time
    type
    last_modified
    permission
    published_note_id
    user {
      full_name
    }
    notes_users_notifications {
      read_at
      muted
    }
  }
}
    `;
export const FrontendWorkspaceFragmentDoc = gql`
    fragment FrontendWorkspace on workspaces {
  id
  owner
  name
  layout
  landing_pane_content
  charts {
    ...FrontendChart
  }
}
    ${FrontendChartFragmentDoc}`;
export const NotesReadAtFragmentDoc = gql`
    fragment NotesReadAt on notes_users_notifications {
  note_id
  read_at
  user_id
  muted
}
    `;
export const FrontendMessageThreadFragmentDoc = gql`
    fragment FrontendMessageThread on message_threads {
  id
  client {
    id
    full_name
    client_user {
      company {
        name
      }
    }
  }
}
    `;
export const FrontendNoteFragmentDoc = gql`
    fragment FrontendNote on notes {
  id
  title
  type
  content
  permission
  last_modified
  channel_id
  published_note {
    last_modified
    message_threads {
      ...FrontendMessageThread
    }
  }
  notes_users_notifications {
    read_at
    muted
  }
  published_note_id
  chart_symbol
  time
  owner
}
    ${FrontendMessageThreadFragmentDoc}`;
export const FrontendClientFragmentDoc = gql`
    fragment FrontendClient on clients {
  user_id
  company {
    name
  }
  user {
    full_name
    email
    is_online {
      id
    }
  }
}
    `;
export const TeamClientFragmentDoc = gql`
    fragment TeamClient on team_contacts {
  channel_id
  client {
    ...FrontendClient
  }
}
    ${FrontendClientFragmentDoc}`;
export const FrontendUserFragmentDoc = gql`
    fragment FrontendUser on users {
  id
  first_name
  last_name
  full_name
  email
  outline_user {
    organization_id
    team_id
    team {
      contacts {
        client {
          user {
            full_name
            id
          }
        }
      }
      members {
        user {
          id
          full_name
        }
      }
    }
  }
  client_user {
    company {
      name
    }
    team_contacts {
      team_id
      channel_id
    }
  }
}
    `;
export const AddChartDocument = gql`
    mutation addChart($workspaceId: Int!, $symbol: String!, $position: Int!) {
  insert_charts_one(object: {symbol: $symbol, workspace_id: $workspaceId, position: $position}, on_conflict: {constraint: charts_workspace_id_position_key, update_columns: [symbol]}) {
    id
    symbol
    workspace_id
    position
  }
}
    `;
export type AddChartMutationFn = ApolloReactCommon.MutationFunction<AddChartMutation, AddChartMutationVariables>;

/**
 * __useAddChartMutation__
 *
 * To run a mutation, you first call `useAddChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChartMutation, { data, loading, error }] = useAddChartMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      symbol: // value for 'symbol'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useAddChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddChartMutation, AddChartMutationVariables>) {
        return ApolloReactHooks.useMutation<AddChartMutation, AddChartMutationVariables>(AddChartDocument, baseOptions);
      }
export type AddChartMutationHookResult = ReturnType<typeof useAddChartMutation>;
export type AddChartMutationResult = ApolloReactCommon.MutationResult<AddChartMutation>;
export type AddChartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddChartMutation, AddChartMutationVariables>;
export const UpdateChartSymbolDocument = gql`
    mutation updateChartSymbol($chartId: Int!, $symbol: String!) {
  update_charts_by_pk(pk_columns: {id: $chartId}, _set: {symbol: $symbol}) {
    symbol
  }
}
    `;
export type UpdateChartSymbolMutationFn = ApolloReactCommon.MutationFunction<UpdateChartSymbolMutation, UpdateChartSymbolMutationVariables>;

/**
 * __useUpdateChartSymbolMutation__
 *
 * To run a mutation, you first call `useUpdateChartSymbolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChartSymbolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChartSymbolMutation, { data, loading, error }] = useUpdateChartSymbolMutation({
 *   variables: {
 *      chartId: // value for 'chartId'
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useUpdateChartSymbolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateChartSymbolMutation, UpdateChartSymbolMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateChartSymbolMutation, UpdateChartSymbolMutationVariables>(UpdateChartSymbolDocument, baseOptions);
      }
export type UpdateChartSymbolMutationHookResult = ReturnType<typeof useUpdateChartSymbolMutation>;
export type UpdateChartSymbolMutationResult = ApolloReactCommon.MutationResult<UpdateChartSymbolMutation>;
export type UpdateChartSymbolMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateChartSymbolMutation, UpdateChartSymbolMutationVariables>;
export const UpdateChartSettingsDocument = gql`
    mutation updateChartSettings($chartId: Int!, $type: chart_styles_enum, $period: chart_periods_enum) {
  insert_user_chart_settings_one(object: {type: $type, period: $period, chart_id: $chartId}, on_conflict: {constraint: user_chart_settings_pkey, update_columns: [type, period]}) {
    type
    period
  }
}
    `;
export type UpdateChartSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateChartSettingsMutation, UpdateChartSettingsMutationVariables>;

/**
 * __useUpdateChartSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateChartSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChartSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChartSettingsMutation, { data, loading, error }] = useUpdateChartSettingsMutation({
 *   variables: {
 *      chartId: // value for 'chartId'
 *      type: // value for 'type'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useUpdateChartSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateChartSettingsMutation, UpdateChartSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateChartSettingsMutation, UpdateChartSettingsMutationVariables>(UpdateChartSettingsDocument, baseOptions);
      }
export type UpdateChartSettingsMutationHookResult = ReturnType<typeof useUpdateChartSettingsMutation>;
export type UpdateChartSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateChartSettingsMutation>;
export type UpdateChartSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateChartSettingsMutation, UpdateChartSettingsMutationVariables>;
export const GetChartDocument = gql`
    subscription getChart($chartId: Int!) {
  charts_by_pk(id: $chartId) {
    symbol
    settings {
      period
      type
    }
  }
}
    `;

/**
 * __useGetChartSubscription__
 *
 * To run a query within a React component, call `useGetChartSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetChartSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartSubscription({
 *   variables: {
 *      chartId: // value for 'chartId'
 *   },
 * });
 */
export function useGetChartSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetChartSubscription, GetChartSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetChartSubscription, GetChartSubscriptionVariables>(GetChartDocument, baseOptions);
      }
export type GetChartSubscriptionHookResult = ReturnType<typeof useGetChartSubscription>;
export type GetChartSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetChartSubscription>;
export const UpdateClientChartDocument = gql`
    mutation updateClientChart($symbol: String!, $type: chart_styles_enum, $period: chart_periods_enum) {
  insert_client_charts_one(object: {type: $type, period: $period, symbol: $symbol}, on_conflict: {constraint: client_charts_pkey, update_columns: [type, period]}) {
    ...ClientChart
  }
}
    ${ClientChartFragmentDoc}`;
export type UpdateClientChartMutationFn = ApolloReactCommon.MutationFunction<UpdateClientChartMutation, UpdateClientChartMutationVariables>;

/**
 * __useUpdateClientChartMutation__
 *
 * To run a mutation, you first call `useUpdateClientChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientChartMutation, { data, loading, error }] = useUpdateClientChartMutation({
 *   variables: {
 *      symbol: // value for 'symbol'
 *      type: // value for 'type'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useUpdateClientChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientChartMutation, UpdateClientChartMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientChartMutation, UpdateClientChartMutationVariables>(UpdateClientChartDocument, baseOptions);
      }
export type UpdateClientChartMutationHookResult = ReturnType<typeof useUpdateClientChartMutation>;
export type UpdateClientChartMutationResult = ApolloReactCommon.MutationResult<UpdateClientChartMutation>;
export type UpdateClientChartMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientChartMutation, UpdateClientChartMutationVariables>;
export const GetClientChartDocument = gql`
    subscription getClientChart($userId: String!, $symbol: String!) {
  client_charts_by_pk(client_id: $userId, symbol: $symbol) {
    ...ClientChart
  }
}
    ${ClientChartFragmentDoc}`;

/**
 * __useGetClientChartSubscription__
 *
 * To run a query within a React component, call `useGetClientChartSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetClientChartSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientChartSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useGetClientChartSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetClientChartSubscription, GetClientChartSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetClientChartSubscription, GetClientChartSubscriptionVariables>(GetClientChartDocument, baseOptions);
      }
export type GetClientChartSubscriptionHookResult = ReturnType<typeof useGetClientChartSubscription>;
export type GetClientChartSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetClientChartSubscription>;
export const DeleteClientChartDocument = gql`
    mutation deleteClientChart($userId: String!, $symbol: String!) {
  delete_client_charts_by_pk(client_id: $userId, symbol: $symbol) {
    ...ClientChart
  }
}
    ${ClientChartFragmentDoc}`;
export type DeleteClientChartMutationFn = ApolloReactCommon.MutationFunction<DeleteClientChartMutation, DeleteClientChartMutationVariables>;

/**
 * __useDeleteClientChartMutation__
 *
 * To run a mutation, you first call `useDeleteClientChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientChartMutation, { data, loading, error }] = useDeleteClientChartMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useDeleteClientChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteClientChartMutation, DeleteClientChartMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteClientChartMutation, DeleteClientChartMutationVariables>(DeleteClientChartDocument, baseOptions);
      }
export type DeleteClientChartMutationHookResult = ReturnType<typeof useDeleteClientChartMutation>;
export type DeleteClientChartMutationResult = ApolloReactCommon.MutationResult<DeleteClientChartMutation>;
export type DeleteClientChartMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteClientChartMutation, DeleteClientChartMutationVariables>;
export const GetClientChartsDocument = gql`
    subscription getClientCharts {
  client_charts {
    ...ClientChart
  }
}
    ${ClientChartFragmentDoc}`;

/**
 * __useGetClientChartsSubscription__
 *
 * To run a query within a React component, call `useGetClientChartsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetClientChartsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientChartsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetClientChartsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetClientChartsSubscription, GetClientChartsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetClientChartsSubscription, GetClientChartsSubscriptionVariables>(GetClientChartsDocument, baseOptions);
      }
export type GetClientChartsSubscriptionHookResult = ReturnType<typeof useGetClientChartsSubscription>;
export type GetClientChartsSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetClientChartsSubscription>;
export const GetAllNotesUsersNotificationsDocument = gql`
    query getAllNotesUsersNotifications {
  notes_users_notifications {
    ...NotesReadAt
  }
}
    ${NotesReadAtFragmentDoc}`;

/**
 * __useGetAllNotesUsersNotificationsQuery__
 *
 * To run a query within a React component, call `useGetAllNotesUsersNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotesUsersNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNotesUsersNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllNotesUsersNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllNotesUsersNotificationsQuery, GetAllNotesUsersNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllNotesUsersNotificationsQuery, GetAllNotesUsersNotificationsQueryVariables>(GetAllNotesUsersNotificationsDocument, baseOptions);
      }
export function useGetAllNotesUsersNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllNotesUsersNotificationsQuery, GetAllNotesUsersNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllNotesUsersNotificationsQuery, GetAllNotesUsersNotificationsQueryVariables>(GetAllNotesUsersNotificationsDocument, baseOptions);
        }
export type GetAllNotesUsersNotificationsQueryHookResult = ReturnType<typeof useGetAllNotesUsersNotificationsQuery>;
export type GetAllNotesUsersNotificationsLazyQueryHookResult = ReturnType<typeof useGetAllNotesUsersNotificationsLazyQuery>;
export type GetAllNotesUsersNotificationsQueryResult = ApolloReactCommon.QueryResult<GetAllNotesUsersNotificationsQuery, GetAllNotesUsersNotificationsQueryVariables>;
export const GetNotesUsersNotificationsByUserDocument = gql`
    query getNotesUsersNotificationsByUser($userId: String!) {
  notes_users_notifications(where: {user_id: {_eq: $userId}}) {
    ...NotesReadAt
  }
}
    ${NotesReadAtFragmentDoc}`;

/**
 * __useGetNotesUsersNotificationsByUserQuery__
 *
 * To run a query within a React component, call `useGetNotesUsersNotificationsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesUsersNotificationsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesUsersNotificationsByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNotesUsersNotificationsByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotesUsersNotificationsByUserQuery, GetNotesUsersNotificationsByUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotesUsersNotificationsByUserQuery, GetNotesUsersNotificationsByUserQueryVariables>(GetNotesUsersNotificationsByUserDocument, baseOptions);
      }
export function useGetNotesUsersNotificationsByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotesUsersNotificationsByUserQuery, GetNotesUsersNotificationsByUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotesUsersNotificationsByUserQuery, GetNotesUsersNotificationsByUserQueryVariables>(GetNotesUsersNotificationsByUserDocument, baseOptions);
        }
export type GetNotesUsersNotificationsByUserQueryHookResult = ReturnType<typeof useGetNotesUsersNotificationsByUserQuery>;
export type GetNotesUsersNotificationsByUserLazyQueryHookResult = ReturnType<typeof useGetNotesUsersNotificationsByUserLazyQuery>;
export type GetNotesUsersNotificationsByUserQueryResult = ApolloReactCommon.QueryResult<GetNotesUsersNotificationsByUserQuery, GetNotesUsersNotificationsByUserQueryVariables>;
export const GetNotesUsersNotificationsByUserAndNoteDocument = gql`
    query getNotesUsersNotificationsByUserAndNote($userId: String!, $noteId: Int!) {
  notes_users_notifications(where: {user_id: {_eq: $userId}, note_id: {_eq: $noteId}}) {
    ...NotesReadAt
  }
}
    ${NotesReadAtFragmentDoc}`;

/**
 * __useGetNotesUsersNotificationsByUserAndNoteQuery__
 *
 * To run a query within a React component, call `useGetNotesUsersNotificationsByUserAndNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesUsersNotificationsByUserAndNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesUsersNotificationsByUserAndNoteQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useGetNotesUsersNotificationsByUserAndNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotesUsersNotificationsByUserAndNoteQuery, GetNotesUsersNotificationsByUserAndNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotesUsersNotificationsByUserAndNoteQuery, GetNotesUsersNotificationsByUserAndNoteQueryVariables>(GetNotesUsersNotificationsByUserAndNoteDocument, baseOptions);
      }
export function useGetNotesUsersNotificationsByUserAndNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotesUsersNotificationsByUserAndNoteQuery, GetNotesUsersNotificationsByUserAndNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotesUsersNotificationsByUserAndNoteQuery, GetNotesUsersNotificationsByUserAndNoteQueryVariables>(GetNotesUsersNotificationsByUserAndNoteDocument, baseOptions);
        }
export type GetNotesUsersNotificationsByUserAndNoteQueryHookResult = ReturnType<typeof useGetNotesUsersNotificationsByUserAndNoteQuery>;
export type GetNotesUsersNotificationsByUserAndNoteLazyQueryHookResult = ReturnType<typeof useGetNotesUsersNotificationsByUserAndNoteLazyQuery>;
export type GetNotesUsersNotificationsByUserAndNoteQueryResult = ApolloReactCommon.QueryResult<GetNotesUsersNotificationsByUserAndNoteQuery, GetNotesUsersNotificationsByUserAndNoteQueryVariables>;
export const SubscribeToNoteDocument = gql`
    subscription subscribeToNote($id: Int!) {
  note: notes_by_pk(id: $id) {
    ...FrontendNote
  }
}
    ${FrontendNoteFragmentDoc}`;

/**
 * __useSubscribeToNoteSubscription__
 *
 * To run a query within a React component, call `useSubscribeToNoteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNoteSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToNoteSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeToNoteSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<SubscribeToNoteSubscription, SubscribeToNoteSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<SubscribeToNoteSubscription, SubscribeToNoteSubscriptionVariables>(SubscribeToNoteDocument, baseOptions);
      }
export type SubscribeToNoteSubscriptionHookResult = ReturnType<typeof useSubscribeToNoteSubscription>;
export type SubscribeToNoteSubscriptionResult = ApolloReactCommon.SubscriptionResult<SubscribeToNoteSubscription>;
export const AddNoteDocument = gql`
    mutation addNote($symbol: String!, $time: timestamptz!, $type: note_types_enum = note, $title: String, $content: json, $permission: permissions_enum) {
  insert_notes_one(object: {chart_symbol: $symbol, time: $time, type: $type, title: $title, content: $content, permission: $permission}) {
    id
    permission
  }
}
    `;
export type AddNoteMutationFn = ApolloReactCommon.MutationFunction<AddNoteMutation, AddNoteMutationVariables>;

/**
 * __useAddNoteMutation__
 *
 * To run a mutation, you first call `useAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteMutation, { data, loading, error }] = useAddNoteMutation({
 *   variables: {
 *      symbol: // value for 'symbol'
 *      time: // value for 'time'
 *      type: // value for 'type'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useAddNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddNoteMutation, AddNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddNoteMutation, AddNoteMutationVariables>(AddNoteDocument, baseOptions);
      }
export type AddNoteMutationHookResult = ReturnType<typeof useAddNoteMutation>;
export type AddNoteMutationResult = ApolloReactCommon.MutationResult<AddNoteMutation>;
export type AddNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddNoteMutation, AddNoteMutationVariables>;
export const PublishNoteDocument = gql`
    mutation publishNote($notePublishingInfo: NotePublishingInfo!) {
  publishNote(notePublishingInfo: $notePublishingInfo) {
    noteId
  }
}
    `;
export type PublishNoteMutationFn = ApolloReactCommon.MutationFunction<PublishNoteMutation, PublishNoteMutationVariables>;

/**
 * __usePublishNoteMutation__
 *
 * To run a mutation, you first call `usePublishNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishNoteMutation, { data, loading, error }] = usePublishNoteMutation({
 *   variables: {
 *      notePublishingInfo: // value for 'notePublishingInfo'
 *   },
 * });
 */
export function usePublishNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishNoteMutation, PublishNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishNoteMutation, PublishNoteMutationVariables>(PublishNoteDocument, baseOptions);
      }
export type PublishNoteMutationHookResult = ReturnType<typeof usePublishNoteMutation>;
export type PublishNoteMutationResult = ApolloReactCommon.MutationResult<PublishNoteMutation>;
export type PublishNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishNoteMutation, PublishNoteMutationVariables>;
export const UpdateNoteContentsDocument = gql`
    mutation updateNoteContents($id: Int!, $title: String!, $content: json) {
  update_notes(where: {id: {_eq: $id}}, _set: {title: $title, content: $content}) {
    affected_rows
    returning {
      id
      title
      content
    }
  }
}
    `;
export type UpdateNoteContentsMutationFn = ApolloReactCommon.MutationFunction<UpdateNoteContentsMutation, UpdateNoteContentsMutationVariables>;

/**
 * __useUpdateNoteContentsMutation__
 *
 * To run a mutation, you first call `useUpdateNoteContentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteContentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteContentsMutation, { data, loading, error }] = useUpdateNoteContentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateNoteContentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNoteContentsMutation, UpdateNoteContentsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNoteContentsMutation, UpdateNoteContentsMutationVariables>(UpdateNoteContentsDocument, baseOptions);
      }
export type UpdateNoteContentsMutationHookResult = ReturnType<typeof useUpdateNoteContentsMutation>;
export type UpdateNoteContentsMutationResult = ApolloReactCommon.MutationResult<UpdateNoteContentsMutation>;
export type UpdateNoteContentsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNoteContentsMutation, UpdateNoteContentsMutationVariables>;
export const UpdateNotePermissionDocument = gql`
    mutation updateNotePermission($id: Int!, $permission: permissions_enum, $channelId: String) {
  update_notes(where: {id: {_eq: $id}}, _set: {permission: $permission, channel_id: $channelId}) {
    affected_rows
    returning {
      id
      permission
      channel_id
    }
  }
}
    `;
export type UpdateNotePermissionMutationFn = ApolloReactCommon.MutationFunction<UpdateNotePermissionMutation, UpdateNotePermissionMutationVariables>;

/**
 * __useUpdateNotePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateNotePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotePermissionMutation, { data, loading, error }] = useUpdateNotePermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      permission: // value for 'permission'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useUpdateNotePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotePermissionMutation, UpdateNotePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNotePermissionMutation, UpdateNotePermissionMutationVariables>(UpdateNotePermissionDocument, baseOptions);
      }
export type UpdateNotePermissionMutationHookResult = ReturnType<typeof useUpdateNotePermissionMutation>;
export type UpdateNotePermissionMutationResult = ApolloReactCommon.MutationResult<UpdateNotePermissionMutation>;
export type UpdateNotePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotePermissionMutation, UpdateNotePermissionMutationVariables>;
export const UpdateNoteTimeDocument = gql`
    mutation updateNoteTime($id: Int!, $time: timestamptz!) {
  update_notes(where: {id: {_eq: $id}}, _set: {time: $time}) {
    affected_rows
    returning {
      id
      time
    }
  }
}
    `;
export type UpdateNoteTimeMutationFn = ApolloReactCommon.MutationFunction<UpdateNoteTimeMutation, UpdateNoteTimeMutationVariables>;

/**
 * __useUpdateNoteTimeMutation__
 *
 * To run a mutation, you first call `useUpdateNoteTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteTimeMutation, { data, loading, error }] = useUpdateNoteTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useUpdateNoteTimeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNoteTimeMutation, UpdateNoteTimeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNoteTimeMutation, UpdateNoteTimeMutationVariables>(UpdateNoteTimeDocument, baseOptions);
      }
export type UpdateNoteTimeMutationHookResult = ReturnType<typeof useUpdateNoteTimeMutation>;
export type UpdateNoteTimeMutationResult = ApolloReactCommon.MutationResult<UpdateNoteTimeMutation>;
export type UpdateNoteTimeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNoteTimeMutation, UpdateNoteTimeMutationVariables>;
export const GetAllNotesDocument = gql`
    subscription getAllNotes {
  notes {
    ...NotePreview
  }
}
    ${NotePreviewFragmentDoc}`;

/**
 * __useGetAllNotesSubscription__
 *
 * To run a query within a React component, call `useGetAllNotesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNotesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetAllNotesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetAllNotesSubscription, GetAllNotesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetAllNotesSubscription, GetAllNotesSubscriptionVariables>(GetAllNotesDocument, baseOptions);
      }
export type GetAllNotesSubscriptionHookResult = ReturnType<typeof useGetAllNotesSubscription>;
export type GetAllNotesSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetAllNotesSubscription>;
export const DeleteNoteDocument = gql`
    mutation deleteNote($id: Int!) {
  delete_notes(where: {id: {_eq: $id}}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type DeleteNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, baseOptions);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = ApolloReactCommon.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const SoftDeleteNoteDocument = gql`
    mutation softDeleteNote($id: Int!, $currentTime: timestamptz!) {
  update_notes(where: {id: {_eq: $id}}, _set: {deleted_at: $currentTime}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type SoftDeleteNoteMutationFn = ApolloReactCommon.MutationFunction<SoftDeleteNoteMutation, SoftDeleteNoteMutationVariables>;

/**
 * __useSoftDeleteNoteMutation__
 *
 * To run a mutation, you first call `useSoftDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteNoteMutation, { data, loading, error }] = useSoftDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currentTime: // value for 'currentTime'
 *   },
 * });
 */
export function useSoftDeleteNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SoftDeleteNoteMutation, SoftDeleteNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<SoftDeleteNoteMutation, SoftDeleteNoteMutationVariables>(SoftDeleteNoteDocument, baseOptions);
      }
export type SoftDeleteNoteMutationHookResult = ReturnType<typeof useSoftDeleteNoteMutation>;
export type SoftDeleteNoteMutationResult = ApolloReactCommon.MutationResult<SoftDeleteNoteMutation>;
export type SoftDeleteNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<SoftDeleteNoteMutation, SoftDeleteNoteMutationVariables>;
export const GetAllPublicNotesDocument = gql`
    subscription getAllPublicNotes {
  public_notes {
    ...PublicNotePreviewWithThreads
  }
}
    ${PublicNotePreviewWithThreadsFragmentDoc}`;

/**
 * __useGetAllPublicNotesSubscription__
 *
 * To run a query within a React component, call `useGetAllPublicNotesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPublicNotesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPublicNotesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPublicNotesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetAllPublicNotesSubscription, GetAllPublicNotesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetAllPublicNotesSubscription, GetAllPublicNotesSubscriptionVariables>(GetAllPublicNotesDocument, baseOptions);
      }
export type GetAllPublicNotesSubscriptionHookResult = ReturnType<typeof useGetAllPublicNotesSubscription>;
export type GetAllPublicNotesSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetAllPublicNotesSubscription>;
export const GetClientPublicNotesByChartDocument = gql`
    subscription getClientPublicNotesByChart($clientId: String!, $symbol: String) {
  message_threads(where: {client_id: {_eq: $clientId}, public_note: {chart_symbol: {_eq: $symbol}}}) {
    public_note {
      ...PublicNotePreview
    }
  }
}
    ${PublicNotePreviewFragmentDoc}`;

/**
 * __useGetClientPublicNotesByChartSubscription__
 *
 * To run a query within a React component, call `useGetClientPublicNotesByChartSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetClientPublicNotesByChartSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientPublicNotesByChartSubscription({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      symbol: // value for 'symbol'
 *   },
 * });
 */
export function useGetClientPublicNotesByChartSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetClientPublicNotesByChartSubscription, GetClientPublicNotesByChartSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetClientPublicNotesByChartSubscription, GetClientPublicNotesByChartSubscriptionVariables>(GetClientPublicNotesByChartDocument, baseOptions);
      }
export type GetClientPublicNotesByChartSubscriptionHookResult = ReturnType<typeof useGetClientPublicNotesByChartSubscription>;
export type GetClientPublicNotesByChartSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetClientPublicNotesByChartSubscription>;
export const GetPublicNoteForChartDocument = gql`
    subscription getPublicNoteForChart($clientId: String!, $publicNoteId: Int!) {
  public_note: public_notes_by_pk(id: $publicNoteId) {
    chart_symbol
    related_notes(where: {message_threads: {client_id: {_eq: $clientId}}}) {
      ...PublicNotePreview
    }
    message_threads(where: {client_id: {_eq: $clientId}}) {
      id
      channel_id
    }
  }
}
    ${PublicNotePreviewFragmentDoc}`;

/**
 * __useGetPublicNoteForChartSubscription__
 *
 * To run a query within a React component, call `useGetPublicNoteForChartSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicNoteForChartSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicNoteForChartSubscription({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      publicNoteId: // value for 'publicNoteId'
 *   },
 * });
 */
export function useGetPublicNoteForChartSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetPublicNoteForChartSubscription, GetPublicNoteForChartSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetPublicNoteForChartSubscription, GetPublicNoteForChartSubscriptionVariables>(GetPublicNoteForChartDocument, baseOptions);
      }
export type GetPublicNoteForChartSubscriptionHookResult = ReturnType<typeof useGetPublicNoteForChartSubscription>;
export type GetPublicNoteForChartSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetPublicNoteForChartSubscription>;
export const GetClientPublicNoteDocument = gql`
    query getClientPublicNote($publicNoteId: Int!, $clientId: String!) {
  public_note: public_notes_by_pk(id: $publicNoteId) {
    ...FrontendPublicNote
    message_threads(where: {client_id: {_eq: $clientId}}) {
      id
      created_at
      read_at
      channel_id
    }
  }
}
    ${FrontendPublicNoteFragmentDoc}`;

/**
 * __useGetClientPublicNoteQuery__
 *
 * To run a query within a React component, call `useGetClientPublicNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientPublicNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientPublicNoteQuery({
 *   variables: {
 *      publicNoteId: // value for 'publicNoteId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientPublicNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientPublicNoteQuery, GetClientPublicNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientPublicNoteQuery, GetClientPublicNoteQueryVariables>(GetClientPublicNoteDocument, baseOptions);
      }
export function useGetClientPublicNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientPublicNoteQuery, GetClientPublicNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientPublicNoteQuery, GetClientPublicNoteQueryVariables>(GetClientPublicNoteDocument, baseOptions);
        }
export type GetClientPublicNoteQueryHookResult = ReturnType<typeof useGetClientPublicNoteQuery>;
export type GetClientPublicNoteLazyQueryHookResult = ReturnType<typeof useGetClientPublicNoteLazyQuery>;
export type GetClientPublicNoteQueryResult = ApolloReactCommon.QueryResult<GetClientPublicNoteQuery, GetClientPublicNoteQueryVariables>;
export const GetUnreadPublicNotesCountDocument = gql`
    subscription getUnreadPublicNotesCount($clientId: String) {
  message_threads_aggregate(where: {client_id: {_eq: $clientId}, read_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetUnreadPublicNotesCountSubscription__
 *
 * To run a query within a React component, call `useGetUnreadPublicNotesCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadPublicNotesCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadPublicNotesCountSubscription({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetUnreadPublicNotesCountSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetUnreadPublicNotesCountSubscription, GetUnreadPublicNotesCountSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetUnreadPublicNotesCountSubscription, GetUnreadPublicNotesCountSubscriptionVariables>(GetUnreadPublicNotesCountDocument, baseOptions);
      }
export type GetUnreadPublicNotesCountSubscriptionHookResult = ReturnType<typeof useGetUnreadPublicNotesCountSubscription>;
export type GetUnreadPublicNotesCountSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetUnreadPublicNotesCountSubscription>;
export const GetFinancialDataDocument = gql`
    query getFinancialData($symbol: String!, $period: QueryPeriods!) {
  getFinancialData(symbol: $symbol, period: $period) {
    symbol
    data {
      close
      date
      high
      low
      open
      volume
    }
  }
}
    `;

/**
 * __useGetFinancialDataQuery__
 *
 * To run a query within a React component, call `useGetFinancialDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialDataQuery({
 *   variables: {
 *      symbol: // value for 'symbol'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetFinancialDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFinancialDataQuery, GetFinancialDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFinancialDataQuery, GetFinancialDataQueryVariables>(GetFinancialDataDocument, baseOptions);
      }
export function useGetFinancialDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFinancialDataQuery, GetFinancialDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFinancialDataQuery, GetFinancialDataQueryVariables>(GetFinancialDataDocument, baseOptions);
        }
export type GetFinancialDataQueryHookResult = ReturnType<typeof useGetFinancialDataQuery>;
export type GetFinancialDataLazyQueryHookResult = ReturnType<typeof useGetFinancialDataLazyQuery>;
export type GetFinancialDataQueryResult = ApolloReactCommon.QueryResult<GetFinancialDataQuery, GetFinancialDataQueryVariables>;
export const GetSignedUrlDocument = gql`
    query getSignedUrl($fileName: String!, $fileType: String!) {
  getSignedUrl(fileName: $fileName, fileType: $fileType) {
    url
    signedUrl
  }
}
    `;

/**
 * __useGetSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUrlQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useGetSignedUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, baseOptions);
      }
export function useGetSignedUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSignedUrlQuery, GetSignedUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument, baseOptions);
        }
export type GetSignedUrlQueryHookResult = ReturnType<typeof useGetSignedUrlQuery>;
export type GetSignedUrlLazyQueryHookResult = ReturnType<typeof useGetSignedUrlLazyQuery>;
export type GetSignedUrlQueryResult = ApolloReactCommon.QueryResult<GetSignedUrlQuery, GetSignedUrlQueryVariables>;
export const GetAlgoliaApiKeyDocument = gql`
    query getAlgoliaApiKey {
  getAlgoliaApiKey {
    apiKey
  }
}
    `;

/**
 * __useGetAlgoliaApiKeyQuery__
 *
 * To run a query within a React component, call `useGetAlgoliaApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlgoliaApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlgoliaApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAlgoliaApiKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlgoliaApiKeyQuery, GetAlgoliaApiKeyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAlgoliaApiKeyQuery, GetAlgoliaApiKeyQueryVariables>(GetAlgoliaApiKeyDocument, baseOptions);
      }
export function useGetAlgoliaApiKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlgoliaApiKeyQuery, GetAlgoliaApiKeyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAlgoliaApiKeyQuery, GetAlgoliaApiKeyQueryVariables>(GetAlgoliaApiKeyDocument, baseOptions);
        }
export type GetAlgoliaApiKeyQueryHookResult = ReturnType<typeof useGetAlgoliaApiKeyQuery>;
export type GetAlgoliaApiKeyLazyQueryHookResult = ReturnType<typeof useGetAlgoliaApiKeyLazyQuery>;
export type GetAlgoliaApiKeyQueryResult = ApolloReactCommon.QueryResult<GetAlgoliaApiKeyQuery, GetAlgoliaApiKeyQueryVariables>;
export const GetClientWithNotesDocument = gql`
    subscription getClientWithNotes($clientId: String!) {
  client: clients_by_pk(user_id: $clientId) {
    ...ClientWithNotes
  }
}
    ${ClientWithNotesFragmentDoc}`;

/**
 * __useGetClientWithNotesSubscription__
 *
 * To run a query within a React component, call `useGetClientWithNotesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetClientWithNotesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientWithNotesSubscription({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientWithNotesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetClientWithNotesSubscription, GetClientWithNotesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetClientWithNotesSubscription, GetClientWithNotesSubscriptionVariables>(GetClientWithNotesDocument, baseOptions);
      }
export type GetClientWithNotesSubscriptionHookResult = ReturnType<typeof useGetClientWithNotesSubscription>;
export type GetClientWithNotesSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetClientWithNotesSubscription>;
export const SetUserLastSeenDocument = gql`
    mutation setUserLastSeen($userId: String!) {
  update_users_by_pk(_set: {last_seen: "now()"}, pk_columns: {id: $userId}) {
    id
    last_seen
  }
}
    `;
export type SetUserLastSeenMutationFn = ApolloReactCommon.MutationFunction<SetUserLastSeenMutation, SetUserLastSeenMutationVariables>;

/**
 * __useSetUserLastSeenMutation__
 *
 * To run a mutation, you first call `useSetUserLastSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserLastSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserLastSeenMutation, { data, loading, error }] = useSetUserLastSeenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetUserLastSeenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetUserLastSeenMutation, SetUserLastSeenMutationVariables>) {
        return ApolloReactHooks.useMutation<SetUserLastSeenMutation, SetUserLastSeenMutationVariables>(SetUserLastSeenDocument, baseOptions);
      }
export type SetUserLastSeenMutationHookResult = ReturnType<typeof useSetUserLastSeenMutation>;
export type SetUserLastSeenMutationResult = ApolloReactCommon.MutationResult<SetUserLastSeenMutation>;
export type SetUserLastSeenMutationOptions = ApolloReactCommon.BaseMutationOptions<SetUserLastSeenMutation, SetUserLastSeenMutationVariables>;
export const GetWorkspacesDocument = gql`
    query getWorkspaces {
  workspaces {
    id
    name
    icon
    permission
    landing_pane_content
  }
}
    `;

/**
 * __useGetWorkspacesQuery__
 *
 * To run a query within a React component, call `useGetWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkspacesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWorkspacesQuery, GetWorkspacesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWorkspacesQuery, GetWorkspacesQueryVariables>(GetWorkspacesDocument, baseOptions);
      }
export function useGetWorkspacesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWorkspacesQuery, GetWorkspacesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWorkspacesQuery, GetWorkspacesQueryVariables>(GetWorkspacesDocument, baseOptions);
        }
export type GetWorkspacesQueryHookResult = ReturnType<typeof useGetWorkspacesQuery>;
export type GetWorkspacesLazyQueryHookResult = ReturnType<typeof useGetWorkspacesLazyQuery>;
export type GetWorkspacesQueryResult = ApolloReactCommon.QueryResult<GetWorkspacesQuery, GetWorkspacesQueryVariables>;
export const AddWorkspaceDocument = gql`
    mutation addWorkspace($name: String!, $icon: String!, $permission: permissions_enum!) {
  insert_workspaces(objects: {name: $name, icon: $icon, permission: $permission}) {
    affected_rows
    returning {
      id
      name
      icon
      permission
      landing_pane_content
    }
  }
}
    `;
export type AddWorkspaceMutationFn = ApolloReactCommon.MutationFunction<AddWorkspaceMutation, AddWorkspaceMutationVariables>;

/**
 * __useAddWorkspaceMutation__
 *
 * To run a mutation, you first call `useAddWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkspaceMutation, { data, loading, error }] = useAddWorkspaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      icon: // value for 'icon'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useAddWorkspaceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddWorkspaceMutation, AddWorkspaceMutationVariables>) {
        return ApolloReactHooks.useMutation<AddWorkspaceMutation, AddWorkspaceMutationVariables>(AddWorkspaceDocument, baseOptions);
      }
export type AddWorkspaceMutationHookResult = ReturnType<typeof useAddWorkspaceMutation>;
export type AddWorkspaceMutationResult = ApolloReactCommon.MutationResult<AddWorkspaceMutation>;
export type AddWorkspaceMutationOptions = ApolloReactCommon.BaseMutationOptions<AddWorkspaceMutation, AddWorkspaceMutationVariables>;
export const GetWorkspaceDocument = gql`
    subscription getWorkspace($workspaceId: Int!) {
  workspaces: workspaces_by_pk(id: $workspaceId) {
    ...FrontendWorkspace
  }
}
    ${FrontendWorkspaceFragmentDoc}`;

/**
 * __useGetWorkspaceSubscription__
 *
 * To run a query within a React component, call `useGetWorkspaceSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetWorkspaceSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetWorkspaceSubscription, GetWorkspaceSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetWorkspaceSubscription, GetWorkspaceSubscriptionVariables>(GetWorkspaceDocument, baseOptions);
      }
export type GetWorkspaceSubscriptionHookResult = ReturnType<typeof useGetWorkspaceSubscription>;
export type GetWorkspaceSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetWorkspaceSubscription>;
export const UpdateWorkspaceLayoutDocument = gql`
    mutation updateWorkspaceLayout($workspaceId: Int!, $layout: layouts_enum!) {
  update_workspaces(where: {id: {_eq: $workspaceId}}, _set: {layout: $layout}) {
    affected_rows
    returning {
      id
      layout
    }
  }
}
    `;
export type UpdateWorkspaceLayoutMutationFn = ApolloReactCommon.MutationFunction<UpdateWorkspaceLayoutMutation, UpdateWorkspaceLayoutMutationVariables>;

/**
 * __useUpdateWorkspaceLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceLayoutMutation, { data, loading, error }] = useUpdateWorkspaceLayoutMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      layout: // value for 'layout'
 *   },
 * });
 */
export function useUpdateWorkspaceLayoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkspaceLayoutMutation, UpdateWorkspaceLayoutMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkspaceLayoutMutation, UpdateWorkspaceLayoutMutationVariables>(UpdateWorkspaceLayoutDocument, baseOptions);
      }
export type UpdateWorkspaceLayoutMutationHookResult = ReturnType<typeof useUpdateWorkspaceLayoutMutation>;
export type UpdateWorkspaceLayoutMutationResult = ApolloReactCommon.MutationResult<UpdateWorkspaceLayoutMutation>;
export type UpdateWorkspaceLayoutMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorkspaceLayoutMutation, UpdateWorkspaceLayoutMutationVariables>;
export const UpdateWorkspaceLandingPaneContentsDocument = gql`
    mutation updateWorkspaceLandingPaneContents($id: Int!, $landing_pane_content: json) {
  update_workspaces(where: {id: {_eq: $id}}, _set: {landing_pane_content: $landing_pane_content}) {
    affected_rows
    returning {
      id
      landing_pane_content
    }
  }
}
    `;
export type UpdateWorkspaceLandingPaneContentsMutationFn = ApolloReactCommon.MutationFunction<UpdateWorkspaceLandingPaneContentsMutation, UpdateWorkspaceLandingPaneContentsMutationVariables>;

/**
 * __useUpdateWorkspaceLandingPaneContentsMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceLandingPaneContentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceLandingPaneContentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceLandingPaneContentsMutation, { data, loading, error }] = useUpdateWorkspaceLandingPaneContentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      landing_pane_content: // value for 'landing_pane_content'
 *   },
 * });
 */
export function useUpdateWorkspaceLandingPaneContentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkspaceLandingPaneContentsMutation, UpdateWorkspaceLandingPaneContentsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkspaceLandingPaneContentsMutation, UpdateWorkspaceLandingPaneContentsMutationVariables>(UpdateWorkspaceLandingPaneContentsDocument, baseOptions);
      }
export type UpdateWorkspaceLandingPaneContentsMutationHookResult = ReturnType<typeof useUpdateWorkspaceLandingPaneContentsMutation>;
export type UpdateWorkspaceLandingPaneContentsMutationResult = ApolloReactCommon.MutationResult<UpdateWorkspaceLandingPaneContentsMutation>;
export type UpdateWorkspaceLandingPaneContentsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorkspaceLandingPaneContentsMutation, UpdateWorkspaceLandingPaneContentsMutationVariables>;
export const DeleteWorkspaceDocument = gql`
    mutation deleteWorkspace($workspaceId: Int!) {
  delete_workspaces(where: {id: {_eq: $workspaceId}}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type DeleteWorkspaceMutationFn = ApolloReactCommon.MutationFunction<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;

/**
 * __useDeleteWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceMutation, { data, loading, error }] = useDeleteWorkspaceMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDeleteWorkspaceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>(DeleteWorkspaceDocument, baseOptions);
      }
export type DeleteWorkspaceMutationHookResult = ReturnType<typeof useDeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationResult = ApolloReactCommon.MutationResult<DeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>;
export const AddMessageThreadsDocument = gql`
    mutation addMessageThreads($threads: [message_threads_insert_input!]!) {
  insert_message_threads(objects: $threads) {
    returning {
      id
      client_id
      channel_id
    }
  }
}
    `;
export type AddMessageThreadsMutationFn = ApolloReactCommon.MutationFunction<AddMessageThreadsMutation, AddMessageThreadsMutationVariables>;

/**
 * __useAddMessageThreadsMutation__
 *
 * To run a mutation, you first call `useAddMessageThreadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageThreadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageThreadsMutation, { data, loading, error }] = useAddMessageThreadsMutation({
 *   variables: {
 *      threads: // value for 'threads'
 *   },
 * });
 */
export function useAddMessageThreadsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMessageThreadsMutation, AddMessageThreadsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMessageThreadsMutation, AddMessageThreadsMutationVariables>(AddMessageThreadsDocument, baseOptions);
      }
export type AddMessageThreadsMutationHookResult = ReturnType<typeof useAddMessageThreadsMutation>;
export type AddMessageThreadsMutationResult = ApolloReactCommon.MutationResult<AddMessageThreadsMutation>;
export type AddMessageThreadsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMessageThreadsMutation, AddMessageThreadsMutationVariables>;
export const MarkMessageThreadAsReadDocument = gql`
    mutation markMessageThreadAsRead($id: Int!) {
  update_message_threads_by_pk(pk_columns: {id: $id}, _set: {read_at: "now()"}) {
    id
    read_at
  }
}
    `;
export type MarkMessageThreadAsReadMutationFn = ApolloReactCommon.MutationFunction<MarkMessageThreadAsReadMutation, MarkMessageThreadAsReadMutationVariables>;

/**
 * __useMarkMessageThreadAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageThreadAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageThreadAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageThreadAsReadMutation, { data, loading, error }] = useMarkMessageThreadAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkMessageThreadAsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkMessageThreadAsReadMutation, MarkMessageThreadAsReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkMessageThreadAsReadMutation, MarkMessageThreadAsReadMutationVariables>(MarkMessageThreadAsReadDocument, baseOptions);
      }
export type MarkMessageThreadAsReadMutationHookResult = ReturnType<typeof useMarkMessageThreadAsReadMutation>;
export type MarkMessageThreadAsReadMutationResult = ApolloReactCommon.MutationResult<MarkMessageThreadAsReadMutation>;
export type MarkMessageThreadAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkMessageThreadAsReadMutation, MarkMessageThreadAsReadMutationVariables>;
export const UpsertNotificationAsReadDocument = gql`
    mutation upsertNotificationAsRead($noteId: Int!, $userId: String!) {
  insert_notes_users_notifications(objects: [{note_id: $noteId, user_id: $userId, read_at: "now()"}], on_conflict: {constraint: notes_users_notifications_user_id_note_id_key, update_columns: [read_at]}) {
    returning {
      ...NotesReadAt
    }
  }
}
    ${NotesReadAtFragmentDoc}`;
export type UpsertNotificationAsReadMutationFn = ApolloReactCommon.MutationFunction<UpsertNotificationAsReadMutation, UpsertNotificationAsReadMutationVariables>;

/**
 * __useUpsertNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useUpsertNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNotificationAsReadMutation, { data, loading, error }] = useUpsertNotificationAsReadMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpsertNotificationAsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertNotificationAsReadMutation, UpsertNotificationAsReadMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertNotificationAsReadMutation, UpsertNotificationAsReadMutationVariables>(UpsertNotificationAsReadDocument, baseOptions);
      }
export type UpsertNotificationAsReadMutationHookResult = ReturnType<typeof useUpsertNotificationAsReadMutation>;
export type UpsertNotificationAsReadMutationResult = ApolloReactCommon.MutationResult<UpsertNotificationAsReadMutation>;
export type UpsertNotificationAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertNotificationAsReadMutation, UpsertNotificationAsReadMutationVariables>;
export const DeleteNoteUserNotificationByUserDocument = gql`
    mutation deleteNoteUserNotificationByUser($noteId: Int!, $userId: String!) {
  delete_notes_users_notifications(where: {user_id: {_eq: $userId}, note_id: {_eq: $noteId}}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type DeleteNoteUserNotificationByUserMutationFn = ApolloReactCommon.MutationFunction<DeleteNoteUserNotificationByUserMutation, DeleteNoteUserNotificationByUserMutationVariables>;

/**
 * __useDeleteNoteUserNotificationByUserMutation__
 *
 * To run a mutation, you first call `useDeleteNoteUserNotificationByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteUserNotificationByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteUserNotificationByUserMutation, { data, loading, error }] = useDeleteNoteUserNotificationByUserMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteNoteUserNotificationByUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNoteUserNotificationByUserMutation, DeleteNoteUserNotificationByUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNoteUserNotificationByUserMutation, DeleteNoteUserNotificationByUserMutationVariables>(DeleteNoteUserNotificationByUserDocument, baseOptions);
      }
export type DeleteNoteUserNotificationByUserMutationHookResult = ReturnType<typeof useDeleteNoteUserNotificationByUserMutation>;
export type DeleteNoteUserNotificationByUserMutationResult = ApolloReactCommon.MutationResult<DeleteNoteUserNotificationByUserMutation>;
export type DeleteNoteUserNotificationByUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNoteUserNotificationByUserMutation, DeleteNoteUserNotificationByUserMutationVariables>;
export const DeleteNoteUserNotificationDocument = gql`
    mutation deleteNoteUserNotification($noteId: Int!) {
  delete_notes_users_notifications(where: {note_id: {_eq: $noteId}}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type DeleteNoteUserNotificationMutationFn = ApolloReactCommon.MutationFunction<DeleteNoteUserNotificationMutation, DeleteNoteUserNotificationMutationVariables>;

/**
 * __useDeleteNoteUserNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNoteUserNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteUserNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteUserNotificationMutation, { data, loading, error }] = useDeleteNoteUserNotificationMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteNoteUserNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNoteUserNotificationMutation, DeleteNoteUserNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNoteUserNotificationMutation, DeleteNoteUserNotificationMutationVariables>(DeleteNoteUserNotificationDocument, baseOptions);
      }
export type DeleteNoteUserNotificationMutationHookResult = ReturnType<typeof useDeleteNoteUserNotificationMutation>;
export type DeleteNoteUserNotificationMutationResult = ApolloReactCommon.MutationResult<DeleteNoteUserNotificationMutation>;
export type DeleteNoteUserNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNoteUserNotificationMutation, DeleteNoteUserNotificationMutationVariables>;
export const GetNoteDocument = gql`
    query getNote($id: Int!) {
  note: notes_by_pk(id: $id) {
    ...FrontendNote
  }
}
    ${FrontendNoteFragmentDoc}`;

/**
 * __useGetNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, baseOptions);
      }
export function useGetNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, baseOptions);
        }
export type GetNoteQueryHookResult = ReturnType<typeof useGetNoteQuery>;
export type GetNoteLazyQueryHookResult = ReturnType<typeof useGetNoteLazyQuery>;
export type GetNoteQueryResult = ApolloReactCommon.QueryResult<GetNoteQuery, GetNoteQueryVariables>;
export const UnpublishNoteDocument = gql`
    mutation unpublishNote($noteId: Int!) {
  unpublishNote(noteId: $noteId) {
    noteId
  }
}
    `;
export type UnpublishNoteMutationFn = ApolloReactCommon.MutationFunction<UnpublishNoteMutation, UnpublishNoteMutationVariables>;

/**
 * __useUnpublishNoteMutation__
 *
 * To run a mutation, you first call `useUnpublishNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishNoteMutation, { data, loading, error }] = useUnpublishNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useUnpublishNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnpublishNoteMutation, UnpublishNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UnpublishNoteMutation, UnpublishNoteMutationVariables>(UnpublishNoteDocument, baseOptions);
      }
export type UnpublishNoteMutationHookResult = ReturnType<typeof useUnpublishNoteMutation>;
export type UnpublishNoteMutationResult = ApolloReactCommon.MutationResult<UnpublishNoteMutation>;
export type UnpublishNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UnpublishNoteMutation, UnpublishNoteMutationVariables>;
export const GetTeamClientsDocument = gql`
    query getTeamClients($teamId: Int!) {
  team_contacts(where: {team_id: {_eq: $teamId}}) {
    ...TeamClient
  }
}
    ${TeamClientFragmentDoc}`;

/**
 * __useGetTeamClientsQuery__
 *
 * To run a query within a React component, call `useGetTeamClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamClientsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamClientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTeamClientsQuery, GetTeamClientsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTeamClientsQuery, GetTeamClientsQueryVariables>(GetTeamClientsDocument, baseOptions);
      }
export function useGetTeamClientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTeamClientsQuery, GetTeamClientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTeamClientsQuery, GetTeamClientsQueryVariables>(GetTeamClientsDocument, baseOptions);
        }
export type GetTeamClientsQueryHookResult = ReturnType<typeof useGetTeamClientsQuery>;
export type GetTeamClientsLazyQueryHookResult = ReturnType<typeof useGetTeamClientsLazyQuery>;
export type GetTeamClientsQueryResult = ApolloReactCommon.QueryResult<GetTeamClientsQuery, GetTeamClientsQueryVariables>;
export const GetTeamClientsLiveDocument = gql`
    subscription getTeamClientsLive($teamId: Int!) {
  team_contacts(where: {team_id: {_eq: $teamId}}) {
    ...TeamClient
  }
}
    ${TeamClientFragmentDoc}`;

/**
 * __useGetTeamClientsLiveSubscription__
 *
 * To run a query within a React component, call `useGetTeamClientsLiveSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamClientsLiveSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamClientsLiveSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamClientsLiveSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<GetTeamClientsLiveSubscription, GetTeamClientsLiveSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<GetTeamClientsLiveSubscription, GetTeamClientsLiveSubscriptionVariables>(GetTeamClientsLiveDocument, baseOptions);
      }
export type GetTeamClientsLiveSubscriptionHookResult = ReturnType<typeof useGetTeamClientsLiveSubscription>;
export type GetTeamClientsLiveSubscriptionResult = ApolloReactCommon.SubscriptionResult<GetTeamClientsLiveSubscription>;
export const GetUserDocument = gql`
    query getUser($id: String!) {
  user: users_by_pk(id: $id) {
    ...FrontendUser
  }
}
    ${FrontendUserFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;