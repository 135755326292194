import React, {useEffect} from 'react'
import {
  Box,
  Paper,
  ListSubheader,
  Divider,
  ListItemSecondaryAction,
  Button,
} from '@material-ui/core'
import {Redirect, Link} from 'react-router-dom'
import createPersistedState from 'use-persisted-state'
import {makeStyles} from '@material-ui/core/styles'
import SplitPane from 'react-split-pane'
import {format} from 'date-fns'
import {
  useGetClientPublicNoteQuery,
  useMarkMessageThreadAsReadMutation,
} from '../../generated/types'
import {Chat, Loading} from '../../components'
import EditorViewer from '../../components/editor/editor-viewer'
import {useUserState} from '../../utils/user-context'
import routes from '../../routes'

type CNWProps = {
  noteId: number
  chartButton?: boolean
}

const ClientNoteViewer = ({noteId, chartButton}: CNWProps) => {
  const classes = useStyles()
  const {user} = useUserState()
  const publicNoteVariables = {publicNoteId: noteId, clientId: user?.id || ''}
  const {loading, error, data} = useGetClientPublicNoteQuery({variables: publicNoteVariables})
  const [markAsRead] = useMarkMessageThreadAsReadMutation()

  const note = data?.public_note
  const channelId = note?.message_threads[0]?.channel_id
  const readAt = note?.message_threads[0]?.read_at
  const threadId = note?.message_threads[0]?.id

  const useHorizontalPaneSplit = createPersistedState('clientHorizontalPaneSplit')
  const [horizontalPaneSplit, setHorizontalPaneSplit] = useHorizontalPaneSplit(0)

  useEffect(() => {
    if (!!threadId && readAt === null) markAsRead({variables: {id: threadId}})
  }, [readAt, threadId, markAsRead])

  if (!loading && !note) {
    return <Redirect to={routes.common.fourhundredandfour.createUrl()} />
  }

  if (error) throw error
  if (loading || !note || !channelId) return <Loading />

  return (
    <SplitPane
      split="horizontal"
      minSize={200}
      defaultSize={horizontalPaneSplit || '60%'}
      onChange={size => setHorizontalPaneSplit(size)}
      maxSize={-100}
      resizerStyle={{
        zIndex: 4000,
        height: 11,
        margin: '-5px 0',
        cursor: 'row-resize',
      }}
      pane2Style={{
        height: '100%',
      }}
    >
      <Paper className={classes.topPane} variant="outlined">
        <Paper style={{overflow: 'auto', height: '100%'}}>
          <ListSubheader>
            {format(new Date(note.time), 'MMMM d, yyyy')}
            {chartButton && (
              <ListItemSecondaryAction>
                <Button
                  variant="outlined"
                  color="secondary"
                  component={Link}
                  to={routes.client.chartNote.createUrl(note.chart_symbol, note.id)}
                >
                  View on chart
                </Button>
              </ListItemSecondaryAction>
            )}
          </ListSubheader>
          <Divider />
          <Box p={1}>
            <EditorViewer key={noteId} value={note.content} />
          </Box>
        </Paper>
      </Paper>
      <Paper className={classes.bottomPane} variant="outlined">
        <Chat channelId={channelId} />
      </Paper>
    </SplitPane>
  )
}

const useStyles = makeStyles(() => ({
  bottomPane: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  topPane: {
    width: '100%',
    height: '100%',
  },
}))

export default ClientNoteViewer
